import extendNode from '../extendNode.mjs'
import useCatRootModuleNode from '../_CAT_ROOT_MODULE/useCatRootModuleNode.jsx'
import PropertiesPane from './PropertiesPaneCatRootBm.jsx'
import meta from './metaCatRootBm.mjs'

export default () => {
    const CatRootModuleNode = useCatRootModuleNode()

    return extendNode(CatRootModuleNode, {
        ...meta,
        PropertiesPane,

        canLinkType(map, node, type) {
            const {subTypeCode} = map.data

            if ('BODA2' === subTypeCode) {
                return /^(BD|UI)$/.test(type)
            }
            else {
                return /^(BD|BF)$/.test(type)
            }
        },

        defaultChildType(map, node) {
            const {subTypeCode} = map.data

            if ('BODA2' === subTypeCode) {
                return 'UI'
            }
            else {
                return 'BF'
            }
        },

        menuItemsInsertConcept(map, node) {
            const {subTypeCode} = map.data

            if ('BODA2' === subTypeCode) {
                return [
                    ['BD_1'],
                    ['BD_2'],
                    ['UI'],
                ]
            }
            else {
                return [
                    ['BD_1'],
                    ['BD_2'],
                    ['BF'],
                ]
            }
        },

        menuItemsInsertProduct(map, node) {
            const {subTypeCode} = map.data

            if ('BODA2' === subTypeCode) {
                return [
                    ['BD_1'],
                    ['BD_2'],
                    ['UI'],
                ]
            }
            else {
                return [
                    ['BD_1'],
                    ['BD_2'],
                    ['BF'],
                ]
            }
        },
    })
}
