import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import ButtonOldVersion from '../components/PropertiesPaneButtonOldVersion/PropertiesPaneButtonOldVersion.jsx'
import Model from './ModelMid.mjs'
import SelectMidType from './PropertiesControllerSelectMidType.jsx'

const PropertiesPaneMid = (props) => (
    <ProductPropertiesPane
        buttons={<ButtonOldVersion>加载制品图</ButtonOldVersion>}

        controllers={
            <>
                <Text prop="middlewareName" />
                <SelectMidType />
                <LongText prop="middlewareDesc" />
                <LongText prop="middlewareMemo" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'ChgReference',
                    'Reference',
                    'ReferenceCall',
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneMid
