import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import ButtonOldVersion from '../components/PropertiesPaneButtonOldVersion/PropertiesPaneButtonOldVersion.jsx'
import Model from './ModelSs.mjs'
import SsType from './SsType.mjs'
import TerminalType from './TerminalType.mjs'

const PropertiesPaneSs = (props) => (
    <ProductPropertiesPane
        buttons={<ButtonOldVersion>加载制品图</ButtonOldVersion>}

        controllers={
            <>
                <Text prop="ssUserCode" />
                <Text prop="ssName" />

                <Select
                    prop="isUi"
                    options={SsType}
                />

                <Select
                    prop="terminalType"
                    options={TerminalType}
                />

                <LongText prop="ssDesc" />
                <Text prop="devLang" />
                <LongText prop="devTechDesc" />
                <LongText prop="softwareDesc" />
                <LongText prop="hardwareDesc" />
                <LongText prop="networkDesc" />
                <Text prop="deployIp" />
                <LongText prop="deployDesc" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'ChgReference',
                    'Reference',
                    'ReferenceCall',
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneSs
