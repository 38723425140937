import {css} from '@emotion/react'
import {useMapContext} from '@/components/MindMap/index.mjs'
import NodeExtraText from './NodeExtraText.jsx'

const cssNodeDesc = css({
})

export default function NodeDesc({node, ...props}) {
    const map = useMapContext()
    const showNodeDesc = map.useShowNodeDesc()
    const _n = map.nodeProxy(node)

    if (! (showNodeDesc || _n.showDesc())) {
        return null
    }

    const desc = _n.getDesc()

    if (! desc) {
        return null
    }

    return (
        <NodeExtraText
            css={cssNodeDesc}
            node={node}
            text={`<${desc}>`}
            {...props}
        />
    )
}
