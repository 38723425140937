import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import ButtonSaveProduct from '../components/PropertiesPaneButtonSaveProduct.jsx'
import Model from './ModelLs.mjs'

const PropertiesPaneLs = (props) => (
    <ProductPropertiesPane
        buttons={
            <>
                <ButtonSaveProduct />
            </>
        }

        controllers={
            <>
                <Text prop="lsName" />
                <Text prop="infDomainName" />
                <Text prop="infIp" />
                <LongText prop="memo" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'ChgReference',
                    'Reference',
                    'ReferenceCall',
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneLs
