import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import IconMonitor from '../icons/IconMonitor.jsx'
import meta from './metaCres.mjs'
import Model from '../FRES/ModelFres.mjs'
import PropertiesPane from '../FRES/PropertiesPaneFres.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        canLinkType(map, node, type) {
            return /^(UE|UI(ALG)?)$/.test(type)
        },

        canMountType(map, node, type) {
            return /^(PIC)$/.test(type)
        },

        getIcons(map, node) {
            const {pkid} = node.data

            if (pkid) {
                return [
                    <IconMonitor
                        key="type"
                        color={Color.LIGHT_BLUE}
                    />
                ]
            }
            else {
                return []
            }
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.GOLD}),
                shape: 'RoundedRectangle',
            }
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['PIC'],
            ]
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['UI'],
                ['UE'],
            ]
        },

        async onPull(map, node) {
            await ComponentNode.onPull.call(this, map, node)
            node.isFolded = false
        },

        async _atOwnerPush(map, node, event) {
            const pushData = await this._getPushData(map, node)

            const d = {
                ...pushData,
                resPics: [],
                ssCode: '',
                ueCode: '',
                uiCode: '',
                secSsCode: '',
                secUeCode: '',
                secUeRev: '',
                secUiCode: '',
            }

            const next = chain => {
                const _n = map.nodeProxy(chain[0])
                return {yieldChildren: _n.isMounted()}
            }

            for (const n of map.bfs(node.children, next)) {
                const {bizNodeType} = n.data

                if ('PIC' === bizNodeType) {
                    const {fileId: picId, text: picName} = n.data

                    if (picId) {
                        d.resPics.push({picId, picName})
                    }
                }
                else if ('UE' === bizNodeType) {
                    const {ssCode, ueCode, uiCode} = n.data

                    if (d.ueCode) {
                        continue
                    }
                    else {
                        Object.assign(d, {ssCode, ueCode, uiCode})
                    }
                }
                else if ('UI' === bizNodeType) {
                    const {rev, uiCode} = n.data

                    if (d.secUiCode) {
                        continue
                    }
                    else {
                        Object.assign(d, {
                            secUeRev: rev, // 没写错
                            secUiCode: uiCode,
                        })
                    }
                }
            }

            event.detail.child.push(d)
        },
    })
}
