import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaSsinsDependenceOn.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canLinkType(map, node, type) {
            return /^(DB|MID)INS$/.test(type)
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['DBINS_'],
                ['MIDINS_'],
            ]
        },

        async _atPush(map, node, event) {
            event.stopPropagation()

            const next = chain => {
                const _n = map.nodeProxy(chain[0])
                return {yieldChildren: _n.isMounted()}
            }

            for (const n of map.bfs(node.children, next)) {
                const {
                    bizNodeType,
                    dbInsCode,
                    middlewareInsCode,
                    prjId,
                    rev,
                    sVer,
                } = n.data

                if ('DBINS' === bizNodeType) {
                    event.detail.archSsInsDependences.push({
                        dependenceCode: dbInsCode,
                        dependencePrjId: prjId,
                        dependenceSVer: sVer,
                        dependenceType: 'DB',
                        dependenceVer: rev,
                    })
                }
                else if ('MIDINS' === bizNodeType) {
                    event.detail.archSsInsDependences.push({
                        dependenceCode: middlewareInsCode,
                        dependencePrjId: prjId,
                        dependenceSVer: sVer,
                        dependenceType: 'MIDDLEWARE',
                        dependenceVer: rev,
                    })
                }
            }
        },
    })
}
