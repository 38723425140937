export const toNavigatorMap = (mapData, version = 0) => {
    return [
        // latest -> v0
        ({root, ...rest}) => ({
            ...rest,
            root: toTree(root, version),
        }),

        // latest
        (a) => a,
    ][version](mapData)
}

export const toProductMap = (mapData, version = 0) => {
    return [
        // latest -> v0
        ({root, data}) => ({
            ...data,
            root: toTree(root, version),
        }),

        // latest
        (a) => a,
    ][version](mapData)
}

export const toTree = (tree, version = 0) => {
    return [
        // latest -> v0
        ({children, data, isFolded, isHidden}) => ({
            ...data,
            children: children.map((tree) => toTree(tree, version)),
            isHidden,
            isFolded,
        }),

        // latest
        (a) => a,
    ][version](tree)
}
