import extendNode from '../extendNode.mjs'
import useAbstractNode from '../_ABSTRACT/useAbstractNode.jsx'
import meta from './metaOu_1.mjs'
import FormChoose from './FormChooseOu_1.jsx'
import TableChoose from './TableChooseOu_1.jsx'
import api from './apiOu_1.mjs'

export default () => {
    const AbstractNode = useAbstractNode()

    return extendNode(AbstractNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
    })
}
