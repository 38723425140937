import extendNode from '../extendNode.mjs'
import http from '@/biz/http.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import api from './apiBdrule.mjs'
import meta from './metaBdrule.mjs'
import Model from './ModelBdrule.mjs'
import RuleType from './RuleType.jsx'
import PropertiesPane from './PropertiesPaneBdrule.jsx'
import PropertiesPaneBatch from './PropertiesPaneBatchBdrule.jsx'
import FormChoose from './FormChooseBdrule.jsx'
import TableChoose from './TableChooseBdrule.jsx'

const ruleTypes = new Map(
    RuleType.map(([type, name, icon]) => [type, {icon, name, type}])
)

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,
        PropertiesPaneBatch,

        canMountType(map, node, type) {
            return /^(AR|DOC|PIC|URL)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'AR'
        },

        getDesc(map, node) {
            const {ruleType} = node.data

            if (! ruleType || 'COMMON' === ruleType) {
                return this.name
            }

            const {name = ''} = ruleTypes.get(ruleType) ?? {}
            return name
        },

        getIcons(map, node) {
            const {ruleType} = node.data
            const {icon} = ruleTypes.get(ruleType) ?? {}

            if (icon) {
                return [icon]
            }
            else {
                return []
            }
        },

        getStyle(map, node) {
            const {ruleType} = node.data

            const borderColor = (! ruleType || 'COMMON' === ruleType) ?
                Color.LIGHT_YELLOW : Color.CYAN

            return {
                ...this._getStyle(map, node, {borderColor}),
                shape: 'EllipseRectangle',
            }
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['AR'],
                ['PIC'],
                ['DOC'],
                ['URL'],
            ]
        },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.ruleList.push(d)
        },

        _getDefaultDataFields(map, node) {
            return {
                ...ComponentNode._getDefaultDataFields.call(this, map, node),
                ruleType: 'COMMON'
            }
        },

        async _readGrowTree(map, node) {
            const {prjPatternId} = map.data

            const readImplementer = prjPatternId ?
                async () => {
                    const {rev, ruleCode} = node.data

                    const result = await http.get(
                        '/patterns/rule/extend',
                        {prjPatternId, rev, ruleCode}
                    )

                    return result && parseMap(result)
                }
                :
                async () => {
                    const {prjId, rev, ruleCode, sVer} = node.data

                    const result = await http.get(
                        '/dmbds/rule/extend',
                        {prjId, rev, ruleCode, sVer}
                    )

                    return result && parseMap(result)
                }

            const [tree, implementer] = await Promise.all([
                ComponentNode._readGrowTree.call(this, map, node),
                readImplementer(),
            ])

            if (implementer) {
                tree.children.unshift(implementer)
            }

            return tree
        },
    })
}
