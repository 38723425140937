import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import IconCircle from '../icons/IconCircle.jsx'
import meta from './metaBj.mjs'
import Model from './ModelBj.mjs'
import PropertiesPane from './PropertiesPaneBj.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        attrNodes: {
            top: ['ATTR_BJ_POS'],
        },

        getIcons(map, node) {
            const {onlineFlag, pkid} = node.data

            if (! pkid) {
                return []
            }
            else if ('1' === onlineFlag) {
                return [
                    <IconCircle
                        key="type"
                        fill={Color.GOLD}
                    />]
            }
            else {
                return [
                    <IconCircle
                        key="type"
                        fill={Color.GREY}
                    />
                ]
            }
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.LIGHT_YELLOW
                }),

                shape: 'Arrow'
            }
        },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.bmBjList.push(d)
        },

        _pushDataSlots: {
            sfCodes: [],
        },

        _getCategories(map, node) {
            const bfTypeCode = (() => {
                for (const n of map.chain(node.parent)) {
                    const {
                        bfTypeCode = 'TARGET',
                        bizNodeType,
                    } = n.data

                    if ('BF' === bizNodeType) {
                        return bfTypeCode
                    }
                }

                return 'TARGET'
            })()

            return {
                TARGET: ['BJ_DESC'],
                SITUATION: ['BJ_DESC', 'BJ_REQ'],
            }[bfTypeCode]
        }
    })
}
