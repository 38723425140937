import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'

const FormItemDeTitle = (props) => {
    return (
        <Form.Item
            label="维度实体标题"
            name="deTitle"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemDoName = (props) => {
    return (
        <Form.Item
            label="维度对象名称"
            name="doName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemDoNo = (props) => {
    return (
        <Form.Item
            label="维度对象代码"
            name="doNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemPrjNo = (props) => {
    return (
        <Form.Item
            label="项目编号"
            name="prjNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

export default function FormChooseDe(props) {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemPrjNo />
            </Col>

            <Col span={6}>
                <FormItemDoName />
            </Col>

            <Col span={6}>
                <FormItemDoNo />
            </Col>

            <Col span={6}>
                <FormItemDeTitle />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}
