import Table from '@/components/Table/Table.jsx'
import TableChooseProduct from '../_PRODUCT/TableChooseProduct.jsx'

export default function TableChooseAmar(props) {
    const columns = [
        // {
        //     title: '项目编号',
        //     dataIndex: 'prjNo',
        //     component: <Table.ViewText />,
        // },

        {
            title: '分析域',
            dataIndex: 'amNamePath',
            component: <Table.ViewText />,
        },

        {
            title: '分析报表代码',
            dataIndex: 'arNo',
            component: <Table.ViewText />,
        },

        {
            title: '分析报表名称',
            dataIndex: 'arName',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseProduct
            columns={columns}
            {...props}
        />
    )
}
