import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'
import SelectAm from '../AM/SelectAm.jsx'

const FormItemAmCode = (props) => {
    return (
        <Form.Item
            label="分析域"
            name="amCode"
            {...props}
        >
            <SelectAm multiple />
        </Form.Item>
    )
}

const FormItemArCode = (props) => {
    return (
        <Form.Item
            label="分析报表代码"
            name="arNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemArName = (props) => {
    return (
        <Form.Item
            label="分析报表名称"
            name="arName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

// const FormItemPrjNo = (props) => {
//     return (
//         <Form.Item
//             label="制品编号"
//             name="prjNo"
//             {...props}
//         >
//             <Input allowClear />
//         </Form.Item>
//     )
// }

export default function FormChooseAmar(props) {
    const brief = (
        <Row gutter={12}>
            {/* <Col span={6}>
                <FormItemPrjNo />
            </Col> */}

            <Col span={6}>
                <FormItemAmCode />
            </Col>

            <Col span={6}>
                <FormItemArCode />
            </Col>

            <Col span={6}>
                <FormItemArName />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}