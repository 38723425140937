import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useAlgNode from '../_ALG/useAlgNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import api from './apiBdsummary.mjs'
import meta from './metaBdsummary.mjs'
import PropertiesPane from './PropertiesPaneBdsummary.jsx'
import FormChoose from './FormChooseBdsummary.jsx'
import TableChoose from './TableChooseBdsummary.jsx'

export default () => {
    const AlgNode = useAlgNode()

    return extendNode(AlgNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        PropertiesPane,

        async choose(map, node) {
            const {prjNo} = map.data
            const getQuery = q => ({prjNo, ...q})
            return this._choose(map, node, {getQuery})
        },

        defaultChildType(map, node) {
            return 'AR'
        },

        getIcons(map, node) {
            const {storyType} = node.data

            if ('INF' === storyType) {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.PURPLE}
                        letters="S"
                        textColor="#fff"
                    />
                ]
            }
            else if ('SYS' === storyType) {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.GREEN}
                        letters="S"
                        textColor="#fff"
                    />
                ]
            }
            else if ('TMPL' === storyType) {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.GREY}
                        letters="S"
                        textColor="#fff"
                    />
                ]
            }
            else {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.LIGHT_YELLOW}
                        letters="S"
                        textColor="#000"
                    />
                ]
            }
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.LIGHT_YELLOW}),
                shape: 'EllipseRectangle',
            }
        },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.algList.push(d)
        },

        _pushDataSlots: {
            fcCode: '',
            sfCodes: [],
        },

        _getDefaultDataFields(map, node) {
            return {
                ...AlgNode._getDefaultDataFields.call(this, map, node),
                storyType: 'COMMON'
            }
        },
    })
}
