import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaDbins.mjs'
import Model from './ModelDbins.mjs'
import PropertiesPane from './PropertiesPaneDbins.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    borderColor={Color.DARK_GREEN}
                    letters="D"
                    textColor="#000"
                />
            ]
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.DARK_GREEN,
            })
        },

        getTextPrefix(map, node) {
            const defaultPrefix = ComponentNode.getTextPrefix.call(
                this, map, node
            )

            const {dbInsEnv} = node.data

            if (dbInsEnv) {
                return `${defaultPrefix}【${dbInsEnv}】`
            }
            else {
                return defaultPrefix
            }
        },

        mapUpdateNodeData(map, node, data) {
            const d = {...data}
            const oldData = node.data

            const {
                dbInsIntranetIp,
                dbInsPort,
                dbInsSchema,
            } = {...oldData, ...data}

            if (
                dbInsIntranetIp &&
                dbInsPort &&
                dbInsSchema &&
                ! oldData.url &&
                ! Object.hasOwnProperty.call(data, 'url')
            ) {
                for (const n of map.trueChain(node.parent)) {
                    const {bizNodeType, dbmsCode} = n.data

                    if ('DB' === bizNodeType) {
                        const url = {
                            ORACLE: `jdbc:oracle:thin:@//${dbInsIntranetIp}:${dbInsPort}/${dbInsSchema}`,
                            MYSQL: `jdbc:mysql://${dbInsIntranetIp}:${dbInsPort}/${dbInsSchema}?allowMultiQueries=true`,
                            SQLSERVER: `jdbc:sqlserver://${dbInsIntranetIp}:${dbInsPort};DatabaseName=${dbInsSchema}`,
                            POSTGRESQL: `jdbc:postgresql://${dbInsIntranetIp}:${dbInsPort}/${dbInsSchema}`,
                        }[dbmsCode]

                        Object.assign(d, {url})
                        break
                    }
                }
            }

            return d
        },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.archDbInss.push(d)
        },

        _getCategories(map, node) {
            return [
                'DBINS_DEPLOYED_IN',
                'DBINS_DEPENDENCE_BY',
            ]
        },

        _getDefaultDataFields(map, node) {
            return {
                ...ComponentNode._getDefaultDataFields.call(this, map, node),
                dbDefault: '0',
            }
        },

        _pushDataSlots: {
            archDbInsDeploys: [],
        },
    })
}
