import extendNode from '../extendNode.mjs'
import useAbstractNode from '../_ABSTRACT/useAbstractNode.jsx'
import meta from './metaMidins_.mjs'
import FormChoose from '../MIDINS/FormChooseMidins.jsx'
import TableChoose from '../MIDINS/TableChooseMidins.jsx'
import api from './apiMidins_.mjs'

export default () => {
    const AbstractNode = useAbstractNode()

    return extendNode(AbstractNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
    })
}
