import {SettingOutlined} from '@ant-design/icons'
import SettingsPane from './SettingsPane.jsx'

export default () => {
    return {
        icon: <SettingOutlined />,
        key: 'settings',
        hotkey: 'Ctrl+Shift+S',
        name: '设置',
        pane: <SettingsPane />,
    }
}
