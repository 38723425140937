import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    pmUserCode: {
        name: () => '流程域代码',
    },

    pmName: {
        name: () => '流程域名称',
        required: () => true,
    },
})
