import {Button} from 'antd'
import {useOpenModal} from '@/components/Modal/Modal.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import ModalVersions from '../ModalVersions.jsx'

export default function ButtonChangeVersion({node, ...props}) {
    const openModal = useOpenModal()
    const map = useMapContext()
    const permission = map.usePermission()
    const {bizNodeType, prjId} = node.data
    const BizNode = map.BizNode[bizNodeType]
    const {classes} = BizNode

    if (! (
        permission.has('write') &&
        BizNode.canChangeVersion?.(map, node) &&
        (classes.includes('module') || ! classes.includes('component'))
    )) {
        return null
    }

    const handleClick = async () => {
        const {mapIdProp, mapTypeCode} = BizNode

        const modalProps = (() => {
            if (mapIdProp) {
                const {[mapIdProp]: dataId} = node.data
                return {dataId, dataType: mapTypeCode}
            }
            else {
                const {pkid} = node.data

                return {
                    dataId: pkid,
                    dataType: bizNodeType,
                }
            }
        })()

        const version = await openModal(
            <ModalVersions
                prjId={prjId}
                {...modalProps}
            />
        )

        if (! version) {
            return
        }

        map.cmd(async () => {
            const {dataVer} = version
            await BizNode.changeVersion(map, node, dataVer)
        })
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >
            加载
        </Button>
    )
}
