import Table from '@/components/Table/Table.jsx'
import TableChooseComponent from '../_COMPONENT/TableChooseExternalComponent.jsx'

export default function TableChooseIe(props) {
    const columns = [
        {
            title: '分析对象名称',
            dataIndex: 'aoName',
            component: <Table.ViewText />,
        },

        {
            title: '分析对象代码',
            dataIndex: 'aoNo',
            component: <Table.ViewText />,
        },

        {
            title: '指标实体标题',
            dataIndex: 'ieTitle',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseComponent
            columns={columns}
            {...props}
        />
    )
}
