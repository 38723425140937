import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelMidins.mjs'

const PropertiesPaneMidins = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="middlewareInsName" />
                <LongText prop="middlewareInsDesc" />
                <LongText prop="middlewareInsMemo" />
                <Text prop="middlewareInsVersion" />
                <Text prop="middlewareInsSchema" />
                <Text prop="middlewareInsIntranetIp" />
                <Text prop="middlewareInsInternetIp" />
                <Text prop="middlewareInsPort" />

                <Select
                    options={[
                        ['NORMAL', '正常'],
                        ['DAMAGED', '损坏'],
                        ['REPAIR', '修理'],
                        ['MAINTAIN', '保养'],
                        ['UNKNOWN', '未知'],
                    ]}

                    prop="middlewareInsStatus"
                />

                <Text prop="middlewareInsBu" />
                <Text prop="middlewareInsDm" />
                <Text prop="middlewareInsOm" />
                <Text prop="middlewareInsEnv" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'ChgReference',
                    'Reference',
                    'ReferenceCall',
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneMidins
