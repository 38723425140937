import useSwrNoCache from '@/script/useSwrNoCache.mjs'
import Table from '@/components/Table/Table.jsx'
import http from '@/biz/http.mjs'

const apiTableConfig = {
    read: async tableId => {
        const {comConfig} = await http.get('/loadUserTableCom', {tableId})
        return comConfig ? JSON.parse(comConfig) : {}
    },

    update: (tableId, config) => http.post(
        '/saveUserTableCom',
        {tableId, comConfig: JSON.stringify(config)}
    ),
}

const useTableConfig = id => {
    const {data, mutate} = useSwrNoCache(
        () => apiTableConfig.read(id),
        {dependencies: [id]}
    )

    if (! id) {
        return []
    }

    const update = cfg => mutate(
        async () => {
            await apiTableConfig.update(id, cfg)
            return {...cfg, t: Date.now()}
        },

        {populateCache: true, revalidate: false}
    )

    return [data, update]
}

const BizTable = ({code, ...props}) => {
    const [config, updateConfig] = useTableConfig(code)

    return (
        <Table
            config={config}
            onChangeConfig={updateConfig}
            {...props}
        />
    )
}

Object.assign(BizTable, Table)
export default BizTable
