import Table from '@/components/Table/Table.jsx'
import TableChooseComponent from '../_COMPONENT/TableChooseComponent.jsx'

export default function TableChooseOu(props) {
    const columns = [
        {
            title: '组织对象名称',
            dataIndex: 'ooName',
            component: <Table.ViewText />,
        },

        {
            title: '组织对象代码',
            dataIndex: 'ooNo',
            component: <Table.ViewText />,
        },

        {
            title: '组织单元标题',
            dataIndex: 'ouName',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseComponent
            columns={columns}
            {...props}
        />
    )
}
