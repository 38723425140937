import {css} from '@emotion/react'
import ShowHiddenNodes from './ShowHiddenNodes.jsx'
import ShowNodeDesc from './ShowNodeDesc.jsx'

const cssPane = css({
    display: 'grid',
})

export default function Settings(props) {
    return (
        <div
            css={cssPane}
            {...props}
        >
            <ShowHiddenNodes />
            <ShowNodeDesc />
        </div>
    )
}
