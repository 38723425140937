import {useEffect, useState} from 'react'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {subscribe, unsubscribe} from '@/script/event.mjs'

const getItems = map => {
    const next = chain => {
        const {isHidden} = chain[0]
        return {yieldNode: isHidden}
    }

    return [...map.dfs(map.root.children, next)]
}

export default () => {
    const map = useMapContext()
    const [items, setItems] = useState(() => getItems(map))

    useEffect(
        () => {
            const handleModelChange = () => {
                map.logger.withOff(
                    () => setItems(getItems(map))
                )
            }

            subscribe(map, 'model_change', handleModelChange)

            return () => {
                unsubscribe(map, 'model_change', handleModelChange)
            }
        },

        []
    )

    return items
}
