import ModulePropertiesPane from '../_MODULE/ModulePropertiesPane.jsx'
import Check from '../../SidePanel/RightPanel/PropertiesControllerCheck.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import ButtonCode from './PropertiesPaneButtonCode.jsx'
import ButtonExportXml from './PropertiesPaneButtonExportXml.jsx'
import Model from './ModelVm.mjs'

const PropertiesPaneBm = (props) => (
    <ModulePropertiesPane
        buttons={
            <>
                <ButtonCode />
                <ButtonExportXml nodes={props.nodes} />
            </>
        }

        controllers={
            <>
                <Text prop="vmUserCode" />
                <Text prop="vmName" />
                <Text prop="vmPack" />
                <Check prop="isClass" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'Reference',
                    'ReferenceCall',
                ]}
            />
        }

        versionLabel="程序地图版本号"
        {...props}
    />
)

export default PropertiesPaneBm
