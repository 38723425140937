import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'riUserCode',
    mapProp: 'riMap',
    name: '报表指标',
    ownerIdProp: 'arId',
    ownerTextProp: 'arName',
    ownerType: 'AMAR',
    textProp: 'riName',
    type: 'ARRI',
}
