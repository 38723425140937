import Table from '@/components/Table/Table.jsx'
import TableChooseComponent from '../_COMPONENT/TableChooseComponent.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

const TableChooseBdevent = (props) => {
    const map = useMapContext()

    const columns = [
        'BCT' === map.data.prjTypeCode && {
            title: '界面方案',
            dataIndex: 'uiCase',
            component: <Table.ViewText />,
        },

        {
            title: '业务对象/服务名称',
            dataIndex: 'bdName',
            component: <Table.ViewText />,
        },

        {
            title: '业务对象/服务代码',
            dataIndex: 'bdNo',
            component: <Table.ViewText />,
        },

        {
            title: '规则标题',
            dataIndex: 'eventTitle',
            component: <Table.ViewText />,
        },
    ].filter(a => a)

    return (
        <TableChooseComponent
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseBdevent
