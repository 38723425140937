import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaOuOu.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canLinkType(map, node, type) {
            return /^(OU|CAT)$/.test(type)
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['CAT'],
            ]
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['OU'],
            ]
        },

        async _atPush(map, node, event) {
            event.stopPropagation()

            const next = chain => {
                const _n = map.nodeProxy(chain[0])
                return {yieldChildren: _n.isMounted()}
            }

            for (const n of map.bfs(node.children, next)) {
                const {bizNodeType, ouCode} = n.data

                if ('OU' === bizNodeType) {
                    event.detail.pOuCodes.push(ouCode)
                }
            }
        },
    })
}
