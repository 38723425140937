import Table from '@/components/Table/Table.jsx'
import TableChooseComponent from '../_COMPONENT/TableChooseComponent.jsx'

const TableChooseMidins = (props) => {
    const columns = [
        {
            title: '中间件名称',
            dataIndex: 'middlewareName',
            component: <Table.ViewText />,
        },

        {
            title: '中间件类型',
            dataIndex: 'middlewareTypeName',
            component: <Table.ViewText />,
        },

        {
            title: '中间件实例名称',
            dataIndex: 'middlewareInsName',
            component: <Table.ViewText />,
        },

        {
            title: '环境',
            dataIndex: 'middlewareInsEnv',
            component: <Table.ViewText />,
        },

        {
            title: '内网IP',
            dataIndex: 'middlewareInsIntranetIp',
            component: <Table.ViewText />,
        },

        {
            title: '公网IP',
            dataIndex: 'middlewareInsInternetIp',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseComponent
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseMidins
