import {useEffect, useRef, useState} from 'react'
import {css} from '@emotion/react'
import Checkbox from '@/components/Form/Checkbox.jsx'
import {subscribe, unsubscribe} from '@/script/event.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'

const hideIrrelevant = map => {
    const walkArs = node => {
        const {rev: referredRev} = node.data

        for (const ar of node.children) {
            ar.isHidden = true
            const {arName: ver} = ar.data
            const rev = Number(ver.replace(/^\d+\.(\d+)$/g, '$1'))

            if (rev < referredRev) {
                for (const nn of ar.chain) {
                    nn.isHidden = false
                }
            }

            walkRefs(ar)
        }
    }

    const walkRefs = node => {
        for (const n of node.children) {
            walkArs(n)
        }
    }

    walkArs(map.root)
}

const showIrrelevant = map => {
    for (const n of map.dfs(map.root.children)) {
        n.isHidden = false
    }
}

const cssCheckbox = css({
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    gap: 8,
})

export default function ControllerHideIrrelevant(props) {
    const map = useMapContext()
    const [isChecked, setIsChecked] = useState(false)
    const refIsToggling = useRef(false)

    const toggleVisibility = async isHidden => {
        refIsToggling.current = true

        await map.execute(() => {
            if (isHidden) {
                hideIrrelevant(map)
            }
            else {
                showIrrelevant(map)
            }
        })

        refIsToggling.current = false
    }

    useEffect(
        () => {
            const handleModelChange = () => {
                if (! refIsToggling.current) {
                    toggleVisibility(isChecked)
                }
            }

            subscribe(map, 'model_change', handleModelChange)

            return () => {
                unsubscribe(map, 'model_change', handleModelChange)
            }
        },

        [isChecked, map, toggleVisibility]
    )

    const handleChange = isChecked => {
        setIsChecked(isChecked)
        toggleVisibility(isChecked)
    }

    return (
        <Checkbox
            css={cssCheckbox}
            value={isChecked}
            onChange={handleChange}
            {...props}
        >
            仅历史版本引用链
        </Checkbox>
    )
}
