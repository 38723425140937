import extendNode from '../extendNode.mjs'
import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaBdlogicUi.mjs'

export default () => {
    const ArCatNode = useArCatNode()

    return extendNode(ArCatNode, {
        ...meta,

        getText(map, node) {
            return '人机交互\n要求'
        },

        isHidden(map, node) {
            if (node.parent) {
                const {ruleType} = node.parent.data
                return 'BT' !== ruleType
            }
            else {
                return false
            }
        },
    })
}
