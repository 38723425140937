import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaBfChart.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(DOC|PIC)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'PIC'
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['DOC'],
                ['PIC'],
            ]
        },

        async _atPush(map, node, event) {
            event.stopPropagation()

            const next = chain => {
                const _n = map.nodeProxy(chain[0])
                return {yieldChildren: _n.isMounted()}
            }

            for (const n of map.bfs(node.children, next)) {
                const {bizNodeType} = n.data

                if (/^(DOC|PIC)$/.test(bizNodeType)) {
                    event.detail.fileList.push(n.data)
                }
            }
        },
    })
}
