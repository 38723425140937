export function canMoveTree(node) {
    if (! node.parent) {
        this.logger.error('不能移动没有父节点的节点', [node])
        return false
    }

    return true
}

const moveTree = (map, nodes, sort, getSibling, insert) => {
    for (const [, childNodes] of map.groupByParent(nodes)) {
        const sortedNodes = sort(childNodes)

        for (const node of sortedNodes) {
            let cursor = getSibling(node)

            while (cursor) {
                if (map.isNodeHidden(cursor)) {
                    cursor = getSibling(cursor)
                }
                else {
                    break
                }
            }

            map.unlinkTree(node)
            insert(cursor, node)
        }
    }
}

export function moveDownTree(nodes) {
    return moveTree(
        this,
        nodes,
        nodes => this.sortSiblings(nodes).reverse(),
        ({nextSibling}) => nextSibling,
        this.insertSiblingAfter.bind(this),
    )
}

export function moveUpTree(nodes) {
    return moveTree(
        this,
        nodes,
        nodes => this.sortSiblings(nodes),
        ({prevSibling}) => prevSibling,
        this.insertSiblingBefore.bind(this),
    )
}
