import Table from '@/components/Table/Table.jsx'
import TableChooseProduct from '../_PRODUCT/TableChooseProduct.jsx'

const TableChooseFc = (props) => {
    const columns = [
        {
            dataIndex: 'fmNamePath',
            title: '功能模块',
            component: <Table.ViewText />,
        },

        {
            dataIndex: 'ssName',
            title: '子系统',
            component: <Table.ViewText />,
        },

        {
            dataIndex: 'vmNamePath',
            title: '程序模块',
            component: <Table.ViewText />,
        },

        {
            dataIndex: 'fcUserCode',
            title: '能力代码',
            component: <Table.ViewText />,
        },

        {
            dataIndex: 'fcName',
            title: '能力名称',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseProduct
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseFc
