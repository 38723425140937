import MenuButton from '../../MenuButton.jsx'
import useMenuItemGrowNode from '../../../menuItems/useMenuItemGrowNode.jsx'
import useMenuItemGrowNodeRecursive from '../../../menuItems/useMenuItemGrowNodeRecursive.jsx'
import useMenuItemImportSnapshot from '../../../menuItems/useMenuItemImportSnapshot.jsx'
import useMenuItemImportTemplate from '../../../menuItems/useMenuItemImportTemplate.jsx'
import useMenuItemMakeAllProducts from '../../../menuItems/useMenuItemMakeAllProducts.jsx'
import useMenuItemMakeProduct from '../../../menuItems/useMenuItemMakeProduct.jsx'
import useMenuItemPullProduct from '../../../menuItems/useMenuItemPullProduct.jsx'
import useMenuItemPushProduct from '../../../menuItems/useMenuItemPushProduct.jsx'
import useMenuItemRefreshProduct from '../../../menuItems/useMenuItemRefreshProduct.jsx'
import useMenuItemSaveProduct from '../../../menuItems/useMenuItemSaveProduct.jsx'
import useMenuItemShareMap from '../../../menuItems/shareMap/useMenuItemShareMap.jsx'
import useMenuItemNodeDesc from '../../../menuItems/useMenuItemNodeDesc.jsx'
import useMenuItemShowHiddenNodes from '../../../menuItems/useMenuItemShowHiddenNodes.jsx'
import useMenuItemShrinkNode from '../../../menuItems/useMenuItemShrinkNode.jsx'

export default function MenuButtonMap(props) {
    const items = [
        useMenuItemImportTemplate(),
        useMenuItemPushProduct(),
        useMenuItemSaveProduct(),
        useMenuItemRefreshProduct(),
        useMenuItemPullProduct(),
        {divider: true},
        useMenuItemGrowNode(),
        useMenuItemGrowNodeRecursive(),
        useMenuItemShrinkNode(),
        {divider: true},
        useMenuItemMakeProduct(),
        useMenuItemMakeAllProducts(),
        {divider: true},
        useMenuItemImportSnapshot(),
        {divider: true},
        useMenuItemShareMap(),
        {divider: true},
        useMenuItemNodeDesc(),
        useMenuItemShowHiddenNodes(),
    ].filter(a => a)

    return (
        <MenuButton
            items={items}
            {...props}
        >
            地图
        </MenuButton>
    )
}
