import runAsync from '@/script/runAsync.mjs'
import apiSnapshot from '../../apis/apiSnapshot.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import useProject from '../useProject.mjs'
import useSwrNoCache from '@/script/useSwrNoCache.mjs'

export default pkid => {
    const {data: mapData} = useSwrNoCache(
        () => runAsync(
            () => apiSnapshot.read({pkid}),
            {action: '读取地图'}
        ),

        {dependencies: [pkid]}
    )

    const {data: {desgAcc} = {}} = useProject(mapData?.data.prjId)

    if (mapData && desgAcc) {
        Object.assign(mapData.data, {desgAcc})
        return parseMap(mapData)
    }
    else {
        return void 0
    }
}
