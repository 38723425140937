import {css} from '@emotion/react'
import {Radio} from 'antd'
import {useReadOnly} from './ReadOnly.mjs'

const cssGroup = css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: 4,
})

const cssRadio = css({
    flexGrow: 1,
})

const Group = ({options, ...props}) => {
    const children = options.map(
        ({value, label = value}) => (
            <Radio.Button
                css={cssRadio}
                key={value}
                value={value}
            >
                {label}
            </Radio.Button>
        )
    )

    return (
        <div
            css={cssGroup}
            {...props}
        >
            {children}
        </div>
    )
}

const cssRadioGroup = css({
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
})

const defaultOptions = []

export default function RadioGroup({
    readOnly,
    options = defaultOptions,
    onChange,
    ...props
}) {
    const gReadOnly = useReadOnly()

    const children = options.map(
        (opts, i) => (
            <Group
                key={i}
                options={opts}
            />
        )
    )

    const handleChange = readOnly || gReadOnly || ! onChange ?
        null
        :
        ({target: {value}}) => onChange(value)

    return (
        <Radio.Group
            css={cssRadioGroup}
            buttonStyle="solid"
            optionType="button"
            onChange={handleChange}
            {...props}
        >
            {children}
        </Radio.Group>
    )
}
