import {
    TreeDoc,
    useAutoScrollCanvas,
    useCancelNodeSelectionOnPointerDownCanvas,
    useCanvas,
    useCanvasSelection,
    useChangeHistory,
    useDom,
    useDragCanvas,
    useGodMode,
    useHotkey,
    useLogger,
    useMetaData,
    useNodeChange,
    useNodesChange,
    useScale,
    useScaleCanvasByWheel,
    useSelectionHistory,
    useSelectNodes,
    useTreeDoc,
    useUnfoledAncestorsWhenNodeIsSelected,
} from '@/components/TreeDoc/index.mjs'

import useEditNodeText from './plugins/useEditNodeText.mjs'
import useHideNode from './plugins/useHideNode.mjs'
import useMindMapPlugins from './plugins/useMindMapPlugins.mjs'
import useScrollNodeIntoView from './plugins/useScrollNodeIntoView.mjs'
import useSelectNodesByMouse from './plugins/useSelectNodesByMouse.mjs'
import useSelectRootOnDoubleClickCanvas from './plugins/useSelectRootOnDoubleClickCanvas.mjs'

export default ({
    components,
    Doc = TreeDoc,
    initData,
    plugins = [],
}) => {
    const treeDoc = useTreeDoc({
        Doc,
        initData,

        plugins: [
            useAutoScrollCanvas(),
            useAutoScrollCanvas(),
            useCancelNodeSelectionOnPointerDownCanvas(),
            useCanvas(),
            useCanvasSelection(),
            useChangeHistory(),
            useDom(['nodes', 'trees']),
            useDragCanvas(),
            useEditNodeText(),
            useGodMode(),
            useHideNode(),
            useHotkey(),
            useLogger(),
            useMetaData(initData.data),
            useMindMapPlugins(),
            useNodeChange(),
            useNodesChange(),
            //useScale(),
            //useScaleCanvasByWheel(),
            useScrollNodeIntoView(),
            useSelectionHistory(),
            useSelectNodes(),
            useSelectNodesByMouse(),
            useSelectRootOnDoubleClickCanvas(),
            useUnfoledAncestorsWhenNodeIsSelected(),
            ...plugins,
        ],
    })

    treeDoc.components = components
    return treeDoc
}
