import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import IconIe from '../IE/icon-ie.svg?react'
import api from './apiArri.mjs'
import meta from './metaArri.mjs'
import Model from './ModelArri.mjs'
import PropertiesPane from './PropertiesPaneArri.jsx'
import FormChoose from './FormChooseArri.jsx'
import TableChoose from './TableChooseArri.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        attrNodes: {
            top: ['ATTR_ARRI_RESUME', 'ATTR_ARRI_TITLE'],
        },

        getIcons(map, node) {
            return [
                <IconIe
                    key="type"
                    fill={'#000'}
                />
            ]
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    borderColor: Color.LIGHT_ORANGE,
                    backgroundColor: Color.LIGHT_ORANGE,
                }),

                shape: 'HorizontalHexagon',
            }
        },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.amArRis.push(d)
        },

        _getCategories(map, node) {
            return [
                'ARRI_AC',
            ]
        },
    })
}
