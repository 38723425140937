import {css} from '@emotion/react'
import {Button} from 'antd'
import {useMapContext} from '@/components/MindMap/index.mjs'

const cssToolBar = css({
    display: 'flex',
    justifyContent: 'center',
    gap: 8,
    padding: 8,
})

export default function ToolBar({items, selectedKeys, ...props}) {
    const map = useMapContext()

    const unhideNodes = nodes => {
        map.commands.unhideNode(nodes)
    }

    const handleClickUnhide = () => {
        const nodes = [...selectedKeys].map(id => map.getNode(id))
        unhideNodes(nodes)
    }

    const handleClickUnhideAll = () => {
        const nodes = map.commands.canUnhideNode(items)
        unhideNodes(nodes)
    }

    return (
        <div
            css={cssToolBar}
            {...props}
        >
            <Button
                disabled={0 === selectedKeys.size}
                size="small"
                onClick={handleClickUnhide}
            >显示</Button>

            <Button
                disabled={0 === items.length}
                size="small"
                onClick={handleClickUnhideAll}
            >全部显示</Button>
        </div>
    )
}
