import SidePanel from '@/components/TreeDoc/SidePanel.jsx'
import useExternalPane from '../../SidePanel/LeftPanel/ExternalPane/useExternalPane.jsx'
import useInvalidPane from '../../SidePanel/LeftPanel/InvalidPane/useInvalidPane.jsx'
import useMarkPane from '../../SidePanel/LeftPanel/MarkPane/useMarkPane.jsx'
import useMessagePane from '../../SidePanel/LeftPanel/MessagePane/useMessagePane.jsx'
import useSearchPane from '../../SidePanel/LeftPanel/SearchPane/useSearchPane.jsx'
import useSettingsPane from './SettingsPane/useSettingsPane.jsx'
import useUpgradeRefPane from './UpgradeRefPane/useUpgradeRefPane.jsx'

export default function LeftPanelChangeRef(props) {
    const panes = [
        useSearchPane(),
        useMarkPane(),
        useMessagePane(),
        useUpgradeRefPane(),
        useInvalidPane(),
        useExternalPane(),
        useSettingsPane(),
    ]

    return (
        <SidePanel
            direction="ltr"
            panelId="left"
            panes={panes}
            {...props}
        />
    )
}
