import {createContext, useContext} from 'react'

const ListContext = createContext()

export const DraggableListContextProvider = ListContext.Provider
export const useDraggableListContext = () => useContext(ListContext)

const ListItemContext = createContext()

export const DraggableListItemContextProvider = ListItemContext.Provider
export const useDraggableListItemContext = () => useContext(ListItemContext)
