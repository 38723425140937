import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'rdUserCode',
    mapProp: 'rdMap',
    name: '报表维度',
    ownerIdProp: 'arId',
    ownerTextProp: 'arName',
    ownerType: 'AMAR',
    textProp: 'rdName',
    type: 'ARRD',
}
