import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import meta from './metaAmar.mjs'
import Model from './ModelAmar.mjs'
import PropertiesPane from './PropertiesPaneAmar.jsx'
import FormChoose from './FormChooseAmar.jsx'
import TableChoose from './TableChooseAmar.jsx'
import api from './apiAmar.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        attrNodes: {
            top: ['ATTR_AMAR_RESUME'],
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.LIGHT_ORANGE,
                }),

                shape: 'Rectangle',
            }
        },

        _getCategories(map, node) {
            return [
                'AMAR_RS',
                'AMAR_RD',
                'AMAR_RI',
                'AMAR_DS_SCOPE',
                'AMAR_IV_SCOPE',
                'AMAR_DRILL',
            ]
        },

        _pushDataSlots: {
            amArRds: [],
            amArRis: [],
        },
    })
}
