import http from '@/biz/http.mjs'
import runAsync from '@/script/runAsync.mjs'
import Tree from '@/script/Tree.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import useSwrNoCache from '@/script/useSwrNoCache.mjs'

const getSubTreeModifiedCompareIds = (modifiedIds, treeData) => {
    const ids = new Set()
    const tree = new Tree(treeData)

    const next = (chain) => {
        const {mapCompUuid} = chain[0].data

        if (! mapCompUuid) {
            return {yieldChildren: false}
        }

        if (modifiedIds.has(mapCompUuid)) {
            for (const ancestor of chain.slice(1)) {
                const {mapCompUuid} = ancestor.data
                ids.add(mapCompUuid)
            }
        }
    }

    [...tree.dfs(tree.root, next)]
    return ids
}

const splitRev = (rev) => rev ? rev.split('.') : []

export default (id, type, leftRev, rightRev, basMapVerId, tarMapVerId) => {
    const {data} = useSwrNoCache(
        () => runAsync(
            () => {
                const [basRev, basSmallRev] = splitRev(leftRev)
                const [tarRev, tarSmallRev] = splitRev(rightRev)

                return http.post(
                    '/mapcompare/common/ids',

                    {
                        basMapVerId,
                        basRev,
                        basSmallRev,
                        id,
                        tarMapVerId,
                        tarRev,
                        tarSmallRev,
                        type,
                    }
                )
            },

            {action: '对比地图'}
        ),

        {
            dependencies: [
                basMapVerId,
                id,
                leftRev,
                rightRev,
                tarMapVerId,
                type,
            ],
        }
    )

    if (data) {
        const {
            basMap,
            basMemo,
            basRevInfo,
            basRlsInfo,
            changeList,
            tarMap,
            tarMemo,
            tarRevInfo,
            tarRlsInfo,
        } = data

        const differences = changeList.map(
            ({changeType, leftMapCompUuid, rightMapCompUuid}) => [
                changeType, leftMapCompUuid, rightMapCompUuid
            ]
        )

        const left = (() => {
            const map = parseMap(basMap)
            const _compareIdToDiffType = new Map()

            for (const [type, compareId] of differences) {
                if (compareId) {
                    _compareIdToDiffType.set(compareId, type)
                }
            }

            const _subTreeModifiedCompareIds = getSubTreeModifiedCompareIds(
                new Set(_compareIdToDiffType.keys()),
                map.root
            )

            Object.assign(map.data, {
                memo: basMemo,
                rev: basRevInfo,
                rls: basRlsInfo,
                _compareIdToDiffType,
                _subTreeModifiedCompareIds,
            })

            return map
        })()

        const right = (() => {
            const map = parseMap(tarMap)
            const _compareIdToDiffType = new Map()

            for (const [type, , compareId] of differences) {
                if (compareId) {
                    _compareIdToDiffType.set(compareId, type)
                }
            }

            const _subTreeModifiedCompareIds = getSubTreeModifiedCompareIds(
                new Set(_compareIdToDiffType.keys()),
                map.root
            )

            Object.assign(map.data, {
                memo: tarMemo,
                rev: tarRevInfo,
                rls: tarRlsInfo,
                _compareIdToDiffType,
                _subTreeModifiedCompareIds,
            })

            return map
        })()

        return [left, right, differences]
    }
    else {
        return []
    }
}
