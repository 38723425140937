import extendNode from '../extendNode.mjs'
import http from '@/biz/http.mjs'
import {usePreviewImages} from '@/components/PreviewImages/PreviewImages'
import Color from '../Color.mjs'
import {ValidationError} from '../Error.mjs'
import useCommonNode from '../_COMMON/useCommonNode.jsx'
import IconPic from './icon-pic.svg?react'
import meta from './metaPic.mjs'
import PropertiesPane from './PropertiesPanePic.jsx'

export default () => {
    const previewImages = usePreviewImages()
    const CommonNode = useCommonNode()

    return extendNode(CommonNode, {
        ...meta,
        PropertiesPane,

        canWriteData(map, node) {
            const _p = map.nodeProxy(node.parent)
            const {bizNodeType, demoType} = node.parent.data

            if (
                'UV' === bizNodeType &&
                'IMG' === demoType &&
                _p.isMounted()
            ) {
                return true
            }
            else {
                return CommonNode.canWriteData.call(this, map, node)
            }

        },

        getIcons() {
            return [<IconPic key="type" />]
        },

        getStyle(map, node) {
            return {
                ...CommonNode.getStyle.call(this, map, node),
                borderColor: Color.CYAN,
                shape: 'BottomWavyRectangle',
            }
        },

        async onDoubleClick(map, node, event) {
            const {fileId} = node.data

            if (fileId) {
                event.preventDefault()
                const url = `${import.meta.env.VITE_HTTP_BASE_URL}/files/${fileId}`

                try {
                    await http.head(url)
                    previewImages([url])
                }
                // eslint-disable-next-line no-empty
                catch (err) {
                }
            }
        },
    })
}
