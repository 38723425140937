import Table from '@/components/Table/Table.jsx'
import TableChooseProduct from '../_PRODUCT/TableChooseProduct.jsx'

const TableChooseDv = (props) => {
    const columns = [
        {
            title: '项目编号',
            dataIndex: 'prjNo',
            component: <Table.ViewText />,
        },

        {
            title: '数据库代码',
            dataIndex: 'dbUserCode',
            component: <Table.ViewText />,
        },

        {
            title: '库表域',
            dataIndex: 'dmNamePath',
            component: <Table.ViewText />,
        },

        {
            title: '数据库名称',
            dataIndex: 'dbName',
            component: <Table.ViewText />,
        },

        {
            title: '视图代码',
            dataIndex: 'dvUserCode',
            component: <Table.ViewText />,
        },

        {
            title: '视图名称',
            dataIndex: 'dvName',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseProduct
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseDv
