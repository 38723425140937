import useSwrNoCache from '@/script/useSwrNoCache.mjs'
import Table from '@/components/Table/Table.jsx'
import apiMap from '../../../apis/apiMap.mjs'

export default function TableVersions({
    dataId,
    dataType,
    prjId,
    onChange,
    ...props
}) {
    const {data, isLoading} = useSwrNoCache(
        () => apiMap.readVersions({dataId, dataType, prjId}),
        {dependencies: [dataId, dataType, prjId]}
    )

    const rowSelection = {
        onChange: (_, selectedRows) => onChange(selectedRows[0]),
        type: 'radio',
    }

    const columns = [
        {
            dataIndex: 'crtTime',
            title: '发布时间',
            component: <Table.ViewText />,
            width: 180,
        },

        {
            dataIndex: 'crtName',
            title: '发布人',
            component: <Table.ViewText />,
            width: 120,
        },

        {
            title: '基线号',
            dataIndex: 'sVer',
            component: <Table.ViewText />,
            width: 80,
        },

        {
            dataIndex: 'dataVer',
            title: '版本号',
            component: <Table.ViewText />,
            width: 80,
            //render: (text, record, index) => {
                //return <ViewVersion value={{ dataVer: record?.dataVer ?? record?.verFlag, pkid: record?.pkid, dataType: record.dataType }} />;
            //}
        },

        {
            dataIndex: 'deliverFlag',
            title: '是否交付',
            component: <Table.ViewBoolean />,
            width: 90,
        },

        {
            dataIndex: 'deliverUserName',
            title: '交付人',
            component: <Table.ViewText />,
            width: 120,
        },

        {
            dataIndex: 'deliverTime',
            title: '交付时间',
            component: <Table.ViewText />,
            width: 180,
        },

        {
            dataIndex: 'taskNo',
            title: '业务需求号',
            component: <Table.ViewText />,
            width: 120,
        },

        {
            dataIndex: 'memo',
            title: '备注',
            component: <Table.ViewLongText />,
            width: 120,
        },
    ]

    return (
        <Table
            columns={columns}
            dataSource={data}
            loading={isLoading}
            rowKey="pkid"
            rowSelection={rowSelection}
            selectRowOnClick
            {...props}
        />
    )
}
