import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    amCode: {
        name: () => '分析域',
    },

    aoName: {
        name: () => '分析对象名称',
        required: () => true,
    },

    aoNo: {
        name: () => '分析对象编号',
        required: () => true,
    },

    memo: {
        name: () => '备注',
    },

    resume: {
        name: () => '简述',
    },
})
