import superMeta from '../_PRODUCT/metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'doNo',
    detailUrl: '/DoDetail',
    detailIdProp: 'doId',
    mapProp: 'doMap',
    name: '维度对象',
    textProp: 'doName',
    type: 'DO',
}
