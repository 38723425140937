import extendNode from '../extendNode.mjs'
import useParamNode from '../_PARAM/useParamNode.jsx'
import meta from './metaRep.mjs'

export default () => {
    const ParamNode = useParamNode()

    return extendNode(ParamNode, {
        ...meta,

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.repList.push(d)
        },
    })
}
