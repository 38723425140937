import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useModuleNode from '../_MODULE/useModuleNode.jsx'
import api from './apiAm.mjs'
import meta from './metaAm.mjs'
import Model from './ModelAm.mjs'
import PropertiesPane from './PropertiesPaneAm.jsx'

export default () => {
    const ModuleNode = useModuleNode()

    return extendNode(ModuleNode, {
        ...meta,
        api,
        Model,
        PropertiesPane,

        getStyle(map, node) {
            return this._getStyle(map, node, {borderColor: Color.LIGHT_ORANGE})
        },

        async _atOwnerPush(map, node, event) {
            const {bizNodeType} = event.target.data

            const key = {
                AA: 'archAmList',
                AM: 'child'
            }[bizNodeType]

            const d = await this._getPushData(map, node)
            event.detail[key].push(d)
        },
    })
}
