import Table from '@/components/Table/Table.jsx'
import TableChooseComponent from '../_COMPONENT/TableChooseComponent.jsx'

const TableChooseBfdataflow = (props) => {
    const columns = [
        {
            title: '业务流程名称',
            dataIndex: 'bfName',
            component: <Table.ViewText />,
        },

        {
            title: '业务流程代码',
            dataIndex: 'bfNo',
            component: <Table.ViewText />,
        },

        {
            title: '业务场景标题',
            dataIndex: 'algTitle',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseComponent
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseBfdataflow
