import Table from '@/components/Table/Table.jsx'
import TableChooseComponent from '../_COMPONENT/TableChooseComponent.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

const TableChooseUirule = (props) => {
    const map = useMapContext()

    const columns = [
        'BCT' === map.data.prjTypeCode && {
            title: '界面方案',
            dataIndex: 'uiCase',
            component: <Table.ViewText />,
        },

        {
            title: '界面名称',
            dataIndex: 'uiName',
            component: <Table.ViewText />,
        },

        {
            title: '界面编号',
            dataIndex: 'uiUserCode',
            component: <Table.ViewText />,
        },

        {
            title: '规则标题',
            dataIndex: 'ruleTitle',
            component: <Table.ViewText />,
        },
    ].filter(a => a)

    return (
        <TableChooseComponent
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseUirule
