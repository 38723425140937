import {cloneElement, createContext, useContext} from 'react'
import {css} from '@emotion/react'
import {usePopover} from '@/components/Popover/index.mjs'
import Divider from './ContextMenuDivider.jsx'
import MenuItem from './ContextMenuItem.jsx'
import SubMenu from './ContextSubMenu.jsx'

export const ContextMenuContext = createContext()
export const useContextMenuContext = () => useContext(ContextMenuContext)

export const cssContextMenu = css({
    display: 'grid',
    gridAutoColumns: 'auto',
    maxWidth: '100vw',
    maxHeight: '100vh',
    backgroundColor: '#fff',
    padding: '2px 0',
    overflow: 'auto',

    boxShadow: `
        rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px
    `,

    userSelect: 'none',
})

const cssContextMenuItemColumns1 = css({
    gridColumn: 'span 1',
})

const cssContextMenuItemColumns2 = css({
    gridColumn: 'span 2',
})

const cssContextMenuItemColumns3 = css({
    gridColumn: 'span 3',
})

export default function ContextMenu({items, ...props}) {
    const popover = usePopover()
    const supMenu = useContextMenuContext()

    const context = supMenu ?? {
        close: () => popover.close()
    }

    const handleClick = e => {
        if (! e.defaultPrevented) {
            context.close()
        }
    }

    const [hasPrefix, hasSuffix] = (() => {
        let hasPrefix = false
        let hasSuffix = false

        for (const {children, prefix, suffix} of items) {
            if (! hasPrefix && prefix) {
                hasPrefix = true
            }

            if (! hasSuffix && (suffix || children)) {
                hasSuffix = true
            }

            if (hasPrefix && hasSuffix) {
                break
            }
        }

        return [hasPrefix, hasSuffix]
    })()

    const cssItem = (() => {
        if (hasPrefix && hasSuffix) {
            return cssContextMenuItemColumns3
        }
        else if (hasPrefix || hasSuffix) {
            return cssContextMenuItemColumns2
        }
        else {
            return cssContextMenuItemColumns1
        }
    })()

    const children = items.map(
        ({children, divider, element, label, key = label, ...props}, i) => {
            if (divider) {
                return (
                    <Divider
                        key={`DIVIDER-${i}`}
                        css={cssItem}
                    />
                )
            }
            else if (children) {
                return (
                    <SubMenu
                        key={key}
                        css={cssItem}
                        hasPrefix={hasPrefix}
                        hasSuffix={hasSuffix}
                        items={children}
                        label={label}
                        {...props}
                    />
                )
            }
            else if (element) {
                return cloneElement(element, {
                    key,
                    css: cssItem,
                    hasPrefix,
                    hasSuffix,
                    ...props
                })
            }
            else {
                return (
                    <MenuItem
                        key={key}
                        css={cssItem}
                        label={label}
                        hasPrefix={hasPrefix}
                        hasSuffix={hasSuffix}
                        {...props}
                    />
                )
            }
        }
    )

    return (
        <div
            css={cssContextMenu}
            onClick={handleClick}
            {...props}
        >
            <ContextMenuContext.Provider value={context} >
                {children}
            </ContextMenuContext.Provider>
        </div>
    )
}
