import {css} from '@emotion/react'
import {Checkbox} from 'antd'
import {MenuOutlined} from '@ant-design/icons'

import {
    DraggableList,
    DraggableListItem,
    DraggableListTrigger,
} from '@/components/DraggableList/index.mjs'

const cssList = css({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, auto)',
})

const cssListItem = css({
    display: 'grid',
    gridTemplateColumns: 'subgrid',
    gridColumn: 'span 3',
    alignItems: 'center',
    gap: 12,
    minHeight: 32,
    padding: '0 16px',
})

const cssListItemPrefix = css({
})

const cssListItemSuffix = css({
})

const ColumnVisibilityListItem = ({label, isHidden, onChange, ...props}) => {
    const onClick = e => {
        e.preventDefault()
        onChange(! isHidden)
    }

    const prefix = <Checkbox checked={! isHidden} />

    const suffix = (
        <DraggableListTrigger>
            <MenuOutlined />
        </DraggableListTrigger>
    )

    return (
        <DraggableListItem
            css={cssListItem}
            onClick={onClick}
            {...props}
        >
            <div css={cssListItemPrefix}>{prefix}</div>
            {label}
            <div css={cssListItemSuffix}>{suffix}</div>
        </DraggableListItem>
    )
}

const ColumnVisibilityList = ({css, table, ...props}) => {
    const {columns} = table.useConfig()

    const columnsMap = new Map(
        table.columns.map(e => [e.key ?? e.dataIndex, e])
    )

    const children = columns.map(({id, hidden}) => {
        const column = columnsMap.get(id)
        const {title, titleText} = column

        const onChange = hidden => {
            table.setColumnConfig(id, cfg => ({...cfg, hidden}))
        }

        return (
            <ColumnVisibilityListItem
                key={id}
                isHidden={hidden}
                label={titleText ?? title}
                onChange={onChange}
            />
        )
    })

    const handleChange = indexes => {
        table.setConfig(cfg => ({
            ...cfg,
            columns: indexes.map(i => cfg.columns[i]),
        }))
    }

    return (
        <DraggableList
            css={[css, cssList]}
            onChange={handleChange}
            {...props}
        >
            {children}
        </DraggableList>
    )
}

export default (table) => {
    const children = [{
        element: <ColumnVisibilityList table={table} />
    }]

    return {
        children,
        disabled: 0 === table.config.columns.length,
        label: '隐藏/显示列',
        key: 'column-visibility',
    }
}
