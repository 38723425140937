import {SettingOutlined} from '@ant-design/icons'
import Settings from './Settings.jsx'

export default () => {
    return {
        icon: <SettingOutlined />,
        key: 'settings',
        hotkey: 'Ctrl+Shift+S',
        name: '设置',
        pane: <Settings />,
    }
}
