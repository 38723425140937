import superMeta from '../_MODULE/metaModuleNode.mjs'

export default {
    ...superMeta,
    codeProp: 'amUserCode',
    mapIdProp: 'anaMapId',
    mapStsCodeProp: 'anaMapStsCode',
    mapTypeCode: 'ANA_MAP',
    name: '分析域',
    ownerIdProp: 'aaId',
    ownerTextProp: 'aaName',
    ownerType: 'AA',
    textProp: 'amName',
    type: 'AM',
}
