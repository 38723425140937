import {useMapContext} from '@/components/MindMap/index.mjs'

const key = 'hide-node'

export default () => {
    const map = useMapContext()
    const {canHideNode, canUnhideNode, hideNode, unhideNode} = map.commands
    const selectedNodes = map.useSelectedNodes()

    {
        const nodes = canUnhideNode(selectedNodes)

        if (0 < nodes.length) {
            return {
                key,
                label: '显示',
                onClick: () => unhideNode(nodes),
            }
        }
    }

    {
        const nodes = canHideNode(selectedNodes)

        if (0 < nodes.length) {
            return {
                key,
                label: '隐藏',
                onClick: () => hideNode(nodes),
            }
        }
    }

    return {
        key,
        disabled: true,
        label: '隐藏',
    }
}
