import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useModuleNode from '../_MODULE/useModuleNode.jsx'
import api from './apiUm.mjs'
import meta from './metaUm.mjs'
import Model from './ModelUm.mjs'
import PropertiesPane from './PropertiesPaneUm.jsx'

export default () => {
    const ModuleNode = useModuleNode()

    return extendNode(ModuleNode, {
        ...meta,
        api,
        Model,
        PropertiesPane,

        getStyle(map, node) {
            const {bizNodeType} = node.parent?.data ?? {}

            const shape = this.type === bizNodeType ?
                'HorizontalHexagon' : 'RoundedRectangle'

            return {
                ...this._getStyle(map, node, {borderColor: Color.LIGHT_BLUE}),
                shape,
            }
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['UM'],
            ]
        },

        async _atOwnerPush(map, node, event) {
            const {bizNodeType} = event.target.data

            const key = {
                UA: 'archUmList',
                UM: 'child'
            }[bizNodeType]

            const d = await this._getPushData(map, node)
            event.detail[key].push(d)
        },
    })
}
