import {useOpenModal} from '@/components/Modal/Modal.jsx'
import SelectDptDialog from '@/pages/MainPage/dialogs/SelectDptDialog.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import parseMap from '../scripts/parseMap.mjs'

export default () => {
    const openModal = useOpenModal()
    const map = useMapContext()
    const permission = map.usePermission()

    if (! permission.has('write')) {
        return null
    }

    const onClick = async () => {
        const tmpl = await openModal(
            <SelectDptDialog
                dpType={map.root.data.bizNodeType}
                sfTypeCode={map.root.data.sfTypeCode}
                setVisible={() => {}}
            />
        )

        if (! tmpl) {
            return
        }

        const {root: {children}} = parseMap(tmpl)

        await map.cmd(async () => {
            for (const child of [...map.children(map.root)]) {
                map.deleteTree(child)
            }

            for (const child of children) {
                const childNode = map.importTree(child)
                map.appendChild(map.root, childNode)
            }

            const _n = map.nodeProxy(map.root)
            await _n.emitEventDown({type: 'import'})
        })
    }

    return {
        key: 'import-template',
        label: '导入模板',
        onClick,
    }
}
