import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import meta from './metaAo.mjs'
import Model from './ModelAo.mjs'
import PropertiesPane from './PropertiesPaneAo.jsx'
import FormChoose from './FormChooseAo.jsx'
import TableChoose from './TableChooseAo.jsx'
import api from './apiAo.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        attrNodes: {
            top: ['ATTR_AO_RESUME'],
        },

        async choose(map, node) {
            const getQuery = ({ams = [], ...query}) => ({
                ...query,
                amIds: ams.map(({amId}) => amId),
            })

            return this._choose(map, node, {getQuery})
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {backgroundColor: Color.CYAN}),
                shape: 'SingleBreakangle',
            }
        },

        _getCategories(map, node) {
            return [
                'AO_IE',
                'AO_AD',
                'AO_DS',
                'AO_DT',
            ]
        },

        _pushDataSlots: {
            adList: [],
            ieList: [],
        },
    })
}
