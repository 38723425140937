import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaFcParam.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        _getCategories(map, node) {
            return [
                'FC_REQ',
                'FC_REP',
            ]
        },
    })
}
