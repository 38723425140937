import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    amCode: {
        name: () => '分析域',
    },

    doNo: {
        name: () => '维度对象编号',
        required: () => true,
    },

    doName: {
        name: () => '维度对象名称',
        required: () => true,
    },

    memo: {
        name: () => '备注',
    },

    resume: {
        name: () => '简述',
    },
})
