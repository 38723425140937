const pasteTree = async (map, nodes, insert) => {
    const data = await map.actions.readTreeFromClipboard()
    const nodesToSelect = new Set()

    await Promise.all(
        nodes.map(async node => Promise.all(
            data.trees.map(async tree => {
                const n = map.importTree(tree)
                insert(node, n)
                const _n = map.nodeProxy(n)
                await _n.emitEventUp({type: 'attach'})

                if (! n.isDeleted) {
                    map.logger.info('从剪贴板粘贴', [n])
                    nodesToSelect.add(n)
                }
            })
        ))
    )

    map.selectNodes(nodesToSelect)
}

export const canPasteTreeAfter = () => defaultActions => {
    return function (node) {
        if (! this.permission.has('write')) {
            this.logger.error('没有权限', [node])
            return false
        }

        if (! defaultActions.canPasteTreeAfter(node)) {
            return false
        }

        const _p = this.nodeProxy(node.parent)

        if (! _p.canWriteTree()) {
            return false
        }

        let cursor = node.nextSibling

        while (cursor) {
            const _n = this.nodeProxy(cursor)

            if (! _n.isHidden() && _n.canMove()) {
                return true
            }

            cursor = cursor.nextSibling
        }

        return false
    }
}

export const canPasteTreeAppend = () => defaultActions => {
    return function (node) {
        if (! this.permission.has('write')) {
            this.logger.error('没有权限', [node])
            return false
        }

        return defaultActions.canPasteTreeAppend(node)
    }
}

export const pasteTreeAfter = () => () => {
    return async function (nodes) {
        await pasteTree(
            this,
            nodes,
            this.insertSiblingAfter.bind(this)
        )
    }
}

export const pasteTreeAppend = () => () => {
    return async function (nodes) {
        const insert = (node, child) => {
            if (node.lastChild) {
                let cursor = node.lastChild

                while (cursor) {
                    const _n = this.nodeProxy(cursor)

                    if (! _n.isHidden() && _n.canMove()) {
                        this.insertSiblingAfter(cursor, child)
                        return
                    }

                    cursor = cursor.prevSibling
                }

                this.prependChild(node, child)
            }
            else {
                this.appendChild(node, child)
            }
        }

        await pasteTree(this, nodes, insert)
    }
}
