import Table from '@/components/Table/Table.jsx'
import TableChooseProduct from '../_PRODUCT/TableChooseProduct.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

const TableChooseUc = (props) => {
    const map = useMapContext()

    const columns = [
        {
            title: '功能模块',
            dataIndex: 'fmNamePath',
            component: <Table.ViewText />,
        },

        'BCT' === map.data.prjTypeCode && {
            title: '界面方案',
            dataIndex: 'uiCase',
            component: <Table.ViewText />,
        },

        {
            title: '集成用例代码',
            dataIndex: 'sfNo',
            component: <Table.ViewText />,
        },

        {
            title: '集成用例名称',
            dataIndex: 'sfName',
            component: <Table.ViewText />,
        },
    ].filter(a => a)

    return (
        <TableChooseProduct
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseUc
