import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useArchNode from '../_ARCH/useArchNode.jsx'
import meta from './metaAa.mjs'
import PropertiesPane from './PropertiesPaneAa.jsx'
import api from './apiAa.mjs'

export default () => {
    const ArchNode = useArchNode()

    return extendNode(ArchNode, {
        ...meta,
        api,
        PropertiesPane,

        getStyle(map, node) {
            return this._getStyle(map, node, {
                backgroundColor: Color.LIGHT_ORANGE,
            })
        },

        getText(map, node) {
            const {mapTypeCode, prjTypeCode} = map.data

            if ('SA_MAP' === mapTypeCode) {
                return {
                    BAM: '基准模型',
                    TAM: '主题模型',
                }[prjTypeCode] ?? this.alias
            }
            else {
                return ArchNode.getText.call(this, map, node)
            }
        },

        _pushDataSlots: {
            archAmList: [],
        },
    })
}
