import Checkbox from '@/components/Form/Checkbox.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import cssSettingsItem from './cssSettingsItem.mjs'

export default function ShowHiddenNodes(props) {
    const map = useMapContext()
    const showHiddenNodes = map.useShowHiddenNodes()

    const handleChange = show => {
        map.showHiddenNodes = show
    }

    return (
        <Checkbox
            css={cssSettingsItem}
            onChange={handleChange}
            value={showHiddenNodes}
            {...props}
        >
            显示隐藏节点
        </Checkbox>
    )
}
