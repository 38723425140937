import BaseMap from '../../BaseMap.jsx'
import hotkeys from './hotkeys.mjs'
import useMapData from './useMapData.mjs'
import useMindMapPlugins from './useMindMapPlugins.mjs'
import usePermission from './usePermission.mjs'
import ToolBar from './ToolBar/ToolBar.jsx'

const components = {ToolBar}

const NavigatorMap = ({
    id,
    patternRev,
    permission,
    prjPatternId,
    ...props
}) => {
    const initData = useMapData(id)

    if (initData) {
        Object.assign(initData.data, {patternRev, prjPatternId})
    }

    const plugins = [
        useMindMapPlugins(),
        usePermission(permission, initData?.data?.prjId),
    ]

    return (
        <BaseMap
            components={components}
            hotkeys={hotkeys}
            initData={initData}
            plugins={plugins}
            {...props}
        />
    )
}

export default NavigatorMap
