import extendNode from '../extendNode.mjs'
import useAbstractNode from '../_ABSTRACT/useAbstractNode.jsx'
import meta from './metaBd_1.mjs'
import FormChoose from './FormChooseBd_1.jsx'
import TableChoose from './TableChooseBd_1.jsx'
import api from './apiBd_1.mjs'

export default () => {
    const AbstractNode = useAbstractNode()

    return extendNode(AbstractNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,

        async _atCreate(map, node, event) {
            node.data = {
                ...node.data,
                bizNodeType: 'BD',
                dpType: 'BD',
            }

            await map.BizNode.BD._atCreate.call(this, map, node, event)
        }
    })
}
