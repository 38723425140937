import {toNavigatorMap} from '../../../compatibility.mjs'
import apiMap from '../../../apis/apiMap.mjs'

export const canSaveMap = () => () => {
    return function () {
        if (! /^DRAFT|REVISE$/.test(this.data.mapStsCode)) {
            this.logger.error('只有草稿/修订状态的地图才能保存')
            return false
        }

        if (! this.permission.has('write')) {
            this.logger.error('没有保存权限')
            return false
        }

        return true
    }
}

export const saveMap = () => {
    return () => function () {
        return this.runAction(
            async () => {
                const {data} = this
                const _n = this.nodeProxy(this.root)
                const compact = 'UI_MAP' !== this.data.mapTypeCode
                const root = _n.exportTree({compact})
                const mapModel = toNavigatorMap({data, root})

                const {
                    mapModel: dummy,
                    ...newData
                } = await apiMap.save({...this.data, mapModel})

                this.data = {...this.data, ...newData}
            },

            {
                action: '保存地图',
                success: {show: true},
            },
        )
    }
}
