import {useMapContext} from '@/components/MindMap/index.mjs'

export default (type) => {
    const map = useMapContext()
    map.usePermission()
    const selectedNodes = map.useSelectedNodes()
    const nodes = map.commands.canInsertChild(selectedNodes)

    if (0 === nodes.length) {
        return []
    }

    const [menuItemsProp, callbackProp] = {
        common: ['menuItemsInsertCommon', 'onInsertCommon'],
        concept: ['menuItemsInsertConcept', 'onInsertConcept'],
        product: ['menuItemsInsertProduct', 'onInsertProduct'],
    }[type]

    const menuItems = nodes.map(n => {
        const _n = map.nodeProxy(n)
        return _n[menuItemsProp]()
    })

    const itemTypes = menuItems.map(
        items => new Set(
            items.map(([type]) => type)
        )
    )

    let commonTypes = new Set(itemTypes[0])

    for (let i = 1; i < menuItems.length; i += 1) {
        commonTypes = commonTypes.intersection(itemTypes[i])
    }

    const createMenuItem = ([
        type,
        label = map.BizNode[type].name,
        children,
    ]) => {
        if (children) {
            return {
                key: type,
                label,
                children: children.map(createMenuItem),
            }
        }
        else {
            return {
                key: type,
                label,

                onClick: () => {
                    map.BizNode[type][callbackProp](map, nodes)
                }
            }
        }
    }

    return menuItems[0]
        .filter(([type]) => commonTypes.has(type))
        .map(createMenuItem)
}
