import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    dataLen: {
        name: () => '数据长度',
    },

    dataType: {
        name: () => '数据类型',
    },

    dataUnit: {
        name: () => '数据单位',
    },

    ieTitle: {
        name: () => '指标实体标题',
        required: () => true,
    },

    ieUserCode: {
        name: () => '指标实体代码',
    },

    resume: {
        name: () => '简述',
    },
})
