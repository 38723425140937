import Table from '@/components/Table/Table.jsx'
import TableChooseProduct from '../_PRODUCT/TableChooseProduct.jsx'

const dictBool = {0: '否', 1: '是'}

const TableChooseDto = (props) => {
    const columns = [
        {
            title: 'DTO代码',
            dataIndex: 'dtoUserCode',
            component: <Table.ViewText />,
        },

        {
            title: 'DTO名称',
            dataIndex: 'dtoName',
            component: <Table.ViewText />,
        },

        {
            title: '是否DR',
            dataIndex: 'isDr',
            component: <Table.ViewDict dict={dictBool} />,
        },
    ]

    return (
        <TableChooseProduct
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseDto
