import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useAlgNode from '../_ALG/useAlgNode.jsx'
import PropertiesPane from '../BDALG/PropertiesPaneBdalg.jsx'
import PropertiesPaneBatch from '../BDALG/PropertiesPaneBatchBdalg.jsx'
import meta from './metaBdbase.mjs'

export default () => {
    const AlgNode = useAlgNode()

    return extendNode(AlgNode, {
        ...meta,
        PropertiesPane,
        PropertiesPaneBatch,

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.LIGHT_YELLOW,
            })
        },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.algList.push(d)
        },

        _getCategories(map, node) {
            return [
                'ALG_RULE',
                'ALG_ALG',
                'ALG_API',
            ]
        },
    })
}
