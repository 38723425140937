import extendNode from '../extendNode.mjs'
import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaLsiOutput.mjs'

export default () => {
    const ArCatNode = useArCatNode()

    return extendNode(ArCatNode, {
        ...meta,

        async _atPush(map, node, event) {
            event.stopPropagation()
            const d = await this._getPushData(map, node)
            event.detail.outputAlgList.push(d)
        },
    })
}
