import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'

const FormItemIeTitle = (props) => {
    return (
        <Form.Item
            label="分析指标标题"
            name="ieTitle"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemAoName = (props) => {
    return (
        <Form.Item
            label="分析对象名称"
            name="aoName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemAoNo = (props) => {
    return (
        <Form.Item
            label="分析对象代码"
            name="aoNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

export default function FormChooseIe(props) {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemAoName />
            </Col>

            <Col span={6}>
                <FormItemAoNo />
            </Col>

            <Col span={6}>
                <FormItemIeTitle />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}
