import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaPhysDeployedOn.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        _getCategories(map, node) {
            return [
                'PHYS_DEPLOYED_SSINS',
                'PHYS_DEPLOYED_DBINS',
                'PHYS_DEPLOYED_MININS',
                'PHYS_DEPLOYED_VR',
            ]
        },
    })
}
