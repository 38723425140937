import Checkbox from '@/components/Form/Checkbox.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import cssSettingsItem from './cssSettingsItem.mjs'

export default function ShowNodeDesc(props) {
    const map = useMapContext()
    const showNodeDesc = map.useShowNodeDesc()

    const handleChange = show => {
        map.showNodeDesc = show
    }

    return (
        <Checkbox
            css={cssSettingsItem}
            onChange={handleChange}
            value={showNodeDesc}
            {...props}
        >
            显示节点释义
        </Checkbox>
    )
}
