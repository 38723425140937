import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'ieUserCode',
    name: '指标实体',
    ownerIdProp: 'aoId',
    ownerTextProp: 'aoName',
    ownerType: 'AO',
    textProp: 'ieTitle',
    type: 'IE',
}
