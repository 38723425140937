import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaMidins.mjs'
import Model from './ModelMidins.mjs'
import PropertiesPane from './PropertiesPaneMidins.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    borderColor={Color.DARK_GREEN}
                    letters="M"
                    textColor="#000"
                />
            ]
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.DARK_GREEN,
            })
        },

        getTextPrefix(map, node) {
            const defaultPrefix = ComponentNode.getTextPrefix.call(
                this, map, node
            )

            const {middlewareInsEnv} = node.data

            if (middlewareInsEnv) {
                return `${defaultPrefix}【${middlewareInsEnv}】`
            }
            else {
                return defaultPrefix
            }
        },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.archMiddlewareInss.push(d)
        },

        _pushDataSlots: {
            archMiddlewareInsDeploys: [],
        },

        _getCategories(map, node) {
            return [
                'MIDINS_DEPLOYED_IN',
                'MIDINS_DEPENDENCE_BY',
            ]
        },
    })
}
