import Table from '@/components/Table/Table.jsx'
import TableChooseProduct from '../_PRODUCT/TableChooseProduct.jsx'

const TableChooseLsi = (props) => {
    const columns = [
        {
            dataIndex: 'lsName',
            title: '外联系统名称',
            component: <Table.ViewText />,
        },

        {
            dataIndex: 'lsiNo',
            title: '外联接口代码',
            component: <Table.ViewText />,
        },

        {
            dataIndex: 'lsiName',
            title: '外联接口名称',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseProduct
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseLsi
