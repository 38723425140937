import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelVr.mjs'

const PropertiesPaneVr = (props) => (
    <ProductPropertiesPane
        controllers={
            <>
                <Text prop="vsName" />
                <LongText prop="vsDesc" />
                <LongText prop="vsMemo" />

                <Select
                    options={[
                        ['NORMAL', '正常'],
                        ['DAMAGED', '损坏'],
                        ['REPAIR', '修理'],
                        ['MAINTAIN', '保养'],
                        ['UNKNOWN', '未知'],
                    ]}

                    prop="vsStatus"
                />

                <Text prop="vsBu" />
                <Text prop="vsDm" />
                <Text prop="vsOm" />
                <Text prop="vsEnv" />
                <Text prop="vsHostname" />
                <Text prop="vsIntranetIp" />
                <Text prop="vsInternetIp" />
                <LongText prop="vsOs" />
                <Text prop="vsKernelVersion" />
                <LongText prop="vsOpenPort" />
                <Text prop="vsCpu" />
                <Text prop="vsMemory" />
                <Text prop="vsHardDisk" />
                <Text prop="vsMac" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'ChgReference',
                    'Reference',
                    'ReferenceCall',
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneVr
