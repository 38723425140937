import extendNode from '../extendNode.mjs'
// import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaAmarRs.mjs'

export default () => {
    // const CategoryNode = useCategoryNode()
    const ArCatNode = useArCatNode()

    return extendNode(ArCatNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(AR|PIC|DOC)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'AR'
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['AR', '描述'],
                ['PIC', '图片'],
                ['DOC', '文件'],
            ]
        },
    })
}