import Table from '@/components/Table/Table.jsx'
import TableChooseProduct from './TableChooseProduct.jsx'

export default function TableChooseExternalProduct({columns, ...props}) {
    const enhancedColumns = [
        {
            title: '项目编号',
            dataIndex: 'prjNo',
            component: <Table.ViewText />,
        },

        {
            title: '项目名称',
            dataIndex: 'prjName',
            component: <Table.ViewText />,
        },

        ...columns,
    ]

    return (
        <TableChooseProduct
            columns={enhancedColumns}
            rowKey="rowId"
            {...props}
        />
    )
}
