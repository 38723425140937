import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    amCode: {
        name: () => '分析域',
    },

    arNo: {
        name: () => '分析报表编号',
    },

    arName: {
        name: () => '分析报表名称',
        required: () => true,
    },

    memo: {
        name: () => '备注',
    },

    resume: {
        name: () => '简述',
    },
})