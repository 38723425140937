import {useState} from 'react'
import {css} from '@emotion/react'
import HiddenNodes from './HiddenNodes.jsx'
import ToolBar from './ToolBar.jsx'

const cssPane = css({
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    height: '100%',
    overflow: 'hidden',
})

const cssToolBar = css({
    borderBottom: '1px solid #e8e8e8',
})

export default function HiddenPane({items, ...props}) {
    const [selectedKeys, setSelectedKeys] = useState(new Set)

    return (
        <div
            css={cssPane}
            {...props}
        >
            <ToolBar
                css={cssToolBar}
                items={items}
                selectedKeys={selectedKeys}
            />

            <HiddenNodes
                items={items}
                selectedKeys={selectedKeys}
                onSelect={setSelectedKeys}
            />
        </div>
    )
}
