import {css} from '@emotion/react'
import {Button} from 'antd'
import http from '@/biz/http.mjs'
import runAsync from '@/script/runAsync.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'

const cssToolBar = css({
    display: 'flex',
    justifyContent: 'center',
    gap: 8,
    padding: 8,
})

export default function ToolBar({items, selectedKeys, ...props}) {
    const map = useMapContext()

    const upgrade = async items => {
        const pkid2nodeId = new Map(
            items.map(
                ({key, referer: {pkid}}) => [pkid, key]
            )
        )

        const result = await runAsync(
            () => http.post(
                '/moduleReference/batchUpgradeCallModule',

                items.map(
                    ({
                        referer: {
                            bizNodeType: moduleBizNodeType,
                            pkid: moduleId,
                        },

                        referred: {
                            bizNodeType: calleeBizNodeType,
                            pkid: calleeModuleId,
                            rev: calleeRev,
                        },
                    }) => ({
                        calleeBizNodeType,
                        calleeModuleId,
                        calleeRev,
                        moduleId,
                        moduleBizNodeType,
                    })
                )
            ),

            {action: '升级制品'}
        )

        map.execute(() => {
            for (const item of items) {
                const {referred: {ver}, verNodeId} = item
                const n = map.getNode(verNodeId)
                n.data = {...n.data, arName: ver, style: {}}
            }

            for (const {moduleId, moduleRev} of result) {
                const nodeId = pkid2nodeId.get(moduleId)
                const n = map.getNode(nodeId)
                const {rev} = n.data

                if (rev < moduleRev) {
                    n.data = {...n.data, rev: moduleRev}

                    for (const nn of n.children) {
                        const style = {
                            textColor: 'red',
                            fontWeight: 900,
                        }

                        nn.data = {...nn.data, style}
                    }
                }
            }
        })
    }

    const handleClickUpgrade = () => {
        const itemMap = new Map(
            items.map(e => [e.key, e])
        )

        const selectedItems = [...selectedKeys].map(key => itemMap.get(key))
        upgrade(selectedItems)
    }

    const handleClickUpgradeAll = () => {
        const upgradableItems = items.filter(
            e => e.referer.stsCode === 'RLS'
        )

        upgrade(upgradableItems)
    }

    return (
        <div
            css={cssToolBar}
            {...props}
        >
            <Button
                disabled={0 === selectedKeys.size}
                size="small"
                onClick={handleClickUpgrade}
            >升级</Button>

            <Button
                disabled={0 === items.length}
                size="small"
                onClick={handleClickUpgradeAll}
            >全部升级</Button>
        </div>
    )
}
