import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import meta from './metaAmai.mjs'
import Model from './ModelAmai.mjs'
import PropertiesPane from './PropertiesPaneAmai.jsx'
import FormChoose from './FormChooseAmai.jsx'
import TableChoose from './TableChooseAmai.jsx'
import api from './apiAmai.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        attrNodes: {
            top: ['ATTR_AMAI_RESUME'],
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.LIGHT_ORANGE,
                }),

                shape: 'EllipseRectangle',
            }
        },

        _getCategories(map, node) {
            return [
                'AMAI_AO',
                'AMAI_IALG',
                'AMAI_AC',
            ]
        },

        _pushDataSlots: {
            amAiAcs: [],
        },
    })
}
