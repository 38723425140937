import ButtonExportModel from '../components/PropertiesPaneButtonExportModel.jsx'
import ButtonImportModules from '../components/PropertiesPaneButtonImportModules.jsx'
import ArchPropertiesPane from '../_ARCH/ArchPropertiesPane.jsx'

export default function PropertiesPaneBa(props) {
    return (
        <ArchPropertiesPane
            buttons={
                <>
                    <ButtonExportModel
                        global
                        type="bm"
                    />

                    <ButtonImportModules
                        global
                        type="bm"
                    />
                </>
            }

            {...props}
        />
    )
}
