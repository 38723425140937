import http from '@/biz/http.mjs'

export default {
    publish: ({pkid, ...data}) => http.post(
        '/archRa/rlsArchRa',
        {archRas:[{pkid}], ...data}
    ),
    pull: ({pkid}) => http.get(`/archRa/loadDp`, {pkid}),
    push: (data) => http.post('/archRa/modifyDp', data),
    read: ({pkid}) => http.get(`/archRa/oneArchRa`, {pkid}),
    revise: ({pkid, ...data}) => http.post(
        '/archRa/reviseArchRa',
        {archRas: [{pkid}], ...data}
    ),
    update: (data) => http.post(`/archRa/saveMap`, data),
}
