import { ISchema } from '@formily/react'
import { ClickEventSetter, IconSelect } from '../setters'
import {ClickCustomEventSetter} from '../../../FormilyMobile/src/setters'

export const Dialog: ISchema & { Content?: ISchema, Footer?: ISchema } = {
  type: 'object',
  properties: {
    text: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Input',
    },
    title: {
        type: 'string',
        'x-decorator': 'FormItem',
        'x-component': 'Input',
    },
    width: {
        type: 'number',
        'x-decorator': 'FormItem',
        'x-component': 'NumberPicker',
    },
    type: {
      type: 'string',
      enum: ['primary', 'default', 'dashed', 'text', 'link'],
      'x-decorator': 'FormItem',
      'x-component': 'Select',
    },
    iconName: {
      title: '图标',
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': IconSelect,
    },
    'clickCustomEventProps': {
      title: '能力',
      type: 'Object',
      'x-decorator': 'FormItem',
      'x-component': ClickCustomEventSetter,
    },
    size: {
      type: 'string',
      enum: ['large', 'middle', 'small'],
      'x-decorator': 'FormItem',
      'x-component': 'Select',
    },
    shape: {
      title: '按钮形状',
      type: 'string',
      enum: [
        {
          label: '默认',
          value: 'default',
        },
        {
          label: '圆形',
          value: 'circle',
        },
        {
          label: '长方形',
          value: 'round',
        },
      ],
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        allowClear: true,
        defaultValue: 'default'
      },
    },
    hideBtn: {
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
    },
    block: {
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
    },
    loading: {
      title: '载入状态',
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
    },
    danger: {
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
    },
  },
}

Dialog.Content = null

Dialog.Footer = null