import http from '@/biz/http.mjs'

export default {
    create: data => http.post('/amao/addAmAo', data),

    publish: ({pkid, ...data}) => http.post(
        '/amao/rlsAmAo', {amAos: [{pkid}], ...data}
    ),

    pull: ({pkid}) => http.get('/amao/loadDp', {pkid}),
    push: data => http.post('/amao/modifyDp', data),
    read: args => http.get('/amao/oneAmAo', args),
    readList: args => http.get('/amao/listAmAo', args),

    revise: ({pkid, ...data}) => http.post(
        '/amao/reviseAmAo', {amAos: [{pkid}], ...data}
    ),

    update: data => http.post('/amao/modifyAmAo', data),
    updateMap: data => http.post('/amao/saveMap', data),
}
