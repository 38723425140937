import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    riName: {
        name: () => '报表指标名称',
        required: () => true,
    },

    riUserCode: {
        name: () => '报表指标代码',
    },

    resume: {
        name: () => '简述',
    },

    riTitle: {
        name: () => '标题名称',
    },
})
