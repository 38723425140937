import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import api from './apiAiac.mjs'
import IconAiac from './icon-aiac.svg?react'
import meta from './metaAiac.mjs'
import Model from './ModelAiac.mjs'
import PropertiesPane from './PropertiesPaneAiac.jsx'
import FormChoose from './FormChooseAiac.jsx'
import TableChoose from './TableChooseAiac.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        attrNodes: {
            top: ['ATTR_AIAC_RESUME'],
        },

        canMountType(map, node, type) {
            return /^(AR)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'AR'
        },

        getIcons(map, node) {
            return [
                <IconAiac key="type" />
            ]
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.LIGHT_ORANGE}),
                shape: 'EllipseRectangle',
            }
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['AR'],
            ]
        },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.amAiAcs.push(d)
        },
    })
}
