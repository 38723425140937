import {useUser} from "@/biz/user.mjs"
import useProjectMembers from "@/biz/useProjectMembers.mjs"

export default (prjId, role) => {
    const user = useUser()
    const {data: members} = useProjectMembers(prjId)

    if (! (user && members)) {
        return false
    }

    const m = members.find(
        ({userId}) => userId === user.pkid
    )

    if (! m) {
        return false
    }

    if (! m.prjGrpTypeCode) {
        return false
    }

    if (! role) {
        return true
    }

    const re = new RegExp(`\b${role}\b`)
    return re.test(m.prjGrpTypeCode)
}
