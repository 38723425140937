import superMeta from '../_MODULE/metaModuleNode.mjs'

export default {
    ...superMeta,
    codeProp: 'omUserCode',
    mapIdProp: 'orgMapId',
    mapStsCodeProp: 'orgMapStsCode',
    mapTypeCode: 'ORG_MAP',
    name: '组织域',
    ownerIdProp: 'oaId',
    ownerTextProp: 'oaName',
    ownerType: 'OA',
    textProp: 'omName',
    type: 'OM',
}
