import runAsync from '@/script/runAsync.mjs'
import apiShare from '../../apis/apiShare.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import useSwrNoCache from '@/script/useSwrNoCache.mjs'

export default (id) => {
    const {data} = useSwrNoCache(
        () => runAsync(
            () => apiShare.read({id}),
            {action: '读取地图'}
        ),

        {dependencies: [id]}
    )

    if (data) {
        return parseMap(data)
    }
    else {
        return void 0
    }
}
