import extendNode from '../extendNode.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import meta from './metaAlgNode.mjs'
import Model from './ModelAlg.mjs'
import PropertiesPane from './PropertiesPaneAlg.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return (
                'AR' === type ||
                ComponentNode.canMountType.call(this, map, node, type)
            )
        },

        castFrom(map, node) {
            ComponentNode.castFrom.call(this, map, node)
            const {algType} = this
            node.data = {...node.data, algType}
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['AR'],
            ]
        },

        _fixChild(map, node, child) {
            return map.BizNode.AR._fixChild(map, node, child)
        },

        _getDefaultDataFields(map, node) {
            return {
                ...ComponentNode._getDefaultDataFields.call(this, map, node),
                algType: this.algType,
            }
        },

        _getStyle(map, node, styleDone) {
            return {
                ...ComponentNode._getStyle.call(this, map, node, styleDone),
                shape: 'EllipseRectangle',
            }
        },
    })
}
