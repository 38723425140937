import superMeta from '../_PRODUCT/metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'aiCode',
    detailUrl: '/AmAiDetail',
    detailIdProp: 'aiId',
    mapProp: 'aiMap',
    name: '分析指标',
    textProp: 'aiName',
    type: 'AMAI',
}
