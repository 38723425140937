import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import IconIe from './icon-ie.svg?react'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import api from './apiIe.mjs'
import meta from './metaIe.mjs'
import Model from './ModelIe.mjs'
import PropertiesPane from './PropertiesPaneIe.jsx'
import FormChoose from './FormChooseIe.jsx'
import TableChoose from './TableChooseIe.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        attrNodes: {
            top: [
                'ATTR_IE_RESUME',
                'ATTR_IE_DATA_TYPE',
                'ATTR_IE_DATA_LEN',
                'ATTR_IE_DATA_UNIT',
            ],
        },

        getIcons(map, node) {
            return [
                <IconIe
                    key="type"
                    fill={Color.CYAN}
                />
            ]
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.CYAN}),
                shape: 'EllipseRectangle',
            }
        },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.ieList.push(d)
        },
    })
}
