import Table from '@/components/Table/Table.jsx'
import TableChooseComponent from '../_COMPONENT/TableChooseExternalComponent.jsx'

export default function TableChooseDe(props) {
    const columns = [
        {
            title: '维度对象名称',
            dataIndex: 'doName',
            component: <Table.ViewText />,
        },

        {
            title: '维度对象代码',
            dataIndex: 'doNo',
            component: <Table.ViewText />,
        },

        {
            title: '维度实体标题',
            dataIndex: 'deTitle',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseComponent
            columns={columns}
            {...props}
        />
    )
}
