import React, { useState } from 'react'
import FormilyDialog, {  FormilyDialogProps } from './Dialog'
import { createBehavior, createResource } from '@designable/core'
import { DnFC, DroppableWidget } from '@designable/react'
import { observer, ExpressionScope, useForm, useField } from '@formily/react'
import { Modal } from 'antd';
import { createBaseSchema } from '../Field'
import { AllSchemas } from '../../schemas'
import { AllLocales } from '../../locales'
import cls from 'classnames'
import './styles.less'
import { uid } from '@formily/shared'

export const Dialog: DnFC<FormilyDialogProps> & { Content: any, Footer: any } = ({ mapParmsToProps, children, text, title, width, style, hideBtn = false,  ...props}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleClick = () => {
    setIsModalOpen(true);
  }

  return (
    <div
      id={props["data-designer-node-id"]}
      className="dn-dialog-container"
      data-designer-node-id={props["data-designer-node-id"]}
      style={{ display: props.block || style?.display == 'block' ? 'block' : ''}}
    >
      <FormilyDialog
        {...props}
        text={text}
        onClick={handleClick}
        className={cls(props.className, "dn-dialog")}
        style={{ ...style, opacity: hideBtn && '0.1' }}
      />
      <Modal
        title={title}
        style={{ top: 200 }}
        width={width}
        getContainer={() =>
          document.getElementById(props["data-designer-node-id"])
        }
        bodyStyle={{ padding: 0}}
        footer={children && children.props && children.props.children[0][1] ? [ children.props.children[0][1]] : null}
        maskClosable={false}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        {children && children?.props && children.props.children[0][0] ? children.props.children[0][0] : children?.props?.children[0] }
      </Modal>
    </div>
  );
}

Dialog.Content = observer(({ children, ...props }) => {
  return (
    
      <DroppableWidget {...props}>{children}</DroppableWidget>
  )
})

Dialog.Footer = observer(({ children, ...props }) => {
  return (
    
      <DroppableWidget {...props}>{children}</DroppableWidget>
  )
})


Dialog.Behavior = createBehavior(
  {
    name: 'Dialog',
    extends: ['Field'],
    selector: (node) => node.props['x-component'] === 'Dialog',
    designerProps: {
      propsSchema: createBaseSchema(AllSchemas.Dialog),
      cloneable: true
    },
    designerLocales: AllLocales.Dialog,
    
  },
  {
    name: 'Dialog.Content',
    extends: ['Field'],
    selector: (node) => node.props['x-component'] === 'Dialog.Content',
    designerProps: {
      droppable: true,
      allowDrop: (node) => node.props['x-component'] === 'Dialog',
      propsSchema: createBaseSchema(AllSchemas.Dialog.Content),
      deletable: false,
      cloneable: false,
    },
    designerLocales: AllLocales.DialogContent,
  },
  {
    name: 'Dialog.Footer',
    extends: ['Field'],
    selector: (node) => node.props['x-component'] === 'Dialog.Footer',
    designerProps: {
      droppable: true,
      allowDrop: (node) => node.props['x-component'] === 'Dialog',
      propsSchema: createBaseSchema(AllSchemas.Dialog.Footer),
      cloneable: false
    },
    designerLocales: AllLocales.DialogFooter,
  }
)

Dialog.Resource = createResource(
  {
    icon: 'ButtonSource',
    elements: [
      {
        componentName: 'Field',
        props: {
          type: 'void',
          // 'x-decorator': 'FormItem',
          'x-component': 'Dialog',
          'x-component-props': {
            text: '打开弹框',
            title: '弹框标题',
            width: 520,
            size: 'middle',
            type: 'primary',
          }
        },
        children: [
          {
            componentName: 'Field',
            props: {
              type: 'void',
              'x-component': 'Dialog.Content',
              'x-component-props': {
                  style: {
                    padding: '24px 24px 24px 24px'
                  }
              }
            },
          },
          {
            componentName: 'Field',
            props: {
              type: 'void',
              'x-component': 'Dialog.Footer',
              'x-component-props': {
                  style: {
                    TextAlign: 'right'
                  }
              }
            },
            children: [
              {
                componentName: 'Field',
                props: {
                  type: 'void',
                  'x-component': 'Button',
                  'x-component-props': {
                      text: '取消',
                      size: 'middle',
                      type: 'default',
                      hideModal: true
                  }
                },
              },
              {
                componentName: 'Field',
                props: {
                  type: 'void',
                  'x-component': 'Button',
                  'x-component-props': {
                      text: '确定',
                      size: 'middle',
                      type: 'primary',
                      hideModal: true,
                      style: {
                        margin: '0 0 0 10px'
                      }
                  }
                },
              }
            ]
          },
        ]
      },
    ],
  },
)


export const createDialog = observer((dialogProps : any) => {
  const {text, title, children, width,clickCustomEventProps, style, hideBtn = false, ...props} = dialogProps
  const [isModalOpen, setIsModalOpen] = useState(false);
  const form = useForm();
  const field = useField()
  // console.log(children.props.children[0].props.children)
  const allChildren = children.props?.children[0]?.props?.children || []

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleClick = (event) => {
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault();
      // Ctrl键被按下，执行你想要的操作
      form.notify('ctrl-click', clickCustomEventProps)
    } else {
        setIsModalOpen(true);
    }
  }
  return (
    
      <div
        className="dn-dialog-container"
        style={{
          display:
            props.block || style?.display == "block" ? "block" : "",
        }}
      >
        <FormilyDialog
          {...props}
          id={field.props.name}
          text={text}
          onClick={handleClick}
          className={cls(props.className, "dn-dialog")}
          style={{ ...style, display: hideBtn && 'none' }}
        />
        <Modal
          title={title}
          width={width}
          style={{ top: 200 }}
          getContainer={() =>
            document.getElementById(props["data-designer-node-id"])
          }
          bodyStyle={{ padding: 0 }}
          footer={allChildren[1] ? <ExpressionScope value={{ $closeScope: handleCancel, $value: '111' }}>{allChildren[1]}</ExpressionScope> : null}
          maskClosable={false}
          open={isModalOpen}
          onCancel={handleCancel}
        >
          {allChildren[0]}
        </Modal>
      </div>
  );
})

const DialogContent = observer(({ children, ...props }) => {
  return (
    <div {...props} >{children}</div>
  )
})

const DialogFooter = observer(({ children, ...props }) => {
  return (
    <div {...props} >{children}</div>
  )
})

export const DialogPreview = Object.assign(createDialog, {
  Content: DialogContent,
  Footer: DialogFooter
})