import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Ls from '../LS/PropertiesControllerLs.jsx'
import Model from './ModelLsi.mjs'

const PropertiesPaneLsi = (props) => (
    <ProductPropertiesPane
        controllers={
            <>
                <Ls />
                <Text prop="lsiNo" />
                <Text prop="lsiName" />
                <LongText prop="memo" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'ChgReference',
                    'Depend',
                    'Effect',
                    'Reference',
                    'ReferenceCall',
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneLsi
