import bizNodeModel from '../../bizNodeModel.mjs'
import ModelAlg from '../_ALG/ModelAlg.mjs'

export default bizNodeModel({
    ...ModelAlg.definition,

    storyType: {
        name: () => '用户场景类型',
    },

    algTitle: {
        name: () => '用户场景标题',
    },
})
