import Table from '@/components/Table/Table.jsx'
import TableChooseComponent from '../_COMPONENT/TableChooseComponent.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

const TableChooseBdalg = (props) => {
    const map = useMapContext()

    const columns = [
        'BCT' === map.data.prjTypeCode && {
            title: '界面方案',
            dataIndex: 'uiCase',
            component: <Table.ViewText />,
        },

        {
            title: '业务对象/服务名称',
            dataIndex: 'bdName',
            component: <Table.ViewText />,
        },

        {
            title: '业务对象/服务代码',
            dataIndex: 'bdNo',
            component: <Table.ViewText />,
        },

        {
            title: '方法标题',
            dataIndex: 'algTitle',
            component: <Table.ViewText />,
        },

        {
            title: '方法类型',
            dataIndex: 'algType',
            component: <Table.ViewDict dict={{BASE: '基础', DEVELOP: '事件'}} />,
        },
    ].filter(a => a)

    return (
        <TableChooseComponent
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseBdalg
