import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaLsiSf.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canLinkType(map, node, type) {
            return /^(BDLOGIC|SF)$/.test(type)
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['BDLOGIC_1'],
            ]
        },

        async _atPush(map, node, event) {
            event.stopPropagation()

            Object.assign(
                event.detail,

                {
                    openPrjId: '',
                    openSVer: '',
                    patternRev: '',
                    prjPatternId: '',
                    sfCode: '',
                    sfRev: '',
                }
            )

            const next = chain => {
                const _n = map.nodeProxy(chain[0])
                return {yieldChildren: _n.isMounted()}
            }

            for (const n of map.bfs(node.children, next)) {
                const {
                    bizNodeType,
                    patternRev,
                    prjId: openPrjId,
                    prjPatternId,
                    rev: sfRev,
                    sVer: openSVer,
                } = n.data

                const sfCode = (() => {
                    if ('BDLOGIC' === bizNodeType) {
                        return n.data.ruleCode
                    }
                    else if ('SF' === bizNodeType) {
                        return n.data.sfCode
                    }
                })()

                if (sfCode) {
                    Object.assign(event.detail, {
                        openPrjId,
                        openSVer,
                        patternRev,
                        prjPatternId,
                        sfCode,
                        sfRev,
                    })

                    break
                }
            }
        },
    })
}
