import superMeta from '../_MODULE/metaModuleNode.mjs'

export default {
    ...superMeta,
    codeProp: 'rmUserCode',
    name: '需求域',
    ownerIdProp: 'raId',
    ownerTextProp: 'raName',
    ownerType: 'RA',
    textProp: 'rmName',
    type: 'RM',
}
