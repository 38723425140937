import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import meta from './metaDo.mjs'
import Model from './ModelDo.mjs'
import PropertiesPane from './PropertiesPaneDo.jsx'
import FormChoose from './FormChooseDo.jsx'
import TableChoose from './TableChooseDo.jsx'
import api from './apiDo.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        attrNodes: {
            top: ['ATTR_DO_RESUME'],
        },

        async choose(map, node) {
            const getQuery = ({ams = [], ...query}) => ({
                ...query,
                amIds: ams.map(({amId}) => amId),
            })

            return this._choose(map, node, {getQuery})
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {backgroundColor: Color.CYAN}),
                shape: 'BreakangleRectangle',
            }
        },

        _getCategories(map, node) {
            return [
                'DO_DE',
            ]
        },

        _pushDataSlots: {
            deList: [],
        },
    })
}
