import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaSfProg.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canLinkType(map, node, type) {
            return /^(FC)$/.test(type)
        },

        isHidden(map, node) {
            return (
                /^(ALG|SF)_LVL$/.test(map.data.desgAcc) ||
                CategoryNode.isHidden.call(this, map, node)
            )
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['FC'],
            ]
        },

        async _atPush(map, node, event) {
            event.stopPropagation()

            const next = chain => {
                const _n = map.nodeProxy(chain[0])
                return {yieldChildren: _n.isMounted()}
            }

            for (const n of map.bfs(node.children, next)) {
                const {bizNodeType, fcCode} = n.data

                if ('FC' === bizNodeType) {
                    event.detail.progList.push({fcCode})
                }
            }
        },
    })
}
