import MenuButton from '../../MenuButton.jsx'
import useMenuItemGrowNode from '../../../menuItems/useMenuItemGrowNode.jsx'
import useMenuItemGrowNodeRecursive from '../../../menuItems/useMenuItemGrowNodeRecursive.jsx'
import useMenuItemImportSnapshot from '../../../menuItems/useMenuItemImportSnapshot.jsx'
import useMenuItemListSnapshot from '../../../menuItems/useMenuItemListSnapshot.jsx'
import useMenuItemLogs from '../../../menuItems/useMenuItemLogs.jsx'
import useMenuItemMakeAllProducts from '../../../menuItems/useMenuItemMakeAllProducts.jsx'
import useMenuItemMakeProduct from '../../../menuItems/useMenuItemMakeProduct.jsx'
import useMenuItemPublishMap from '../../../menuItems/useMenuItemPublishMap.jsx'
import useMenuItemReviseMap from '../../../menuItems/useMenuItemReviseMap.jsx'
import useMenuItemSaveMap from '../../../menuItems/useMenuItemSaveMap.jsx'
import useMenuItemShareMap from '../../../menuItems/shareMap/useMenuItemShareMap.jsx'
import useMenuItemNodeDesc from '../../../menuItems/useMenuItemNodeDesc.jsx'
import useMenuItemShowHiddenNodes from '../../../menuItems/useMenuItemShowHiddenNodes.jsx'
import useMenuItemShrinkNode from '../../../menuItems/useMenuItemShrinkNode.jsx'

export default function MenuButtonMap(props) {
    const items = [
        useMenuItemSaveMap(),
        useMenuItemPublishMap(),
        useMenuItemReviseMap(),
        {divider: true},
        useMenuItemGrowNode(),
        useMenuItemGrowNodeRecursive(),
        useMenuItemShrinkNode(),
        {divider: true},
        useMenuItemMakeProduct(),
        useMenuItemMakeAllProducts(),
        {divider: true},
        useMenuItemListSnapshot(),
        useMenuItemImportSnapshot(),
        useMenuItemLogs(),
        {divider: true},
        useMenuItemShareMap(),
        {divider: true},
        useMenuItemNodeDesc(),
        useMenuItemShowHiddenNodes(),
    ].filter(a => a)

    return (
        <MenuButton
            items={items}
            {...props}
        >
            地图
        </MenuButton>
    )
}
