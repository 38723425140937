import extendNode from '../extendNode.mjs'
import useAbstractNode from '../_ABSTRACT/useAbstractNode.jsx'
import meta from './metaVr_.mjs'
import FormChoose from '../VR/FormChooseVr.jsx'
import TableChoose from '../VR/TableChooseVr.jsx'
import api from './apiVr_.mjs'

export default () => {
    const AbstractNode = useAbstractNode()

    return extendNode(AbstractNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
    })
}
