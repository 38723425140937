const VIEW_SIZE = 128

const IconLetters = ({
    fill = 'transparent',
    borderColor = fill,
    borderWidth = 10,
    letters = '',
    textColor = '#000',
    textDecoration,
}) => {
    const fontSize = [112, 96, 80][letters.length - 1]
    const lengthAdjust = 1 < letters.length ? 'spacingAndGlyphs' : 'spacing'

    const decoration = {
        'line-through': (
            <>
                <line
                    x1="10%"
                    y1="40%"
                    x2="90%"
                    y2="40%"
                    strokeWidth="10"
                    stroke="#000"
                />

                <line
                    x1="10%"
                    y1="60%"
                    x2="90%"
                    y2="60%"
                    strokeWidth="10"
                    stroke="#000"
                />
            </>
        ),
    }[textDecoration] ?? null

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
        >
            <circle
                cx="50%"
                cy="50%"
                fill={fill}
                stroke={borderColor}
                strokeWidth={borderWidth}
                r={VIEW_SIZE / 2 - borderWidth}
            />

            <text
                dominantBaseline="middle"
                fill={textColor}
                fontFamily="sans-serif"
                fontSize={fontSize}
                stroke={textColor}
                strokeWidth="5"
                textAnchor="middle"
                x="50%"
                y="50%"
                textLength="96"
                lengthAdjust={lengthAdjust}
            >{letters}</text>

            {decoration}
        </svg>
    )
}

export default IconLetters
