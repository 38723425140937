import {useEffect, useState} from 'react'
import {useDraggableListContext} from './DraggableListContext.mjs'

export default refEl => {
    const list = useDraggableListContext()
    const [isFocused, setIsFocused] = useState(false)

    const translate = ({offsetY}) => {
        refEl.current.style.transform = `translateY(${offsetY}px)`
    }

    const li = {
        blur: () => {
            setIsFocused(false)
        },

        focus: () => {
            setIsFocused(true)
        },

        isFocused,

        move(item) {
            if (item !== this) {
                const {height, top, offsetY} = item

                if (0 < offsetY) {
                    if (
                        top < this.top &&
                        this.top + this.height / 2 < height + top + offsetY
                    ) {
                        this.offsetY = - height
                    }
                    else {
                        this.offsetY = 0
                    }
                }
                else {
                    if (
                        this.top < top &&
                        top + offsetY < this.top + this.height / 2
                    ) {
                        this.offsetY = height
                    }
                    else {
                        this.offsetY = 0
                    }
                }
            }

            translate(this)
        },

        moveEnd() {
            Object.assign(this, {offsetX: 0, offsetY: 0})
            translate(this)
        },

        moveStart() {
            const {
                width,
                height,
                top,
                left,
            } = refEl.current.getBoundingClientRect()

            const offsetX = 0
            const offsetY = 0
            Object.assign(this, {width, height, top, left, offsetX, offsetY})
        },
    }

    useEffect(
        () => {
            list.registerItem(li)

            return () => {
                list.deregisteritem(li)
            }
        },

        [li]
    )

    return li
}
