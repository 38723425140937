import {Button} from 'antd'
import {useOpenModal} from '@/components/Modal/Modal.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import ModalVersions from '../components/ModalVersions.jsx'
import TableChooseDesign from '../_DESIGN/TableChooseDesign.jsx'

const ButtonMoreVersion = ({item, onSelect, ...props}) => {
    const openModal = useOpenModal()
    const map = useMapContext()

    const handleClick = async () => {
        const {bizNodeType, pkid, prjId} = item
        const dataType = bizNodeType.replace(/_.*$/, '')

        const version = await openModal(
            <ModalVersions
                prjId={prjId}
                dataId={pkid}
                dataType={dataType}
            />
        )

        if (! version) {
            return
        }

        const {dataVer: rev} = version

        const tree = await map.BizNode[dataType].readTree(
            map, {data: item}, rev
        )

        onSelect(tree.data)
    }

    return (
        <Button
            size="small"
            type="link"
            onClick={handleClick}
            {...props}
        >
            更多版本
        </Button>
    )
}

export default function TableChooseComponent(props) {
    return (
        <TableChooseDesign
            operates={[<ButtonMoreVersion key="more" />]}
            {...props}
        />
    )
}
