import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

import {
    PropertiesController,
    usePropertiesController,
} from '../../SidePanel/RightPanel/PropertiesController.jsx'

import SelectAm from './SelectAm.jsx'

export default function PropertiesControllerAm({
    className,
    style,
    codeProp = 'amCode',
    disabled,
    hidden,
    idProp = 'amId',
    label,
    labelSuffix,
    nameProp = 'amName',
    readOnly,
    required,
    ...props
}) {
    const [values, update] = useProperties()

    const {
        [codeProp]: amCode,
        [idProp]: amId,
        [nameProp]: amName,
    } = values

    const handleChange = am => {
        const {
            amCode = '',
            amId = '',
            amName = '',
        } = am ?? {}

        update({
            [codeProp]: amCode,
            [idProp]: amId,
            [nameProp]: amName,
        })
    }

    const p = usePropertiesController({
        disabled,
        hidden,
        label,
        prop: codeProp,
        readOnly,
        required,
    })

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            label={p.label}
            labelSuffix={labelSuffix}
            required={p.required}
        >
            <SelectAm
                disabled={p.disabled}
                readOnly={p.readOnly}
                value={{amCode, amId, amName}}
                onChange={handleChange}
                {...props}
            />
        </PropertiesController>
    )
}
