import {EyeInvisibleOutlined} from '@ant-design/icons'
import IconWithNotification from '../IconWithNotification.jsx'
import HiddenPane from './HiddenPane.jsx'
import useHiddenNodes from './useHiddenNodes.mjs'

export default () => {
    const items = useHiddenNodes()

    return {
        icon: (
            <IconWithNotification notification={0 < items.length}>
                <EyeInvisibleOutlined />
            </IconWithNotification>
        ),

        key: 'hide',
        hotkey: 'Ctrl+Shift+H',
        name: '隐藏节点',
        pane: <HiddenPane items={items} />,
    }
}
