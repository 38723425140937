import {css} from '@emotion/react'
import useDraggableListTrigger from './useDraggableListTrigger.mjs'

const cssDraggableListTrigger = css({
    cursor: 'pointer',
})

export default function DraggableListTrigger(props) {
    const pointerEventHandlers = useDraggableListTrigger()

    return (
        <div
            css={cssDraggableListTrigger}
            {...pointerEventHandlers}
            {...props}
        >
        </div>
    )
}
