export function canUnhideNode(nodes) {
    return [...nodes].filter(
        node => this.actions.canUnhideNode(node)
    )
}

export async function unhideNode(nodes) {
    await this.cmd(
        () => this.actions.unhideNode(nodes)
    )
}
