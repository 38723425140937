import Table from '@/components/Table/Table.jsx'
import TableChooseProduct from '../_PRODUCT/TableChooseProduct.jsx'

const TableChooseCdg = (props) => {
    const columns = [
        {
            title: '库表域',
            dataIndex: 'dmNamePath',
            component: <Table.ViewText />,
        },

        {
            title: '字段组代码',
            dataIndex: 'pcdgUserCode',
            component: <Table.ViewText />,
        },

        {
            title: '字段组名称',
            dataIndex: 'pcdgName',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseProduct
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseCdg
