import {useUser} from "@/biz/user.mjs"
import useIsProjectMember from "@/biz/useIsProjectMember.mjs"

export default (permission, prjId) => {
    const user = useUser()
    const isMember = useIsProjectMember(prjId)

    if (permission) {
        return {}
    }

    function resetPermission() {
        if ('1' === user.isPlatformAdm) {
            this.permission.add('revise')
            this.permission.add('write')
        }
        else {
            if (isMember) {
                this.permission.add('revise')
            }

            const {
                crtId,
                mapStsCode,
                uptId,
            } = this.data

            if (
                (
                    'REVISE' === mapStsCode &&
                    uptId === user.pkid
                ) ||
                (
                    'DRAFT' === mapStsCode &&
                    crtId === user.pkid
                )
            ) {
                this.permission.add('write')
            }
            else {
                this.permission.delete('write')
            }
        }
    }

    const extensions = doc => {
        resetPermission.call(doc)
    }

    const watchers = {
        load: resetPermission,
        meta_change: resetPermission,
    }

    return {extensions, watchers}
}
