/* eslint-disable react/jsx-key */

import IconLightning from '../icons/icon-lightning.svg?react'
import Color from '../Color.mjs'
import IconInf from '../SF/icon-inf.svg?react'
import IconTime from '../SF/icon-time.svg?react'

export default [
    [
        'BT',
        '交互业务事务能力',
        <IconLightning style={{fill: Color.GOLD}} />
    ],

    [
        'INF',
        '接口业务事务能力',
        <IconInf />
    ],

    [
        'TIME',
        '定时业务事务能力',
        <IconTime />
    ],

    [
        'BL',
        '业务基础能力',
        <IconLightning style={{fill: Color.GREEN}} />
    ],
]
