import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Check from '../../SidePanel/RightPanel/PropertiesControllerCheck.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Fm from '../FM/PropertiesControllerFm.jsx'
import Ss from '../SS/PropertiesControllerSs.jsx'
import Vm from '../VM/PropertiesControllerVm.jsx'
import ButtonCode from './PropertiesPaneButtonCode.jsx'
import Model from './ModelFc.mjs'

const PropertiesPaneFc = (props) => (
    <ProductPropertiesPane
        buttons={<ButtonCode />}

        controllers={
            <>
                <Fm />
                <Ss />
                <Vm />
                <Text prop="fcUserCode" />
                <Text prop="fcName" />
                <Check prop="isApi" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'ChgReference',
                    'Reference',
                    'ReferenceCall',
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneFc
