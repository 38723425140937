import http from '@/biz/http.mjs'

export default {
    publish: ({pkid, ...data}) => http.post(
        '/amAi/rlsAmAi',
        {amAis: [{pkid}], ...data}
    ),

    pull: ({pkid}) => http.get(`/amAi/loadDp`, {pkid}),
    push: (data) => http.post('/amAi/modifyDp', data),
    read: ({pkid}) => http.get(`/amAi/oneAmAi`, {pkid}),
    readList: (args) => http.get('/amAi/listAmAi', args),

    revise: ({pkid, ...data}) => http.post(
        '/amAi/reviseAmAi',
        {amAis: [{pkid}], ...data}
    ),

    update: (data) => http.post(`/amAi/saveMap`, data),
}
