import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaSsins.mjs'
import Model from './ModelSsins.mjs'
import PropertiesPane from './PropertiesPaneSsins.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    borderColor={Color.DARK_GREEN}
                    letters="S"
                    textColor="#000"
                />
            ]
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {borderColor: Color.DARK_GREEN})
        },

        getTextPrefix(map, node) {
            const defaultPrefix = ComponentNode.getTextPrefix.call(
                this, map, node
            )

            const {ssInsEnv} = node.data

            if (ssInsEnv) {
                return `${defaultPrefix}【${ssInsEnv}】`
            }
            else {
                return defaultPrefix
            }
        },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.archSsInss.push(d)
        },

        _pushDataSlots: {
            archSsInsDeploys: [],
            archSsInsDependences: [],
        },

        _getCategories(map, node) {
            return [
                'SSINS_DEPLOYED_IN',
                'SSINS_DEPENDENCE_ON',
            ]
        },
    })
}
