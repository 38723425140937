import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import superMeta from '../_ATTRIBUTE/metaAttributeNode.mjs'
import useAttributeNode from '../_ATTRIBUTE/useAttributeNode.jsx'

export default () => {
    const AttributeNode = useAttributeNode()

    return extendNode(AttributeNode, {
        ...superMeta,
        prop: 'resume',
        type: 'ATTR_AD_RESUME',

        getStyle(map, node) {
            return {
                ...AttributeNode.getStyle.call(this, map, node),
                borderColor: Color.CYAN,
            }
        },
    })
}
