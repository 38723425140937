import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useModuleNode from '../_MODULE/useModuleNode.jsx'
// import api from './apiRm.mjs'
import meta from './metaRm.mjs'
import Model from './ModelRm.mjs'
import PropertiesPane from './PropertiesPaneRm.jsx'

export default () => {
    const ModuleNode = useModuleNode()

    return extendNode(ModuleNode, {
        ...meta,
        Model,
        PropertiesPane,

        getStyle(map, node) {
            return this._getStyle(map, node, {borderColor: Color.GOLD})
        },

        async _atOwnerPush(map, node, event) {
            const {bizNodeType} = event.target.data

            const key = {
                RA: 'archRms',
                RM: 'child'
            }[bizNodeType]

            const d = await this._getPushData(map, node)
            event.detail[key].push(d)
        },

        _fixChild(map, node, child) {
            const {bizNodeType} = child.data

            if (/^([BD]M)$/.test(bizNodeType)) {
                return this.castFrom(map, child)
            }
            else {
                return ModuleNode._fixChild.call(this, map, node, child)
            }
        },
    })
}
