import http from '@/biz/http.mjs'

export default {
    publish: data => http.post('/oa/rlsArchOa', data),
    pull: ({pkid}) => http.get('/oa/loadDp', {pkid}),
    push: data => http.post('/oa/modifyDp', data),
    read: args => http.get('/oa/oneArchOa', args),
    revise: ({pkid}) => http.post('/oa/reviseArchOa', {pkid}),
    updateMap: data => http.post('/oa/saveMap', data),
}
