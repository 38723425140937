import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    deTitle: {
        name: () => '维度实体标题',
        required: () => true,
    },

    deUserCode: {
        name: () => '维度实体代码',
    },

    resume: {
        name: () => '简述',
    },
})
