import extendNode from '../extendNode.mjs'
import http from '@/biz/http.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import Color from '../Color.mjs'
import useModuleNode from '../_MODULE/useModuleNode.jsx'
import api from './apiBm.mjs'
import meta from './metaBm.mjs'
import Model from './ModelBm.mjs'
import PropertiesPane from './PropertiesPaneBm.jsx'

export default () => {
    const ModuleNode = useModuleNode()

    return extendNode(ModuleNode, {
        ...meta,
        api,
        Model,
        PropertiesPane,

        canChangeVersion(map, node) {
            const canChange = ModuleNode.canChangeVersion.call(this, map, node)

            if (canChange) {
                for (const n of map.trueChain(node.parent)) {
                    const {bizNodeType} = n.data

                    if (bizNodeType !== this.type) {
                        return 'CAT_DATA' !== bizNodeType
                    }
                }
            }

            return canChange
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.LIGHT_YELLOW,
            })
        },

        async onDoubleClick(map, node, event) {
            const {mapTypeCode} = map.data

            if (
                'SA_MAP' !== mapTypeCode ||

                (() => {
                    for (const n of map.trueChain(node.parent)) {
                        const {bizNodeType} = n.data

                        if (bizNodeType !== this.type) {
                            return 'CAT_DATA' !== bizNodeType
                        }
                    }

                    return true
                })()
            ) {
                await ModuleNode.onDoubleClick.call(this, map, node, event)
            }
        },

        async _atOwnerPush(map, node, event) {
            const {bizNodeType} = event.target.data

            const key = {
                BA: 'archBmList',
                BM: 'child'
            }[bizNodeType]

            const d = await this._getPushData(map, node)
            event.detail[key].push(d)
        },

        // TODO
        //async _onChange(map, node, event) {
            //if (event.target === node) {
                //const oldData = event.detail
                //const {lastRev, pkid, rev, sVer} = node.data

                //if (
                    //rev !== oldData.rev &&
                    //'SA_MAP' === map.data.mapTypeCode
                //) {
                    //const next = chain => {
                        //const {bizNodeType} = chain[0].data
                        //const yieldNode = 'BM' === bizNodeType
                        //const yieldChildren = /^(BM|CAT_DATA)$/.test(bizNodeType)
                        //const yieldSibling = 'CAT_DATA' !== bizNodeType
                        //return {yieldChildren, yieldNode, yieldSibling}
                    //}

                    //for (
                        //const n of
                        //map.bfs(map.root.children, next)
                    //) {
                        //if (pkid === n.data.pkid) {
                            //n.data = {...n.data, lastRev, rev, sVer}
                            //break
                        //}
                    //}
                //}
            //}

            //await ModuleNode._onChange.call(this, map, node, event)
        //},

        _fixChild(map, node, child) {
            const {bizNodeType} = child.data

            if (/^[DF]M$/.test(bizNodeType)) {
                return this.castFrom(map, child)
            }
            else {
                return ModuleNode._fixChild.call(this, map, node, child)
            }
        },

        async _readGrowTree(map, node) {
            const {mapTypeCode} = map.data

            if (
                'IS_MAP' === mapTypeCode ||

                (
                    'SA_MAP' === mapTypeCode &&

                    (() => {
                        for (const n of map.trueChain(node.parent)) {
                            const {bizNodeType} = n.data

                            if (bizNodeType !== this.type) {
                                return 'CAT_DATA' === bizNodeType
                            }
                        }

                        return false
                    })()
                )
            ) {
                const {[this.mapIdProp]: mapId} = node.data
                const {mapModel} = await http.get(`/basmaps/${mapId}/IS`)
                const {root: {children}} = parseMap(mapModel)
                return {children}
            }
            else {
                return ModuleNode._readGrowTree.call(this, map, node)
            }
        },
    })
}
