import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import IconLetters from '../icons/IconLetters.jsx'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import meta from './metaBdterm.mjs'
import Model from './ModelBdterm.mjs'
import PropertiesPane from './PropertiesPaneBdterm.jsx'
import PropertiesPaneBatch from './PropertiesPaneBatchBdterm.jsx'
import FormChoose from './FormChooseBdterm.jsx'
import TableChoose from './TableChooseBdterm.jsx'
import api from './apiBdterm.mjs'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,
        PropertiesPaneBatch,

        canMountType(map, node, type) {
            return /^(AR|DOC|PIC|URL)$/.test(type)
        },

        async choose(map, node) {
            const getQuery = query => {
                const {bizNodeType, bdNo} = map.root.data

                if ('BD' === bizNodeType) {
                    return {bdNo, ...query}
                }
                else {
                    return query
                }
            }

            return this._choose(map, node, {getQuery})
        },

        defaultChildType(map, node) {
            return 'AR'
        },

        getIcons(map, node) {
            return [
                <IconLetters
                    key="type"
                    fill={Color.LIGHT_YELLOW}
                    letters="T"
                    textColor="#000"
                />
            ]
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.LIGHT_YELLOW}),
                shape: 'EllipseRectangle',
            }
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['AR'],
                ['PIC'],
                ['DOC'],
                ['URL'],
            ]
        },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.termList.push(d)
        },
    })
}
