import {useMapContext} from '@/components/MindMap/index.mjs'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import CategoryRootPropertiesPane from '../_CATEGORY_ROOT/CategoryRootPropertiesPane.jsx'
import ProjectDetail from '../CAT_ROOT_PRJ/PropertiesControllerProjectDetail.jsx'
// import Version from '../components/PropertiesControllerVersion/PropertiesControllerVersion.jsx'

export default function PropertiesPaneCatRootPrj({nodes, ...props}) {
    const map = useMapContext()

    const properties = 'SA_MAP' === map.data.mapTypeCode ?
        map.data : nodes[0].data

    return (
        <CategoryRootPropertiesPane
            controllers={
                <>
                    <Text
                        label="项目编号"
                        labelSuffix={<ProjectDetail />}
                        prop="prjNo"
                    />

                    <Text
                        label="项目名称"
                        prop="prjName"
                    />

                    {/* <Version label="模型总图版本" /> */}

                    <Text
                        label="项目类型"
                        prop="typeName"
                    />

                    <Text
                        label="更新人"
                        prop="uptName"
                    />

                    <Text
                        label="更新时间"
                        prop="uptTime"
                    />

                    <Text
                        label="创建人"
                        prop="crtName"
                    />

                    <Text
                        label="创建时间"
                        prop="crtTime"
                    />
                </>
            }

            properties={properties}
            {...props}
        />
    )
}
