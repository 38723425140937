import {SWRConfig} from 'swr'

const swrOptions = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
}

export default function SwrConfig(props) {
    return <SWRConfig value={swrOptions} {...props} />
}
