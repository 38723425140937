import http from '@/biz/http.mjs'

export default {
    create: data => http.post('/amdo/addAmDo', data),

    publish: ({pkid, ...data}) => http.post(
        '/amdo/rlsAmDo', {amDos: [{pkid}], ...data}
    ),

    pull: ({pkid}) => http.get('/amdo/loadDp', {pkid}),
    push: data => http.post('/amdo/modifyDp', data),
    read: args => http.get('/amdo/oneAmDo', args),
    readList: args => http.get('/amdo/listAmDo', args),

    revise: ({pkid, ...data}) => http.post(
        '/amdo/reviseAmDo', {amDos: [{pkid}], ...data}
    ),

    update: data => http.post('/amdo/modifyAmDo', data),
    updateMap: data => http.post('/amdo/saveMap', data),
}
