import extendNode from '../extendNode.mjs'
import useAbstractNode from '../_ABSTRACT/useAbstractNode.jsx'
import meta from './metaAo_1.mjs'
import FormChoose from './FormChooseAo_1.jsx'
import TableChoose from './TableChooseAo_1.jsx'
import api from './apiAo_1.mjs'

export default () => {
    const AbstractNode = useAbstractNode()

    return extendNode(AbstractNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
    })
}
