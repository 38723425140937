// TODO: 横向

import {DraggableListContextProvider} from './DraggableListContext.mjs'
import useDraggableList from './useDraggableList.mjs'

export default function DraggableList({children, onChange, ...props}) {
    const list = useDraggableList(onChange)

    return (
        <div {...props}>
            <DraggableListContextProvider value={list}>
                {children}
            </DraggableListContextProvider>
        </div>
    )
}
