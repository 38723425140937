import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Check from '../../SidePanel/RightPanel/PropertiesControllerCheck.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Bdt from './PropertiesControllerBdt.jsx'
import CdtLen from './PropertiesControllerCdtLen.jsx'
import CdtPrecision from './PropertiesControllerCdtPrecision.jsx'
import Model from './ModelCdt.mjs'

const PropertiesPaneCtd = (props) => (
    <ProductPropertiesPane
        controllers={
            <>
                <Text prop="cdtUserCode" />
                <Text prop="cdtName" />
                <Text prop="bdtCode" />
                <Text prop="cdtCat" />
                <Bdt />
                <CdtLen />
                <CdtPrecision />
                <Check prop="isSeq" />
                <LongText prop="cdtDesc" />
                <LongText prop="memo" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'ChgReference',
                    'Reference',
                    'ReferenceCall',
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneCtd
