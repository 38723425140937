import React, { useEffect, useMemo, useState } from "react";
import { clone } from "@formily/shared";
import { createForm } from "@formily/core";
import { createSchemaField, useExpressionScope } from "@formily/react";
import { GlobalRegistry } from "@designable/core";
import { requestIdle, uid } from "@designable/shared";
import { usePrefix } from "@designable/react";
import {
  Form,
  ArrayTable,
  Input,
  Select,
  FormItem,
  NumberPicker,
  Switch,
} from "@formily/antd";
import { Button, Spin } from "antd";
//@ts-ignore
import Modal from "@/components/Modal/Modal";
import { IReaction } from "@designable/formily-setters/lib/components/ReactionsSetter/types";
import { ClickEventSetter, IconSelect } from "../index";
import ActionColor from "./actionColor";
import "./styles.less";

interface IActionsSetterProps {
  value?: IReaction & { actions: any[] };
  onChange?: (value: IReaction) => void;
}

const SchemaField = createSchemaField({
  components: {
    Input,
    Select,
    FormItem,
    ArrayTable,
    NumberPicker,
    Switch,
  },
});

export const ActionsSetter: React.FC<IActionsSetterProps> = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [innerVisible, setInnerVisible] = useState(false);
  const $scope = useExpressionScope();
  const prefix = usePrefix("table-setter");

  const form = useMemo(() => {
    return createForm({
      values: { actions: clone(props.value) },
    });
  }, [modalVisible, props.value]);

  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);
  useEffect(() => {
    if (modalVisible) {
      requestIdle(
        () => {
          setInnerVisible(true);
        },
        {
          timeout: 4000,
        }
      );
    } else {
      setInnerVisible(false);
    }
  }, [modalVisible]);
  return (
    <>
      <Button block onClick={openModal}>
        配置操作列
      </Button>
      <Modal
        title="配置操作列"
        width={'80vw'}
        style={{ top: 20 }}
        bodyStyle={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          maxHeight: "80vh",
          minHeight: 320,
          background: "#fff",
          padding: "16px 24px"
        }}
        centered
        transitionName=""
        maskTransitionName=""
        open={modalVisible}
        onCancel={closeModal}
        destroyOnClose
        onOk={async () => {
          form.validate().then(() => {
            form.submit((values) => {
              props.onChange?.(values.actions);
            });
            closeModal();
          });
        }}
      >
        <div className={prefix}>
          {innerVisible ? (
            <Form form={form}>
              <SchemaField scope={{ ...$scope }}>
                <SchemaField.Array
                  name="actions"
                  default={[]}
                  x-component="ArrayTable"
                  x-component-props={{
                    pagination: { pageSize: 10 },
                    scroll: { x: "100%", y: 'calc(80vh - 235px)' },
                  }}
                >
                  <SchemaField.Object>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      name="column1"
                      x-component-props={{
                        width: 50,
                        title: "排序",
                        align: "center",
                      }}
                    >
                      <SchemaField.Void
                        x-decorator="FormItem"
                        required
                        x-component="ArrayTable.SortHandle"
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: "按钮名称",
                        width: 100,
                      }}
                    >
                      <SchemaField.String
                        name="actionTitle"
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                          placeholder: "请输入",
                          defaultValue: "按钮",
                        }}
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: "图标",
                        width: 160,
                      }}
                    >
                      <SchemaField.String
                        name="iconName"
                        x-component={IconSelect}
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: "事件",
                        width: 160,
                      }}
                    >
                      <SchemaField.Object
                        name="clickEventProps"
                        x-component={ClickEventSetter}
                        x-component-props={{
                          showEnable: true
                        }}
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: "字体颜色",
                        width: 120,
                      }}
                    >
                      <SchemaField.String
                        name="textColor"
                        x-decorator="FormItem"
                        x-component={ActionColor}
                        
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: "是否携带参数",
                        width: 120,
                      }}
                    >
                      <SchemaField.Boolean
                        name="withProps"
                        x-decorator="FormItem"
                        x-component="Switch"
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: "宽度",
                        width: 100,
                      }}
                    >
                      <SchemaField.String
                        name="width"
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={{
                          placeholder: "请输入",
                        }}
                        x-reactions={(field) => {
                          field.visible = field.index == 0;
                        }}
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: "对齐方式",
                        width: 100,
                      }}
                    >
                      <SchemaField.String
                        name="align"
                        x-decorator="FormItem"
                        x-component="Select"
                        enum={[
                          { label: "左对齐", value: "left" },
                          { label: "居中", value: "center" },
                          { label: "右对齐", value: "right" },
                        ]}
                        x-component-props={{
                          placeholder: "请选择",
                          defaultValue: "left",
                        }}
                        x-reactions={(field) => {
                          field.visible = field.index == 0;
                        }}
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: "固定列",
                        width: 100,
                      }}
                    >
                      <SchemaField.String
                        name="fixed"
                        x-decorator="FormItem"
                        x-component="Select"
                        enum={[
                          { label: "否", value: false },
                          { label: "左固定", value: "left" },
                          { label: "右固定", value: "right" },
                        ]}
                        x-component-props={{
                          placeholder: "请选择",
                          defaultValue: false,
                        }}
                        x-reactions={(field) => {
                          field.visible = field.index == 0;
                        }}
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: GlobalRegistry.getDesignerMessage(
                          "SettingComponents.ReactionsSetter.operations"
                        ),
                        align: "center",
                        width: 80,
                      }}
                    >
                      <SchemaField.Markup
                        type="void"
                        x-component="ArrayTable.Remove"
                      />
                    </SchemaField.Void>
                  </SchemaField.Object>
                  <SchemaField.Void
                    title={GlobalRegistry.getDesignerMessage(
                      "SettingComponents.ReactionsSetter.addRelationField"
                    )}
                    x-component="ArrayTable.Addition"
                    x-component-props={{ style: { marginTop: 8 } }}
                  />
                </SchemaField.Array>
              </SchemaField>
            </Form>
          ) : (
            <div className="example">
              <Spin />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
