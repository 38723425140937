import extendNode from '../extendNode.mjs'
import useAbstractNode from '../_ABSTRACT/useAbstractNode.jsx'
import meta from './metaSf_2.mjs'
import FormChoose from './FormChooseSf_2.jsx'
import TableChoose from './TableChooseSf_2.jsx'
import api from './apiSf_2.mjs'

export default () => {
    const AbstractNode = useAbstractNode()

    return extendNode(AbstractNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
    })
}
