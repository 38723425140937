import Table from '@/components/Table/Table.jsx'
import TableChooseProduct from '../_PRODUCT/TableChooseProduct.jsx'
import DbmsType from './DbmsType.mjs'

const DictDbmsType = Object.fromEntries(DbmsType)

const TableChooseDb = (props) => {
    const columns = [
        {
            title: '数据库代码',
            dataIndex: 'dbUserCode',
            component: <Table.ViewText />,
        },

        {
            title: '数据库名称',
            dataIndex: 'dbName',
            component: <Table.ViewText />,
        },

        {
            title: 'DBMS',
            dataIndex: 'dbmsCode',
            component: <Table.ViewDict dict={DictDbmsType} />,
        },
    ]

    return (
        <TableChooseProduct
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseDb
