import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import meta from './metaCdt.mjs'
import Model from './ModelCdt.mjs'
import PropertiesPane from './PropertiesPaneCdt.jsx'
import FormChoose from './FormChooseCdt.jsx'
import TableChoose from './TableChooseCdt.jsx'
import api from './apiCdt.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return 'CDTSEQVAL' === type
        },

        defaultChildType(map, node) {
            return 'CDTSEQVAL'
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {backgroundColor: Color.CYAN}),
                shape: 'EllipseRectangle',
            }
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['CDTSEQVAL'],
            ]
        },

        _pushDataSlots: {
            children: [],
            dmCdtSeqVals: [],
        },
    })
}
