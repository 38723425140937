import Table from '@/components/Table/Table.jsx'
import TableChooseComponent from '../_COMPONENT/TableChooseComponent.jsx'

export default function TableChooseDe(props) {
    const columns = [
        {
            title: '分析指标标题',
            dataIndex: 'aiName',
            component: <Table.ViewText />,
        },
        {
            title: '分析指标代码',
            dataIndex: 'aiNo',
            component: <Table.ViewText />,
        },
        {
            title: '分析口径名称',
            dataIndex: 'acName',
            component: <Table.ViewText />,
        },

        {
            title: '分析口径代码',
            dataIndex: 'acUserCode',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseComponent
            columns={columns}
            {...props}
        />
    )
}
