import {useRef} from 'react'

export default onChange => {
    const refItems = useRef(new Set)

    return {
        deregisteritem: item => {
            refItems.current.delete(item)
        },

        registerItem: item => {
            refItems.current.add(item)
        },

        move: (item, offsetX, offsetY) => {
            Object.assign(item, {offsetX, offsetY})

            for (const it of refItems.current) {
                it.move(item)
            }
        },

        moveStart: () => {
            for (const it of refItems.current) {
                it.moveStart()
            }
        },

        moveEnd: () => {
            const items = [...refItems.current]

            const isChanged = items.some(e => ! e.isFocused && e.offsetY)

            if (isChanged) {
                const oldIndexes = new Map(
                    items
                        .sort((a, b) => a.top - b.top)
                        .map((e, i) => [e, i])
                )

                const newIndexes = items
                    .sort((a, b) => a.top + a.offsetY - b.top - b.offsetY)
                    .map(e => oldIndexes.get(e))

                onChange(newIndexes)
            }

            for (const it of refItems.current) {
                it.moveEnd()
            }
        },
    }
}
