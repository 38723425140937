import extendNode from '../extendNode.mjs'
import useCatRootModuleNode from '../_CAT_ROOT_MODULE/useCatRootModuleNode.jsx'
import PropertiesPane from './PropertiesPaneCatRootDm.jsx'
import meta from './metaCatRootDm.mjs'

export default () => {
    const CatRootModuleNode = useCatRootModuleNode()

    return extendNode(CatRootModuleNode, {
        ...meta,
        PropertiesPane,

        canLinkType(map, node, type) {
            return /^(CDG|CDT|DT|DV)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'DT'
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['DT'],
                ['DV'],
            ]
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['DT'],
                ['DV'],
                ['CDT'],
                ['CDG'],
            ]
        },
    })
}
