import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    rdName: {
        name: () => '报表指标名称',
        required: () => true,
    },

    rdUserCode: {
        name: () => '报表指标代码',
    },

    resume: {
        name: () => '简述',
    },

    rdTitle: {
        name: () => '标题名称',
    },
})
