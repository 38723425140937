import superMeta from '../_PRODUCT/metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'arCode',
    detailUrl: '/AmArDetail',
    detailIdProp: 'arId',
    mapProp: 'arMap',
    name: '分析报表',
    textProp: 'arName',
    type: 'AMAR',
}
