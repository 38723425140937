import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'adUserCode',
    mapProp: 'adMap',
    name: '分析维度',
    ownerIdProp: 'aoId',
    ownerTextProp: 'aoName',
    ownerType: 'AO',
    textProp: 'adTitle',
    type: 'AD',
}
