import superMeta from '../_PRODUCT/metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'ooNo',
    detailUrl: '/OoDetail',
    detailIdProp: 'ooId',
    mapProp: 'ooMap',
    name: '组织对象',
    textProp: 'ooName',
    type: 'OO',
}
