import extendNode from '../extendNode.mjs'
import useCommonNode from '../_COMMON/useCommonNode.jsx'
import PsType from './PsType.jsx'
import meta from './metaPs.mjs'
import PropertiesPane from './PropertiesPanePs.jsx'

export default () => {
    const CommonNode = useCommonNode()

    return extendNode(CommonNode, {
        ...meta,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(DOC|FJ|MARK|PIC|PS)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'PS'
        },

        getIcons(map, node) {
            const {psTypeCode} = node.data

            const type2icon = new Map(
                PsType.map(([type, , icon]) => [type, icon])
            )

            const icon = type2icon.get(psTypeCode)

            if (icon) {
                return [icon]
            }
            else {
                return []
            }
        },

        getStyle(map, node) {
            return {
                ...CommonNode.getStyle.call(this, map, node),
                backgroundColor: '#f8f7f7',
                shape: 'Rectangle',
            }
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['MARK'],
                ['PIC'],
                ['DOC'],
                ['PS'],
            ]
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['FJ'],
            ]
        },

        _getDefaultDataFields(map, node) {
            // 不需要根据类型自动填充文本，故不调用父类的方法
            return {
                psTypeCode: 'PF',
            }
        },
    })
}
