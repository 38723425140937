import SidePane from '../../../SidePanel/SidePane.jsx'
import HideIrrelevant from './ControllerHideIrrelevant.jsx'

export default function UpgradePane(props) {
    return (
        <SidePane {...props}>
            <HideIrrelevant />
        </SidePane>
    )
}
