import {useEffect, useRef, useState} from 'react'
import {publish, subscribe, unsubscribe} from '@/script/event.mjs'

export default (defaultShow) => {
    const refShow = useRef(defaultShow)

    const extensions = () => ({
        get showNodeDesc() {
            return refShow.current
        },

        set showNodeDesc(value) {
            refShow.current = value
            publish(this, 'show_node_desc_change', value)
        },

        useShowNodeDesc() {
            const [show, setShow] = useState(refShow.current)

            useEffect(
                () => {
                    subscribe(this, 'show_node_desc_change', setShow)

                    return () => {
                        unsubscribe(this, 'show_node_desc_change', setShow)
                    }
                },

                []
            )

            return show
        },
    })

    return {extensions}
}
