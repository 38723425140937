import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaDb.mjs'
import Model from './ModelDb.mjs'
import PropertiesPane from './PropertiesPaneDb.jsx'
import FormChoose from './FormChooseDb.jsx'
import TableChoose from './TableChooseDb.jsx'
import api from './apiDb.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return (
                /^(DM)$/.test(type) ||
                ProductNode.canMountType.call(this, map, node, type)
            )
        },

        exportTree(map, node, options) {
            if ('SA_MAP' === map.data.mapTypeCode) {
                const children = [...node.children]
                    .map(n => {
                        const _n = map.nodeProxy(n)
                        return _n.exportTree(options)
                    })
                    .filter(a => a)

                return {
                    ...map.exportNode(node),
                    children,
                }
            }
            else {
                return ProductNode.exportTree.call(this, map, node, options)
            }
        },

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    fill={Color.DARK_GOLD}
                    letters="D"
                    textColor="#fff"
                />
            ]
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                backgroundColor: Color.CYAN,
            })
        },

        _getCategories(map, node) {
            return [
                'DB_DA',
                'DB_DBINS',
            ]
        },

        _pushDataSlots: {
            archDmList: [],
            archDbInss: [],
        },

        async _grow(map, node, depth) {
            const newDepth = await ProductNode._grow.call(
                this, map, node, depth
            )

            if (newDepth === depth) {
                return depth
            }

            const p = node.trueParent

            if (p) {
                const {bizNodeType} = p.data

                if ('DEP_DB' === bizNodeType) {
                    for (const n of [...node.children]) {
                        if ('DB_DBINS' === n.data.bizNodeType) {
                            map.deleteNode(n)
                        }
                        else {
                            map.deleteTree(n)
                        }
                    }
                }
                else if ('CAT_DB' === bizNodeType) {
                    for (const n of [...node.children]) {
                        if ('DB_DA' === n.data.bizNodeType) {
                            map.deleteNode(n)
                        }
                        else {
                            map.deleteTree(n)
                        }
                    }
                }
            }

            return newDepth
        },
    })
}
