import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    rmUserCode: {
        name: () => '需求域代码',
    },

    rmName: {
        name: () => '需求域名称',
        required: () => true,
    },
})
