import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaDv.mjs'
import Model from './ModelDv.mjs'
import PropertiesPane from './PropertiesPaneDv.jsx'
import FormChoose from './FormChooseDv.jsx'
import TableChoose from './TableChooseDv.jsx'
import api from './apiDv.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        async choose(map, node) {
            const {prjNo} = map.data

            const getQuery = ({dms = [], ...query}) => {
                const dbCode = (() => {
                    const p = node.trueParent

                    if (! p) {
                        return
                    }

                    const {bizNodeType} = p.data

                    if ('DV_DS' === bizNodeType) {
                        return p.parent.data.dbCode
                    }
                })()

                return {
                    prjNo,
                    ...query,
                    dbCode,
                    dmIds: dms.map(({dmId}) => dmId),
                }
            }

            return this._choose(map, node, {getQuery})
        },

        getIcons(map, node) {
            return [
                <IconLetters
                    key="type"
                    fill="#fff"
                    letters="V"
                    textColor="#000"
                />
            ]
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {backgroundColor: Color.CYAN}),
                shape: 'SingleBreakangle',
            }
        },

        mapUpdateNodeData(map, node, data) {
            const d = {...data}
            const oldData = node.data

            if (
                ! data.dmCode &&
                oldData.dbCode !== data.dbCode
            ) {
                Object.assign(d, {
                    dmCode: '',
                    dmId: '',
                    dmName: '',
                })
            }

            return d
        },

        async _atCreate(map, node) {
            for (const n of map.chain(node.parent)) {
                const {
                    bizNodeType,
                    dmCode,
                    dmId,
                    dmName,
                } = n.data

                if ('CAT_ROOT_DM' === bizNodeType) {
                    node.data = {
                        ...node.data,
                        dmCode,
                        dmId,
                        dmName,
                    }

                    break
                }
            }

            await ProductNode._atCreate.call(this, map, node)
        },

        _getCategories(map, node) {
            return [
                'DV_DS',
                'DV_MEMO',
            ]
        },
    })
}
