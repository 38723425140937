import usePointer from '@/hooks/usePointer.mjs'

import {
    useDraggableListContext,
    useDraggableListItemContext,
} from './DraggableListContext.mjs'

export default () => {
    const l = useDraggableListContext()
    const li = useDraggableListItemContext()

    return usePointer({
        onClick: e => {
            e.stopPropagation()
        },

        onDoubleClick: e => {
            e.stopPropagation()
        },

        onPointerDown: e => {
            e.stopPropagation()
            li.focus()
        },

        onPointerUp: e => {
            e.stopPropagation()
            li.blur()
        },

        onSwipeEnd: () => {
            l.moveEnd()
        },

        onSwipeMove: e => {
            e.stopPropagation()
            const {clientX, clientY, initialClientX, initialClientY} = e
            l.move(li, clientX - initialClientX, clientY - initialClientY)
        },

        onSwipeStart: () => {
            l.moveStart()
        },
    })
}
