import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import meta from './metaUialg.mjs'
import Model from './ModelUialg.mjs'
import PropertiesPane from './PropertiesPaneUialg.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.LIGHT_BLUE}),
                shape: 'HorizontalHexagon',
            }
        },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.cAlgList.push(d)
        },

        _pushDataSlots: {
        },

        _getCategories(map, node) {
            return [
                'ALG_RULE',
                'ALG_CORE',
                'ALG_API',
            ]
        },
    })
}
