import extendNode from '../extendNode.mjs'
// import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaAmarIvScope.mjs'

export default () => {
    // const CategoryNode = useCategoryNode()
    const ArCatNode = useArCatNode()

    return extendNode(ArCatNode, {
        ...meta,
    })
}