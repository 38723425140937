import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

import {
    PropertiesController,
    usePropertiesController,
} from '../../SidePanel/RightPanel/PropertiesController.jsx'

import SelectPm from './SelectPm.jsx'

export default function PropertiesControllerPm({
    className,
    style,
    codeProp = 'pmCode',
    disabled,
    hidden,
    idProp = 'pmId',
    label,
    labelSuffix,
    nameProp = 'pmName',
    readOnly,
    required,
    ...props
}) {
    const [values, update] = useProperties()

    const {
        [codeProp]: pmCode,
        [idProp]: pmId,
        [nameProp]: pmName,
    } = values

    const handleChange = pm => {
        const {
            pmCode = '',
            pmId = '',
            pmName = '',
        } = pm ?? {}

        update({
            [codeProp]: pmCode,
            [idProp]: pmId,
            [nameProp]: pmName,
        })
    }

    const p = usePropertiesController({
        disabled,
        hidden,
        label,
        prop: codeProp,
        readOnly,
        required,
    })

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            label={p.label}
            labelSuffix={labelSuffix}
            required={p.required}
        >
            <SelectPm
                disabled={p.disabled}
                readOnly={p.readOnly}
                value={{pmCode, pmId, pmName}}
                onChange={handleChange}
                {...props}
            />
        </PropertiesController>
    )
}
