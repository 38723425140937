import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import meta from './metaBaseNode.mjs'
import NodeEvent from './NodeEvent.mjs'

const EVENT_TYPES = [
    'attach',
    'change',
    'create',
    'delete',
    'grow',
    'import',
    'insert',
    'push',
    'shrink',
]

/**
 * 所有业务节点类型的基类
 */
export default () => {
    return extendNode(null, {
        ...meta,

        /**
         * 节点侧面板
         */
        get nodePanes() {
            const panes = {}
            const {PropertiesPane, PropertiesPaneBatch, StylePane} = this

            if (PropertiesPane) {
                const components = [PropertiesPane]

                if (PropertiesPaneBatch) {
                    components.push(PropertiesPaneBatch)
                }

                panes.properties = {components}
            }

            if (StylePane) {
                panes.style = {components: [StylePane, StylePane]}
            }

            return panes
        },

        /**
         * 当节点被作为子树根删除前回调
         */
        beforeDeleteTree(map, node) {
        },

        /**
         * 能否拷贝树
         */
        canCopyTree(map, node) {
            return true
        },

        /**
         * 能否拷贝样式
         */
        canCopyStyle(map, node) {
            map.logger.error(`${this.name}不支持拷贝样式`, [node])
            return false
        },

        /**
         * 能否删除单个节点
         *
         * 删除单个节点仅删除节点自身，子节点不受影响
         */
        canDeleteNode(map, node) {
            // 对于绝大部分业务节点，删除单个节点都会破坏其父子的从属关系，
            // 故默认不能删除
            const {bizNodeType} = node.data
            const {name} = map.BizNode[bizNodeType]
            map.logger.error(`${name}不支持删除单个节点`, [node])
            return false
        },

        /**
         * 能否删除树
         *
         * 删除树会连同子节点一并删除
         */
        canDeleteTree(map, node) {
            if (node.parent) {
                // 如果父节点能编辑子树，通常都可以删除
                const {bizNodeType} = node.parent.data
                return map.BizNode[bizNodeType].canWriteTree(map, node.parent)
            }
            else {
                // 根节点由上层库保证不能删除，此处无需处理
                return true
            }
        },

        /**
         * 能否复制树
         *
         * 复制通常会产生一棵完全一样的兄弟树
         */
        canDuplicate(map, node) {
            return this.canDeleteTree(map, node)
        },

        /**
         * 能否加载
         */
        canGrow(map, node) {
            const {mapTypeCode} = map.data

            if ('ANALYSE_MAP' === mapTypeCode) {
                return false
            }

            return true
        },

        /**
         * 能否在节点后链接指定类型的节点
         */
        canLinkType(map, node, type) {
            // 默认不能链接任何类型，由子类重写
            return false
        },

        /**
         * 能否在节点后挂载指定类型的节点
         */
        canMountType(map, node, type) {
            const categories = this._getCategories(map, node)
            return categories.includes(type)
        },

        /**
         * 能否移动
         */
        canMove(map, node) {
            if (node.parent) {
                const _p = map.nodeProxy(node.parent)
                return _p.canWriteTree()
            }
            else {
                return false
            }
        },

        /**
         * 能否卸载
         */
        canShrink(map, node) {
            // 通常能加载的节点都可以卸载
            return this.canGrow(map, node)
        },

        /**
         * 能否编辑自身
         */
        canWriteData(map, node) {
            if (node.parent) {
                // 如果父节点能编辑子树，通常都可以编辑自身
                const {bizNodeType} = node.parent.data
                return map.BizNode[bizNodeType].canWriteTree(map, node.parent)
            }
            else {
                return true
            }
        },

        /**
         * 能否编辑样式
         */
        canWriteStyle(map, node) {
            return false
        },

        /**
         * 能否编辑子树
         */
        canWriteTree(map, node) {
            if (node.parent) {
                // 如果父节点能编辑子树，通常都可以编辑子树
                const {bizNodeType} = node.parent.data
                return map.BizNode[bizNodeType].canWriteTree(map, node.parent)
            }
            else {
                return true
            }
        },

        /**
         * 从其他类型转化
         */
        castFrom(map, node) {
            const {bizNodeType} = node.data
            map.BizNode[bizNodeType].castTo(map, node, this.type)
        },

        /**
         * 转化为其他类型
         */
        castTo(map, node, bizNodeType) {
            const {[this.textProp]: text, ...data} = node.data
            const {textProp} = map.BizNode[bizNodeType]

            node.data = {
                // TODO
                //...map.BizNode[bizNodeType].getInitData(map, node.parent),
                ...data,
                bizNodeType,
                [textProp]: text,
            }
        },

        /**
         * 清除冗余数据
         */
        clean(map, node) {
            const {
                [this.mapProp]: _,
                ...data
            } = node.data

            node.data = data
        },

        /**
         * 上下文菜单
         */
        contextMenu(map, nodes) {
            return []
        },

        /**
         * 用于创建默认子节点的类型
         */
        defaultChildType(map, node) {
            return ''
        },

        /**
         * 导出节点树
         */
        exportTree(map, node, options) {
            const exportNode = () => {
                return {
                    ...map.exportNode(node),
                    children: [],
                }
            }

            const exportTree = () => {
                const children = [...node.children]
                    .map(n => {
                        const _n = map.nodeProxy(n)
                        return _n.exportTree(options)
                    })
                    .filter(a => a)

                return {
                    ...map.exportNode(node),
                    children,
                }
            }

            if (options?.compact) {
                if (! node.parent) {
                    return exportTree()
                }
                else if (this.isLinked(map, node)) {
                    return exportNode()
                }
                else if (this.isMounted(map, node)) {
                    return exportTree()
                }
                else {
                    return null
                }
            }
            else {
                return exportTree()
            }
        },

        /**
         * 默认文本
         */
        defaultText(map, node) {
            // 默认是类型的中文名称
            return this.name
        },

        /**
         * 产生一个事件并沿节点向下逐级处理
         */
        async emitEventDown(map, node, {detail, type}) {
            const emit = async (chain, nodes) => {
                for (const n of nodes) {
                    const e = new NodeEvent({
                        chain: [...chain, n],
                        detail,
                        target: node,
                        type,
                    })

                    const _n = map.nodeProxy(n)
                    await _n.atEvent(e)

                    if (
                        ! e.isPropagationStopped &&
                        (_n.isMounted() || n === node)
                    ) {
                        await emit(e.chain, n.children)
                    }
                }
            }

            await emit([], [node])
        },

        /**
         * 产生一个事件并沿节点向上逐级处理
         */
        async emitEventUp(map, node, {detail, type}) {
            const e = new NodeEvent({detail, target: node, type})

            for (const n of node.chain) {
                e.chain.push(n)
                const _n = map.nodeProxy(n)
                await _n.onEvent(e)

                if (e.isPropagationStopped) {
                    break
                }
            }
        },

        getDesc(map, node) {
            return ''
        },

        /**
         * 获取图标组
         */
        getIcons(map, node) {
            return []
        },

        /**
         * 获取路径
         */
        getPath(map, node) {
            return [...node.chain]
                .reverse()
                .map(n => {
                    const _n = map.nodeProxy(n)
                    const text = _n.getText()
                    const prefix = _n.getTextPrefix()
                    const suffix = _n.getTextSuffix()
                    return [prefix, text, suffix].join('') || '(空白)'
                })
                .join('/')
        },

        /**
         * 获取修订号
         */
        getRev(map, node) {
            return node.data.rev ?? null
        },

        /**
         * 获取节点样式
         */
        getStyle(map, node) {
            const style = {
                backgroundColor: '#fff',
                borderColor: 'transparent',
                borderWidth: 2,
                fontSize: 13,
                fontWeight: 400,
                leadingLineColor: '#666',
                leadingLineWidth: 2,
                lineColor: '#999',
                lineWidth: 2,
                shape: 'RoundedRectangle',
                textAlign: 'left',
                textColor: '#000',
                trailingLineColor: '#666',
                trailingLineWidth: 2,
            }

            if (node === map.root) {
                style.fontSize = 16
                style.fontWeight = 700
            }

            const {
                _compareIdToDiffType,
                _subTreeModifiedCompareIds,
            } = map.data

            if (_compareIdToDiffType && _subTreeModifiedCompareIds) {
                const {mapCompUuid} = node.data
                const diffType = _compareIdToDiffType.get(mapCompUuid)

                if (_subTreeModifiedCompareIds.has(mapCompUuid)) {
                    style.leadingLineColor = Color.DARK_GOLD
                    style.trailingLineColor = Color.DARK_GOLD
                }

                if ('ADD' === diffType) {
                    style.outerBoxBackgroundColor = `${Color.GREEN}50`
                    style.leadingLineColor = Color.GREEN
                }
                else if ('DEL' === diffType) {
                    style.outerBoxBackgroundColor = `${Color.RED}50`
                    style.leadingLineColor = Color.RED
                }
                else if ('UPT' === diffType) {
                    style.outerBoxBackgroundColor = '#ffff00'
                    style.leadingLineColor = Color.DARK_GOLD
                }
            }

            return style
        },

        /**
         * 获取版本号
         */
        getVersion(map, node) {
            const rev = this.getRev(map, node)

            if (null === rev) {
                return ''
            }

            const {sVer} = node.data

            if (sVer) {
                return `${sVer}.${rev}`
            }
            else {
                return ''
            }
        },

        /**
         * 获取节点文本
         */
        getText(map, node) {
            return this.getTextRaw(map, node)
        },

        /**
         * 获取节点文本前缀
         */
        getTextPrefix(map, node) {
            return ''
        },

        /**
         * 获取节点原始文本
         */
        getTextRaw(map, node) {
            return node.data[this.textProp] ?? ''
        },

        /**
         * 获取节点文本后缀
         */
        getTextSuffix(map, node) {
            return ''
        },

        /**
         * 获取节点提示
         */
        getTitle(map, node) {
            return this.name
        },

        /**
         * 获取节点超链接地址
         */
        async getUrl(map, node, childPath = []) {
            const {[this.textProp]: text = ''} = node.data
            const path = [encodeURIComponent(text), ...childPath]

            if (node.parent) {
                const {bizNodeType} = node.parent.data
                return map.BizNode[bizNodeType].getUrl(map, node.parent, path)
            }
            else {
                return `/${path.join('/')}/`
            }
        },

        /**
         * 加载节点
         */
        async grow(map, node, {depth = 0, maxDepth = 1} = {}) {
            const newDepth = await this._grow(map, node, depth)

            if (depth < newDepth) {
                this.emitEventUp(map, node, {type: 'grow'})
                this.emitEventDown(map, node, {type: 'import'})
            }

            node.isFolded = 0 < depth

            if (newDepth < maxDepth) {
                await Promise.all(
                    [...node.children].map(child => {
                        const n = map.nodeProxy(child)
                        return n.grow({depth: newDepth, maxDepth})
                    })
                )
            }
            else if (1 < maxDepth) {
                map.logger.warn('已达最大加载层数，停止加载', [node])
            }
        },

        /**
         * 是否其他项目节点
         */
        isExternal(map, node) {
            return false
        },

        /**
         * 是否隐藏
         */
        isHidden(map, node) {
            return false
        },

        /**
         * 是否被链接
         */
        isLinked(map, node) {
            const p = node.parent

            if (! p) {
                return false
            }

            const _p = map.nodeProxy(p)
            return _p.canLinkType(node.data.bizNodeType)
        },

        /**
         * 是否被挂载
         */
        isMounted(map, node) {
            const p = node.parent

            if (! p) {
                return false
            }

            const _p = map.nodeProxy(p)
            const {bizNodeType} = node.data

            if (! _p.canMountType(bizNodeType)) {
                return false
            }

            return ! p.parent || _p.isMounted()
        },

        /**
         * 是否已不是最新版本
         */
        isOutdated(map, node) {
            const rev = this.getRev(map, node)

            if (null === rev) {
                map.logger.error('模件没有版本化', [node])
                return false
            }

            const {delFlag, lastRev} = node.data

            if ('1' === delFlag) {
                map.logger.error('模件已被删除', [node])
                return false
            }

            if (! lastRev) {
                map.logger.error('模件没有最新版本信息', [node])
                return false
            }

            return rev < lastRev
        },

        /**
         * 转化替换节点文本时数据
         */
        mapReplaceTextData(map, node, replace) {
            const text = this.getText(map, node)
            const newText = replace(text)
            return this.mapSetTextData(map, node, newText)
        },

        /**
         * 转化设置节点文本时数据
         */
        mapSetTextData(map, node, text) {
            return {[this.textProp]: text}
        },

        /**
         * 转化更新节点的数据
         */
        mapUpdateNodeData(map, node, data) {
            return data
        },

        /**
         * 节点的「插入通用节点」的菜单项
         */
        menuItemsInsertCommon(map, node) {
            return []
        },

        /**
         * 节点的「插入概念节点」的菜单项
         */
        menuItemsInsertConcept(map, node) {
            return []
        },

        /**
         * 节点的「插入制品节点」的菜单项
         */
        menuItemsInsertProduct(map, node) {
            return []
        },

        /**
         * 当用户双击节点时回调
         */
        async onDoubleClick(map, node, event) {
            if (! this.canWriteData(map, node)) {
                event.preventDefault()
            }
        },

        /**
         * 处理向下的事件
         */
        atEvent: (() => {
            const Type2Method = Object.fromEntries(
                EVENT_TYPES.map(t => {
                    const capitalized = t
                        .split('_')
                        .map(s => `${s.charAt(0).toUpperCase()}${s.slice(1)}`)
                        .join('')

                    return [t, `_at${capitalized}`]
                })
            )

            return async function (map, node, event) {
                const m = Type2Method[event.type]
                await this[m]?.(map, node, event)
            }
        })(),

        /**
         * 处理向上的事件
         */
        onEvent: (() => {
            const Type2Method = Object.fromEntries(
                EVENT_TYPES.map(t => {
                    const capitalized = t
                        .split('_')
                        .map(s => `${s.charAt(0).toUpperCase()}${s.slice(1)}`)
                        .join('')

                    return [t, `_on${capitalized}`]
                })
            )

            return async function (map, node, event) {
                const m = Type2Method[event.type]
                await this[m]?.(map, node, event)
            }
        })(),

        /**
         * 节点因重载被插入地图后回调
         * TODO: use event
         */
        async onPull(map, node) {
            await Promise.all(
                [...node.children].map(
                    async n => {
                        const _n = map.nodeProxy(n)
                        await _n.onPull()
                    }
                )
            )
        },

        /**
         * 替换子树并合并节点属性
         */
        async replace(map, node, tree) {
            const {children, ...props} = tree
            map.deleteChildren(node)

            for (const tree of children) {
                const child = map.importTree(tree)
                map.appendChild(node, child)
            }

            // 设置某些属性的行为可能取决于子节点，故要先处理子节点再设置属性
            Object.assign(node, props)
            await this.emitEventDown(map, node, {type: 'import'})
        },

        /**
         * 是否总是在节点上显示释义
         */
        showDesc(map, node) {
            return false
        },

        /**
         * 是否在节点上显示版本号
         */
        showVersion(map, node) {
            return true
        },

        /**
         * 卸载节点
         */
        async shrink(map, node) {
            if (! this.canShrink(map, node)) {
                return
            }

            await this.emitEventUp(map, node, {type: 'shrink'})

            await Promise.all(
                [...node.children].map(
                    async n => {
                        const _n = map.nodeProxy(n)
                        await _n.shrink()
                    }
                )
            )
        },

        /**
         * 默认的事件处理
         */
        ...(() => Object.fromEntries(
            EVENT_TYPES
                .map(t => {
                    const capitalized = t
                        .split('_')
                        .map(s => `${s.charAt(0).toUpperCase()}${s.slice(1)}`)
                        .join('')

                    return [
                        [`_at${capitalized}`, function () {}],
                        [`_on${capitalized}`, function () {}],
                    ]
                })
                .flat()
        ))(),

        /**
         * 当节点通过粘贴被插入地图时回调
         */
        async _atAttach(map, node, event) {
            await this._atImport(map, node, event)
        },

        /**
         * 当节点被创建时回调
         */
        async _atCreate(map, node, event) {
            const defaultDataFields = this._getDefaultDataFields(map, node)

            const dataFields = Object.entries(defaultDataFields)
                .filter(([k]) => void 0 === node.data[k])

            if (0 < dataFields.length) {
                node.data = {
                    ...node.data,
                    ...Object.fromEntries(dataFields),
                }
            }

            if (this.isMounted(map, node)) {
                const categories = this._getCategories(map, node)

                for (const type of categories) {
                    const n = map.createNode(type)
                    map.appendChild(node, n)
                }
            }

            await this._atImport(map, node, event)
        },

        async _atImport(map, node, event) {
            this.clean(map, node)
        },

        /**
         * 修复子树使之合法
         */
        _fixChild(map, node, child) {
            return false
        },

        /**
         * 修复树使之合法
         */
        _fixTree(map, node) {
            const _p = map.nodeProxy(node.parent)
            const isFixed = _p._fixChild(node)

            if (! isFixed) {
                return false
            }

            for (const n of node.children) {
                const _n = map.nodeProxy(n)
                _n._fixTree()
            }

            return true
        },

        _getCategories(map, node) {
            return []
        },

        /**
         * 获取创建节点时默认的数据
         */
        _getDefaultDataFields(map, node) {
            return {
                [this.textProp]: this.defaultText(map, node),
            }
        },

        /**
         * 通用图的实际加载逻辑，通常由子类重写。
         * 如果有实际加载，返回的深度应加 1，否则返回原深度。
         */
        async _grow(map, node, depth) {
            return depth
        },

        /**
         * 节点通过粘贴被插入地图时的事件处理
         */
        async _onAttach(map, node, event) {
            if (node !== event.target) {
                return
            }

            if (
                this.isLinked(map, node) ||
                this.isMounted(map, node)
            ) {
                await this.emitEventDown(map, node, event)
            }
            else {
                const isFixed = this._fixTree(map, node)

                if (isFixed) {
                    await this.emitEventDown(map, node, event)
                }
                else {
                    event.stopPropagation()
                    map.deleteTree(node)
                    map.logger.error('子节点不合法，不能插入', [node.parent])
                }
            }
        },

        /**
         * 节点业务数据被改变时的事件处理
         */
        async _onChange(map, node, event) {
        },

        /**
         * 节点被创建时的事件处理
         */
        async _onCreate(map, node, event) {
            if (node === event.target) {
                await this.emitEventDown(map, node, event)
            }
        },
    })
}
