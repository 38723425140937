import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import IconMonitor from '../icons/IconMonitor.jsx'
import meta from './metaFres.mjs'
import Model from './ModelFres.mjs'
import PropertiesPane from './PropertiesPaneFres.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        canLinkType(map, node, type) {
            return /^(U[EV]|UI?)$/.test(type)
        },

        canMountType(map, node, type) {
            return /^(PIC)$/.test(type)
        },

        getIcons(map, node) {
            return [
                <IconMonitor
                    key="type"
                    color={Color.LIGHT_BLUE}
                />
            ]
        },

        getRev(map, node) {
            return null
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.GOLD}),
                shape: 'RoundedRectangle',
            }
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['PIC'],
            ]
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['UI'],
                ['UV'],
                ['UE'],
            ]
        },

        async onPull(map, node) {
            await ComponentNode.onPull.call(this, map, node)
            node.isFolded = false
        },

        async _atOwnerPush(map, node, event) {
            const pushData = await this._getPushData(map, node)

            const d = {
                ...pushData,
                resPics: [],
                ssCode: '',
                ueCode: '',
                ueName: '',
                ueRev: '',
                uiCode: '',
                uiName: '',
                secSsCode: '',
                secUeCode: '',
                secUeName: '',
                secUeRev: '',
                secUiCode: '',
                secUiName: '',
            }

            const next = chain => {
                const _n = map.nodeProxy(chain[0])
                return {yieldChildren: _n.isMounted()}
            }

            for (const n of map.bfs(node.children, next)) {
                const {bizNodeType} = n.data

                if ('PIC' === bizNodeType) {
                    const {fileId: picId, text: picName} = n.data

                    if (picId) {
                        d.resPics.push({picId, picName})
                    }
                }
                else if ('UE' === bizNodeType) {
                    const {
                        rev,
                        ssCode,
                        ueCode,
                        ueName,
                        uiCode,
                        uiName,
                    } = n.data

                    if (! d.ueCode && ! d.uiCode) {
                        Object.assign(d, {
                            ssCode,
                            ueCode,
                            ueName,
                            ueRev: rev,
                            uiCode,
                            uiName,
                        })
                    }
                    else if (! d.secUeCode && ! d.secUiCode) {
                        Object.assign(d, {
                            secSsCode: ssCode,
                            secUeCode: ueCode,
                            secUeName: ueName,
                            secUeRev: rev,
                            secUiCode: uiCode,
                            secUiName: uiName,
                        })
                    }
                }
                else if ('UI' === bizNodeType) {
                    const {rev, ssCode, uiCode, uiName} = n.data

                    if (! d.ueCode && ! d.uiCode) {
                        Object.assign(d, {
                            ssCode,
                            ueRev: rev, // 没写错
                            uiCode,
                            uiName,
                        })
                    }
                    else if (! d.secUeCode && ! d.secUiCode) {
                        Object.assign(d, {
                            secSsCode: ssCode,
                            secUeRev: rev, // 没写错
                            secUiCode: uiCode,
                            secUiName: uiName,
                        })
                    }
                }
            }

            event.detail.child.push(d)
        },

        _getDefaultDataFields(map, node) {
            // 不需要根据类型自动填充文本，故不调用父类的方法
            return {}
        },
    })
}
