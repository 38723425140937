import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    adTitle: {
        name: () => '分析维度标题',
        required: () => true,
    },

    adUserCode: {
        name: () => '分析维度代码',
    },

    resume: {
        name: () => '简述',
    },
})
