import http from '@/biz/http.mjs'

export default {
    publish: data => http.post('/aa/rlsArchAa', data),
    pull: ({pkid}) => http.get('/aa/loadDp', {pkid}),
    push: data => http.post('/aa/modifyDp', data),
    read: args => http.get('/aa/oneArchAa', args),
    revise: ({pkid}) => http.post('/aa/reviseArchAa', {pkid}),
    updateMap: data => http.post('/aa/saveMap', data),
}
