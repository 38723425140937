import {Button} from 'antd'
import {useMapContext} from '@/components/MindMap/index.mjs'

export default function PropertiesPaneButtonViewStruct(props) {
    const map = useMapContext()
    const [node] = map.useSelectedNodes()

    if ('SA_MAP' !== map.data.mapTypeCode) {
        return null
    }

    const {bizNodeType} = node.data
    const {name} = map.BizNode[bizNodeType]

    const handleClick = e => {
        const target = e.altKey ? '_self' : '_blank'
        const _n = map.nodeProxy(node)
        _n.gotoCurrent(target)
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >
            编辑{name}
        </Button>
    )
}
