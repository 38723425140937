import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    amCode: {
        name: () => '分析域',
    },

    aiNo: {
        name: () => '分析指标编号',
    },

    aiName: {
        name: () => '分析指标名称',
        required: () => true,
    },

    memo: {
        name: () => '备注',
    },

    resume: {
        name: () => '简述',
    },
})