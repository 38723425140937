export const readTreeFromClipboard = async () => {
    try {
        const clipboardText = await navigator.clipboard.readText()

        if (clipboardText) {
            const {map, trees} = JSON.parse(clipboardText)

            if (map && trees) {
                return {map, trees}
            }
        }

        throw new Error('读取剪贴板失败: 未找到节点树')
    }
    catch (err) {
        throw new Error('读取剪贴板失败: 未找到节点树')
    }
}

export function canPasteTreeAfter (node) {
    if (! node.parent) {
        this.logger.error('没有父节点的节点不能在其后粘贴', [node])
        return false
    }

    return true
}

export function canPasteTreeAppend (node) {
    return this.actions.canInsertChild(node)
}

const paste = async (map, nodes, insertChild) => {
    const clipboardData = await readTreeFromClipboard()
    const nodesToSelect = new Set()

    for (const node of nodes) {
        const childNodes = clipboardData.trees.map(map.importTree)

        for (const child of childNodes) {
            insertChild(node, child)
            map.logger.info('已插入节点', [node])
            nodesToSelect.add(child)
        }
    }

    map.selectNodes(nodesToSelect)
}

export async function pasteTreeAfter(nodes) {
    await paste(this, nodes, this.insertSiblingAfter.bind(this))
}

export async function pasteTreeAppend(nodes) {
    await paste(this, nodes, this.appendChild.bind(this))
}
