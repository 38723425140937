import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useArchNode from '../_ARCH/useArchNode.jsx'
import meta from './metaBa.mjs'
import PropertiesPane from './PropertiesPaneBa.jsx'
import api from './apiBa.mjs'

export default () => {
    const ArchNode = useArchNode()

    return extendNode(ArchNode, {
        ...meta,
        api,
        PropertiesPane,

        getStyle(map, node) {
            return this._getStyle(map, node, {
                backgroundColor: Color.LIGHT_YELLOW,
            })
        },

        getText(map, node) {
            const {mapTypeCode, prjTypeCode, subTypeCode} = map.data

            if ('SA_MAP' === mapTypeCode) {
                if ('BODA2' === subTypeCode) {
                    return '能力模型'
                }
                else {
                    return {
                        BAM: '基准模型',
                        TAM: '主题模型',
                    }[prjTypeCode] ?? this.alias
                }
            }
            else {
                return ArchNode.getText.call(this, map, node)
            }
        },

        async onEvent(map, node, event) {
            const n = event.target

            if (
                (
                    n === node &&
                    /^(change|grow|shrink)$/.test(event.type)
                ) ||
                (
                    'BM' === n.data.bizNodeType &&
                    /^(change)$/.test(event.type)
                )
            ) {
                const [catData] = map.queryNodes({
                    algo: 'bfs',
                    data: {bizNodeType: 'CAT_DATA'}
                })

                if (! catData) {
                    return
                }

                const _s = map.nodeProxy(node)
                const {children} = _s.exportTree({compact: true})
                const _d = map.nodeProxy(catData)
                _d.replace({children})
            }
        },

        _pushDataSlots: {
            archBmList: [],
        },

        // TODO
        //async _onChange(map, node, event) {
            //if (event.target === node) {
                //const oldData = event.detail
                //const {lastRev, rev, sVer} = node.data

                //if (
                    //rev !== oldData.rev &&
                    //'SA_MAP' === map.data.mapTypeCode
                //) {
                    //for (const n of map.bfs(map.root)) {
                        //if ('CAT_DATA' === n.data.bizNodeType) {
                            //n.data = {...n.data, lastRev, rev, sVer}
                            //break
                        //}
                    //}
                //}
            //}

            //await ArchNode._onChange.call(this, map, node, event)
        //},

        //async _grow(map, node, depth) {
            //const newDepth = await ArchNode._grow.call(this, map, node)

            //if (newDepth === depth) {
                //return depth
            //}

            //if ('SA_MAP' === map.data.mapTypeCode) {
                //for (const n of map.bfs(map.root)) {
                    //if ('CAT_DATA' === n.data.bizNodeType) {
                        //if (n.firstChild) {
                            //await map.BizNode.CAT_DATA._grow(map, n, depth)
                        //}

                        //break
                    //}
                //}
            //}

            //return newDepth
        //},
    })
}
