import uuid from '@/script/uuid.mjs'
import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaFcJob.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(CAT|MARK|FJ|PS)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'FJ'
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['CAT'],
                ['MARK'],
                ['PS'],
            ]
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['FJ'],
            ]
        },

        async _atPush(map, node, event) {
            const next = chain => {
                const {bizNodeType, pkid} = chain[0].data
                const {classes} = map.BizNode[bizNodeType]

                return {
                    yieldChildren: classes.includes('comment'),
                    yieldNode: ! pkid && 'FJ' === bizNodeType,
                }
            }

            const ownerId = event.target.data.pkid
            const ownerText = map.BizNode.FC.getTextRaw(map, event.target)
            const {ownerIdProp, ownerTextProp} = map.BizNode.FJ

            for (const n of map.dfs(node.children, next)) {
                n.data = {
                    ...n.data,
                    fjCode: uuid(),
                    pkid: uuid(),
                    [ownerIdProp]: ownerId,
                    [ownerTextProp]: ownerText,
                }
            }

            const tree = this.exportTree(map, node, {compact: true})
            event.detail.jobMap = JSON.stringify(tree.children)
        },
    })
}
