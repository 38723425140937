import React, {useEffect, useRef, useState} from 'react';
import {Button, Checkbox, Form, Input, Modal, Select, Space} from 'antd';
import {PlusCircleOutlined, SearchOutlined} from '@ant-design/icons';
import SelectProjectDialog from '@/pages/MainPage/dialogs/SelectProjectDialog';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import {useUser} from '@/biz/user.mjs'
import {useOpenModal} from '@/components/Modal/Modal.jsx';
import SelectMapVerDialog from '@/pages/MainPage/dialogs/SelectMapVerDialog.jsx'
import {getMapRevText} from '@/script/CommonUtils.mjs'
import Permission from '@/components/Permission.jsx';
import {getPrjPatternRevText} from '@/script/CommonUtils.mjs';
import openWindow from '@/script/openWindow.mjs';

const Pane = ({
    icon = <PlusCircleOutlined />,
    onRefresh,
    ...props
}) => {
    const http = useHTTP();
    const user = useUser()
    const openModal = useOpenModal();

    // 预模型标识
    const [preFlag, setPreFlag] = useState(true);

    // 表单
    const [form] = Form.useForm();

    // 数据模型
    const ref = useRef({});
    const [prj, setPrj] = useState({});
    const [saMapVer, setSaMapVer] = useState({});

    // 加载显示
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visiblePrj, setVisiblePrj] = useState(false);

    // 组件初始化
    useEffect(() => {
        if (visible) {
            form.resetFields();
            init();
        }
    }, [visible]);

    const init = () => {
        const prjId = props.prjId ?? user.currPrjId;
        const sVer = props.sVer ?? user.currPrjSver;
        const prjName = props.prjName ?? user.currPrjName;

        setPrj({ prjId, sVer });
        ref.current.prjId = prjId;
        ref.current.sVer = sVer;
        form.setFieldsValue({ prjName, sVer });
    }

    // 新建
    const doBuild = async () => {
        try {
            setLoading(true);
            const formField = await form.validateFields();
            formField.prjId = ref.current.prjId;
            formField.sVer = ref.current.sVer;
            formField.preFlag = preFlag;
            formField.patternMapId = saMapVer.pkid;

            const data = await runAsync(
                async () => {
                    return await http.post(`/prjpatterns/gen`, formField);
                }, {
                error: { content: (err) => `构建失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });
            if (data && data.preExist == true) {
                doReBuildPre(data);
            } else {
                if(props.prjId && props.sVer && data){
                    const {pkid: prjPatternId, patternRev, prjNo, softwareVersion} = data;
                    openWindow('/PrjPatternDetail', {prjPatternId, patternRev, prjNo, softwareVersion});
                }

                setVisible(false);
                onRefresh?.();
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    // 重构: 预成品
    const doReBuildPre = async (prePattern) => {
        Modal.confirm({
            title: '当前已存在模型预成品(模型总图:'+getPrjPatternRevText(prePattern)+')，是否继续重构模型预成品？',
            okText: '确定',
            cancelText: '取消',
            onOk: async () => {
                try {
                    const patternData = await runAsync(
                        async () => {
                            return await http.put(`/prjpatterns/update`, {...prePattern, buildType: 'PRE'})
                        },
                        {
                            error: {content: (err) => `构建失败: ${err.message}`},
                            loading: {show: true},
                            success: {show: true},
                        }
                    );

                    setVisible(false);
                    onRefresh?.();

                    if (patternData) {
                        confirmOpenTab(patternData);
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        });
    }

    const confirmOpenTab = (patternData) => {
        Modal.confirm({
            title: '已开始重构模型预成品！',
            okText: '前往查看模型预成品',
            cancelText: '取消',
            onOk: async () => {
                const {pkid: prjPatternId, patternRev, prjNo, softwareVersion} = patternData;
                openWindow('/PrjPatternDetail', {prjPatternId, patternRev, prjNo, softwareVersion});
            }
        });
    }


    // 回调，项目信息
    const callPrj = (value) => {
        if (value) {
            ref.current.prjId = value.pkid;
            ref.current.sVer = value.sVer;

            setPrj({ prjId: value.pkid, sVer: value.sVer });
            form.setFieldsValue({ prjName: value.prjName, sVer: value.sVer });
        } else {
            ref.current.prjId = null;
            ref.current.sVer = null;

            setPrj({});
            form.setFieldsValue({ prjName: '', sVer: '' });
        }

    }

    // 校验
    const validPattern = async ()=>{
        await runAsync(
            async () => {
                return await http.get(`/prjpatterns/validPattern`, props)
            },
            {
                error: {content: (err) => `${err.message}`},
                loading: {show: false},
                success: {show: false},
            }
        );
        return true
    }

    const buildPattern = async (isPre)=>{
        // 从模型总图来构建预模型
        if(props.prjId && props.sVer){
            const prePattern = await runAsync(
                async () => {
                    return await http.get(`/prjpatterns/onePatternPre`, props)
                },
                {
                    error: {content: (err) => `查询失败: ${err.message}`},
                    loading: {show: true},
                    success: {show: false},
                }
            );

            if(prePattern){
                openWindow('/PrjPatternDetail', {prjPatternId: prePattern.pkid, patternRev: prePattern.patternRev, prjNo: prePattern.prjNo, softwareVersion: prePattern.softwareVersion});
            } else {
                const validFlag = await validPattern()
                console.log("validFlag", validFlag)
                if(validFlag){
                    setPreFlag(isPre)
                    setVisible(true);
                }
            }
        }else {
            setPreFlag(isPre)
            setVisible(true);
        }
    }

    // 回调发布版本
    const callMapVerDialog = async () => {
        const {prjId :_prjId, sVer: _sVer} = prj;
        if (!_prjId || !_sVer) {
            // 请先选择项目
            Modal.info({title: '提示信息', content: '请先选择项目、分支号！'});
            return
        }

        // 获取模型总图
        const saData = await runAsync(
            async () => {
                return await http.get(`/basmaps/samap`, { prjId: _prjId, sVer: _sVer });
            }, {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });

        const saMapVerData = await openModal(
            <SelectMapVerDialog id={saData?.pkid} patternFlag={true}/>
        )

        if (saMapVerData) {
            setSaMapVer({ pkid: saMapVerData.pkid, dataVer: saMapVerData.dataVer, smallVer: saMapVerData.smallVer, verFlag: saMapVerData.verFlag});

            const saMapRev = getMapRevText(saMapVerData);
            form.setFieldsValue({ saMapRev: saMapRev});
        } else {
            setSaMapVer({});
            form.setFieldsValue({ saMapRev: ''});
        }
    }

    return (
        <>
            <Space>
                <Button type="primary" icon={icon} onClick={() => buildPattern(true)}>构建模型预成品</Button>

                {/*<Button type="primary" icon={<PlusCircleOutlined />} onClick={() => buildPattern(false)}>构建模型成品</Button>*/}
            </Space>
            <Modal
                visible={visible}
                title={preFlag?'构建模型预成品':'构建模型成品'}
                okText="确定"
                cancelText="取消"
                onCancel={() => setVisible(false)}
                footer={[
                    <Space>
                        <Permission permission="PRJ_PATTERN_CRT" projects={[prj.prjId]}>
                            <Button onClick={() => setVisible(false)}>取消</Button>
                            <Button type="primary" onClick={doBuild} loading={loading}>确定</Button>
                        </Permission>
                    </Space>
                ]}
            >
                <Form form={form} layout="vertical">
                    <Form.Item name="prjName" label="项目" rules={[{ required: true, message: '请选择项目' }]}>
                        <Input placeholder="请选择项目" addonAfter={<SearchOutlined onClick={() => ! props.prjId && setVisiblePrj(true)} />} disabled={props.prjId} readOnly />
                    </Form.Item>

                    <Form.Item name="sVer" label="分支号" rules={[{ required: true, message: '请输入分支号' }]}>
                        <Input placeholder="请输入分支号" disabled={props.sVer} />
                    </Form.Item>

                    {!preFlag &&
                        <Form.Item name="saMapRev" label="模型总图版本号"
                                   rules={[{required: true, message: '请选择模型总图版本号!'}]}>
                            <Input placeholder="请选择模型总图版本号" addonAfter={<SearchOutlined onClick={callMapVerDialog}/>}
                                   readOnly/>
                        </Form.Item>
                    }

                    <Form.Item name="allowDullFlag" valuePropName="checked" >
                        <Checkbox>允许呆滞件</Checkbox>
                    </Form.Item>

                    {/*<Form.Item name="softwareVersion" label="软件版本号" rules={[{ required: true, message: '请输入软件版本号' }]}>
                        <Input placeholder="请输入软件版本号" />
                    </Form.Item>*/}
                </Form>
            </Modal>

            {/* 选择项目 */}
            <SelectProjectDialog visible={visiblePrj} setVisible={setVisiblePrj} onCall={callPrj} />
        </>
    );
}

export default Pane;
