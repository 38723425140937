import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    ouName: {
        name: () => '组织单元标题',
        required: () => true,
    },

    ouUserCode: {
        name: () => '组织单元代码',
    },

    resume: {
        name: () => '简述',
    },
})
