import {eventBus} from '@/script/eventBus.mjs'

export default () => {
    const watchers = {
        render_end() {
            eventBus.publish(this, 'doc_render_start')
        },

        render_start() {
            eventBus.publish(this, 'doc_render_end')
        },
    }

    return {watchers}
}
