import {useRef} from 'react'
import useSWR from 'swr'

const useFetchKey = () => {
    const refKey = useRef(crypto.randomUUID())
    return refKey.current
}

export default (
    fechter,

    {
        dependencies = [],
        start = true,
        ...options
    } = {},
) => {
    const key = useFetchKey()
    return useSWR(start ? [key, ...dependencies] : null, fechter, options)
}
