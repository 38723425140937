import extendNode from '../extendNode.mjs'
import http from '@/biz/http.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import Color from '../Color.mjs'
import IconLetters from '../icons/IconLetters.jsx'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import api from './apiBdevent.mjs'
import meta from './metaBdevent.mjs'
import Model from './ModelBdevent.mjs'
import PropertiesPane from './PropertiesPaneBdevent.jsx'
import FormChoose from './FormChooseBdevent.jsx'
import TableChoose from './TableChooseBdevent.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        attrNodes: {
            top: ['ATTR_BDEVENT_MEMO'],
        },

        getIcons(map, node) {
            return [
                <IconLetters
                    key="type"
                    fill={Color.LIGHT_YELLOW}
                    letters="E"
                    textColor="#000"
                />
            ]
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.LIGHT_YELLOW}),
                shape: 'EllipseRectangle',
            }
        },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.eventList.push(d)
        },

        async _readGrowTree(map, node) {
            const readSelf = async () => {
                const tree = await ComponentNode._readGrowTree.call(
                    this, map, node
                )

                return {
                    ...tree,

                    children: tree.children.filter(
                        n => {
                            const {bizNodeType} = n.data

                            return (
                                'EVENT_PUBLISHER' !== bizNodeType &&
                                'EVENT_SUBSCRIBER' !== bizNodeType
                            )
                        }
                    )
                }
            }

            const readEvents = async () => {
                const {prjId, rev, eventCode, sVer} = node.data

                const result = await http.get(
                    '/dmbds/event/extend',
                    {prjId, rev, eventCode, sVer}
                )

                return result &&
                    JSON.parse(result).map(parseMap)
            }

            const [tree, events] = await Promise.all([
                readSelf(),
                readEvents(),
            ])

            if (events) {
                for (const n of events.toReversed()) {
                    tree.children.unshift(n)
                }
            }

            return tree
        },

        _getCategories(map, node) {
            return [
                'EVENT_PUBLISHER',
                'EVENT_SUBSCRIBER',
                'EVENT_DESC',
            ]
        },
    })
}
