import extendNode from '../extendNode.mjs'
import useAlgCatNode from '../_ALG_CAT/useAlgCatNode.jsx'
import meta from './metaBdDevelop.mjs'

export default () => {
    const AlgCatNode = useAlgCatNode()

    return extendNode(AlgCatNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(BDALG|CAT)$/.test(type)
        },

        isHidden(map, node) {
            const {bdType} = node.parent?.data ?? {}

            return (
                '2' === bdType ||
                AlgCatNode.isHidden.call(this, map, node)
            )
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['CAT'],
            ]
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['BDALG'],
            ]
        },

        _getDefaultDataFields(map, node) {
            return {
                ...AlgCatNode._getDefaultDataFields.call(this, map, node),
                algType: 'DEVELOP',
            }
        },
    })
}
