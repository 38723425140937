export function canUnhideNode(node) {
    return node.parent && node.isHidden
}

export function unhideNode(nodes) {
    const nodesToSelect = new Set(nodes)

    for (const node of nodes) {
        node.isHidden = false
    }

    for (const node of nodes) {
        for (const n of this.chain(node.parent)) {
            if (this.isNodeHidden(n)) {
                nodesToSelect.delete(node)
                break
            }
        }
    }

    this.selectNodes(nodesToSelect)
}
