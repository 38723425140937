import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import meta from './metaDes.mjs'
import Model from './ModelDes.mjs'
import PropertiesPane from './PropertiesPaneDes.jsx'
import api from './apiDes.mjs'
import useContextMenu from './useContextMenuDes.mjs'

export default () => {
    const ProductNode = useProductNode()
    const contextMenu = useContextMenu()

    return extendNode(ProductNode, {
        ...meta,
        api,
        contextMenu,
        Model,
        PropertiesPane,

        getStyle(map, node) {
            return {
                ...ProductNode.getStyle.call(this, map, node),
                backgroundColor: Color.LIGHT_GREY,
            }
        },

        _getCategories(map, node) {
            return [
                'DES_TASK',
                'DES_DES',
                'DES_OTH',
            ]
        },
    })
}
