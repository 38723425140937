import superMeta from '../_ARCH/metaArchNode.mjs'

export default {
    ...superMeta,
    alias: '组织模型',
    archUrl: '/ProjectOrgMap',
    detailUrl: '/OaDetail',
    detailIdProp: 'oaId',
    mapProp: 'oaMap',
    moduleType: 'OM',
    name: '组织结构',
    type: 'OA',
}
