import Table from '@/components/Table/Table.jsx'
import TableChooseProduct from '../_PRODUCT/TableChooseExternalProduct.jsx'

const TableChooseDt = (props) => {
    const columns = [
        {
            title: '数据库代码',
            dataIndex: 'dbUserCode',
            component: <Table.ViewText />,
        },

        {
            title: '数据库名称',
            dataIndex: 'dbName',
            component: <Table.ViewText />,
        },

        {
            title: '表代码',
            dataIndex: 'dtUserCode',
            component: <Table.ViewText />,
        },

        {
            title: '表名称',
            dataIndex: 'dtName',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseProduct
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseDt
