import Table from '@/components/Table/Table.jsx'
import TableChooseComponent from '../_COMPONENT/TableChooseComponent.jsx'
import UvType from './UvType.mjs'

const TableChooseUv = (props) => {
    const columns = [
        {
            title: '界面代码',
            dataIndex: 'uiUserCode',
            component: <Table.ViewText />,
        },

        {
            title: '界面名称',
            dataIndex: 'uiName',
            component: <Table.ViewText />,
        },

        {
            title: '界面视图代码',
            dataIndex: 'uvUserCode',
            component: <Table.ViewText />,
        },

        {
            title: '界面视图名称',
            dataIndex: 'uvName',
            component: <Table.ViewText />,
        },

        {
            title: '界面视图类型',
            dataIndex: 'uvType',
            component: <Table.ViewDict dict={Object.fromEntries(UvType)} />,
        },

    ]

    return (
        <TableChooseComponent
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseUv
