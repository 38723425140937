import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useBaseNode from '../_BASE/useBaseNode.jsx'
import meta from './metaCategoryNode.mjs'

/**
 * 预置分类节点
 */
export default () => {
    const BaseNode = useBaseNode()

    return extendNode(BaseNode, {
        ...meta,

        canDeleteTree() {
            return false
        },

        canMove() {
            return false
        },

        canWriteData() {
            return false
        },

        getRev(map, node) {
            return null
        },

        getStyle(map, node) {
            return {
                ...BaseNode.getStyle.call(this, map, node),
                backgroundColor: Color.LIGHT_GREY,
            }
        },

        async _atAttach(map, node, event) {
            if (event.target === node) {
                for (const n of node.parent.children) {
                    if (
                        n !== node &&
                        this.type === n.data.bizNodeType
                    ) {
                        map.unlinkTree(node)
                        map.insertSiblingAfter(n, node)
                        map.deleteTree(n)

                        map.logger.warn(
                            '已存在相同类型的固定节点，替换原有节点',
                            [node]
                        )
                    }
                }
            }

            await BaseNode._atAttach.call(this, map, node, event)
        },

        async _getPushData(map, node) {
            const tree = this.exportTree(map, node, {compact: true})
            const mapJson = JSON.stringify(tree.children)

            return {
                ...tree.data,
                [this.mapProp]: mapJson,
            }
        },
    })
}
