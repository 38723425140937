import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    amUserCode: {
        name: () => '分析域代码',
    },

    amName: {
        name: () => '分析域名称',
        required: () => true,
    },
})
