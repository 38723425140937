import extendNode from '../extendNode.mjs'
import useDesignNode from '../_DESIGN/useDesignNode.jsx'
import meta from './metaAbstractNode.mjs'

export default () => {
    const DesignNode = useDesignNode()

    return extendNode(DesignNode, {
        ...meta,

        async choose(map, node) {
            const items = await this._choose(map, node)
            const bizNodeType = this.type.replace(/_.*/, '')

            return items.map(e => ({
                ...e,
                data: {...e.data, bizNodeType},
            }))
        },
    })
}
