import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    dbInsName: {
        name: () => '数据库实例名称',
        required: () => true,
    },

    dbInsDesc: {
        name: () => '数据库实例描述',
    },

    dbInsMemo: {
        name: () => '数据库实例备注',
    },

    dbDefault: {
        name: () => '默认数据库实例',
    },

    dbInsVersion: {
        name: () => '数据库版本',
    },

    dbInsSchema: {
        name: () => '数据库实例(真实数据库实例名称)',
        required: () => true,
    },

    dbInsUserName: {
        name: () => '数据库账号',
        required: () => true,
    },

    dbInsUserPassword: {
        name: () => '数据库密码',
        required: () => true,
    },

    url: {
        name: () => 'JDBC URL',
        required: () => true,
    },

    dbInsIntranetIp: {
        name: () => 'IP',
        required: () => true,
    },

    dbInsInternetIp: {
        name: () => '公网IP',
    },

    dbInsPort: {
        name: () => '端口',
        required: () => true,
    },

    dbInsStatus: {
        name: () => '运行状态',
    },

    dbInsBu: {
        name: () => '归属BU',
    },

    dbInsDm: {
        name: () => '开发负责人',
    },

    dbInsOm: {
        name: () => '运维负责人',
    },

    dbInsEnv: {
        name: () => '环境',
    },
})
