import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useAlgNode from '../_ALG/useAlgNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaBfdataflow.mjs'
import PropertiesPane from './PropertiesPaneBfdataflow.jsx'
import FormChoose from './FormChooseBfdataflow.jsx'
import TableChoose from './TableChooseBfdataflow.jsx'
import api from './apiBfdataflow.mjs'

export default () => {
    const AlgNode = useAlgNode()

    return extendNode(AlgNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        PropertiesPane,

        defaultChildType(map, node) {
            return 'AR'
        },

        getIcons(map, node) {
            return [
                <IconLetters
                    key="type"
                    fill={Color.LIGHT_YELLOW}
                    letters="SS"
                    textColor="#000"
                />
            ]
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.LIGHT_YELLOW}),
                shape: 'EllipseRectangle',
            }
        },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.algList.push(d)
        },

        _pushDataSlots: {
            fcCode: '',
            sfCodes: [],
        },
    })
}
