import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelArrd.mjs'

export default function PropertiesPaneDe(props) {
    return (
        <ComponentPropertiesPane
            controllers={
                <>
                    <Text prop="rdName" />
                    <Text prop="rdUserCode" />
                    <LongText prop="resume" />
                    <Text prop="rdTitle" />
                </>
            }

            Model={Model}

            snSuffix={
                <Analyse
                    modes={[
                        'ChgReference',
                        'Depend',
                        'Reference',
                        'ReferenceCall',
                    ]}
                />
            }

            {...props}
        />
    )
}
