import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import ButtonExportModel from '../components/PropertiesPaneButtonExportModel.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelBdsummary.mjs'
import StoryType from './StoryType.jsx'

const PropertiesPaneSummary = (props) => (
    <ComponentPropertiesPane
        buttons={<ButtonExportModel type="bdsummary" />}

        controllers={
            <>
                <Text prop="algTitle" />

                <Select
                    options={StoryType}
                    prop="storyType"
                />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'ChgReference',
                    'Depend',
                    'Effect',
                    'Reference',
                    'ReferenceCall',
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneSummary
