import React, { useEffect, useMemo, useState } from 'react'
import { createForm, createEffectHook } from '@formily/core'
import { FormProvider, createSchemaField } from '@formily/react'
import {
  Form,
  FormItem,
  DatePicker,
  Cascader,
  Editable,
  Input,
  NumberPicker,
  Password,
  PreviewText,
  Radio,
  Reset,
  Select,
  Space,
  Submit,
  TimePicker,
  TreeSelect,
  Upload,
  FormGrid,
  FormLayout,
  FormTab,
  FormCollapse,
  ArrayTable,
  ArrayCards,
} from '@formily/antd';
import { Card, Slider, Rate as AntdRate, Modal, Button as AntdButton, message, ConfigProvider } from 'antd'
import { Image, Menu, LayoutGrid as Grid, LayoutRowGrid as RowGrid, Tag, Tree, Divider, Alert, Steps, Icon } from '../src/components'
import { Box } from '../../FormilyMobile/src/components/preivew';
import { Affix, Button, Breadcrumb, Progress, ProTable, Checkbox, Transfer, Switch, DialogPreview as Dialog } from '../src/components/preview'
// import { ProTable } from '../src/components/ProTable'
import { TreeNode } from '@designable/core'
import { transformToSchema } from '@designable/formily-transformer'
//@ts-ignore
import {useOpenModal} from '@/components/Modal/Modal.jsx'

import DynamicModal from './previewModal'

import './preview.less'

const Rate = (props) => {
  const style = props.style || {};
  return <AntdRate {...props} style={{ position: 'relative', top: '-2px', ...style }} />
}

interface modalPropsType {
  title?: string
  cancelBtnProps?: any
  okBtnProps?: any
  width?: string
  showFoot?: boolean
  modalType?: string
  modalContent?: string
}

const Text: React.FC<{
  value?: string
  content?: string
  mode?: 'normal' | 'h1' | 'h2' | 'h3' | 'p'
}> = ({ value, mode, content, ...props }) => {
  const tagName = mode === 'normal' || !mode ? 'div' : mode
  return React.createElement(tagName, props, value || content)
}

export const SchemaField = createSchemaField({
  components: {
    Space,
    FormGrid,
    FormLayout,
    FormTab,
    FormCollapse,
    ArrayTable,
    ArrayCards,
    FormItem,
    DatePicker,
    Checkbox,
    Cascader,
    Editable,
    Input,
    Text,
    NumberPicker,
    Switch,
    Password,
    PreviewText,
    Radio,
    Reset,
    Select,
    Submit,
    TimePicker,
    Transfer,
    TreeSelect,
    Upload,
    Card,
    Slider,
    Rate,
    Button,
    ProTable,
    Image,
    Menu,
    Grid,
    RowGrid,
    Tag,
    Tree,
    Divider,
    Affix,
    Alert,
    Breadcrumb,
    Steps,
    Icon,
    Progress,
    Box,
    Dialog
  },
  scope: []
})

export interface IPreviewWidgetProps {
  tree: TreeNode
  handleClick: () => void
  initParms: any
  handleCtrlClick?: (param: any) => void
  uvMode?: number
}

const onOpenModalEvent = createEffectHook(
  'open-modal',
  (payload, form) => (listener) => {
    listener(payload, form)
  }
)

const onResetEvent = createEffectHook(
  'reset-form',
  (payload, form) => (listener) => {
    listener(payload, form)
  }
)

const onSearchEvent = createEffectHook(
  'search-btn',
  (payload, form) => (listener) => {
    listener(payload, form)
  }
)

const onCtrlClick = createEffectHook(
  'ctrl-click',
  (payload, form) => (listener) => {
    listener(payload, form)
  }
)



export const PreviewWidget: React.FC<IPreviewWidgetProps> = (props) => {
  const { uvMode = 2 } = props;
  const [currentModalId, setCurrentModalId] = useState(undefined)
  const openModal = useOpenModal();
  const form = useMemo(() => createForm({
    effects(){
      onOpenModalEvent(async(payload, $form) => {
        const { modalDetailProps, ...otherProps} = payload;
        let modalId = modalDetailProps?.modalPage?.pageId || '';
        if (modalDetailProps.modalType == 'dialogModal') return document.getElementById(modalDetailProps.dialogId).click()
        if (!modalId && modalDetailProps.modalType == 'customPage') return message.warning('没有配置需要展示的modalId')
        if (modalDetailProps.modalType == 'customPage') {
          setCurrentModalId(modalId);
        }
        openModal(<DynamicModal form={form} uvMode={uvMode} modalDetail={modalDetailProps} modalId={modalId} otherProps={otherProps} {...props} />)
      }),
      onResetEvent((payload, $form) => {

      }),
      onCtrlClick((payload, $form) => {
        props.handleCtrlClick && props.handleCtrlClick(payload)
      }),
      onSearchEvent((payload, $form) => {
        const arr = payload.searchArr || [];
        
        let datas = []
        $form.getFieldState('table', (state) => {
          datas = state.componentProps.tableProps.all ||state.componentProps.tableProps.dataSource || [];
        })
        const vals = arr.map(item => {
          return {
            key: item.split('.').pop(),
            val: $form.query(item).value()
          }
        }).filter(item => !!item.val);
        if (vals && vals.length > 0) {
          let newDatas = datas.filter(item => {
            let flag = true;
            vals.forEach(k => {
              if (`${item[k.key]}`?.indexOf(`${k.val}`) == -1) {
                flag = false;
              }
            })
            return flag;
          })
          $form.setFieldState('table', (state) => {
            state.componentProps.tableProps.all = datas;
            state.componentProps.tableProps.dataSource = newDatas;
          })

        } else {
          $form.setFieldState('table', (state) => {
            state.componentProps.tableProps.all = datas;
            state.componentProps.tableProps.dataSource = datas;
          })
        }
      })
    },
    initialValues: props.initParms || {}
  }), [])

  const { form: formProps={}, schema } = transformToSchema(props.tree)
  const { style={}, theme } = formProps;

  ConfigProvider.config({
    prefixCls: 'custom',
    theme: formProps.theme
  })

  return (
    <>
      <div className='dn-formily-pc' style={{...style}}>
      <ConfigProvider prefixCls='custom'>
        <FormProvider form={form}>
          <FormLayout labelCol={6} wrapperCol={12}>
            <SchemaField schema={schema} />
          </FormLayout>
        </FormProvider>
      </ConfigProvider>
      </div>
    </>
  )
}
