import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelOpr.mjs'
import OprType from './PropertiesControllerOprType.jsx'

const PropertiesPaneOpr = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <LongText prop="oprTitle" />
                <OprType prop="oprType" />
            </>
        }

        Model={Model}
        noSn

        snSuffix={
            <Analyse
                modes={[
                    'ChgReference',
                    'Reference',
                    'ReferenceCall',
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneOpr
