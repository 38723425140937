import {useEffect, useState} from 'react'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {subscribe, unsubscribe} from '@/script/event.mjs'

const getItems = map => {
    const items = []

    const walkArs = node => {
        const referred = (() => {
            const {bizNodeType, pkid, rev, sVer} = node.data
            const _node = map.nodeProxy(node)
            const text = _node.getText()
            const ver = `${sVer}.${rev}`
            return {bizNodeType, pkid, rev, text, ver}
        })()

        for (const n of node.children) {
            const _n = map.nodeProxy(n.firstChild)
            const {arName: ver} = n.data
            const rev = Number(ver.replace(/^\d+\.(\d+)$/g, '$1'))

            if (rev < referred.rev && ! _n.isExternal()) {
                const {bizNodeType, pkid, rev, stsCode} = _n.data
                const text = _n.getText()
                const referer = {bizNodeType, pkid, rev, stsCode, text, ver}

                items.push({
                    key: _n.id,
                    referer,
                    referred,
                    verNodeId: n.id,
                })
            }

            walkRefs(n)
        }
    }

    const walkRefs = node => {
        for (const n of node.children) {
            walkArs(n)
        }
    }

    walkArs(map.root)
    return items
}

export default () => {
    const map = useMapContext()
    const [items, setItems] = useState(() => getItems(map))

    useEffect(
        () => {
            const handleModelChange = () => {
                map.logger.withOff(
                    () => setItems(getItems(map))
                )
            }

            subscribe(map, 'model_change', handleModelChange)

            return () => {
                unsubscribe(map, 'model_change', handleModelChange)
            }
        },

        []
    )

    return items
}
