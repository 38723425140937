import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'deUserCode',
    mapProp: 'deMap',
    name: '维度实体',
    ownerIdProp: 'doId',
    ownerTextProp: 'doName',
    ownerType: 'DO',
    textProp: 'deTitle',
    type: 'DE',
}
