import React, {useState} from 'react'
import { Select, Button, Tag } from 'antd'
const  colorOptions = ['#c23531','#2f4554', '#61a0a8', '#d48265', '#91c7ae','#749f83',  '#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3'];

const { Option } = Select;
export default ( props: any) => {
  return (
    <Select
      style={{ width: '100%' }}
      placeholder="请选择"
      {...props}
    >
      {
        colorOptions.map(item => {
          return <Option key={item} value={item} style={{color: item}}><Tag color={item}>{item}</Tag></Option>
        })
      }
    </Select>
  )
}