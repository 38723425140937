/* eslint-disable react/jsx-key */

import Color from '../Color.mjs'
import {IconArrowRight} from '../icons/IconArrow.jsx'
import IconAvatar from '../icons/IconAvatar.jsx'
import IconCircle from '../icons/IconCircle.jsx'
import IconConnect from '../icons/IconConnect.jsx'
import IconFile from '../icons/IconFile.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import IconLightning from '../icons/icon-lightning.svg?react'
import IconPrinter from '../icons/IconPrinter.jsx'
import IconRedo from '../icons/IconRedo.jsx'
import IconStar from '../icons/IconStar.jsx'
import IconQuote from '../icons/icon-quote.svg?react'
import IconTriangle from '../icons/IconTriangle.jsx'
import IconManyToMany from './icons/icon-many-to-many.svg?react'
import IconManyToOne from './icons/icon-many-to-one.svg?react'
import IconOneToMany from './icons/icon-one-to-many.svg?react'
import IconOneToOne from './icons/icon-one-to-one.svg?react'
import IconAsyncExec from './icons/IconAsyncExec.jsx'
import {IconReadQueue, IconWriteQueue} from './icons/IconQueue.jsx'
import IconCase from './icons/icon-case.svg?react'
import IconLoop from './icons/icon-loop.svg?react'
import IconDcCheck from './icons/icon-dc-check.svg?react'
import IconDcDate from './icons/icon-dc-date.svg?react'
import IconDcEdit from './icons/icon-dc-edit.svg?react'
import IconDcEditM from './icons/icon-dc-edit-m.svg?react'
import IconDcEvent from './icons/icon-dc-event.svg?react'
import IconDcHidden from './icons/icon-dc-hidden.svg?react'
import IconDcList from './icons/icon-dc-list.svg?react'
import IconDcPic from './icons/icon-dc-pic.svg?react'
import IconDcRadio from './icons/icon-dc-radio.svg?react'
import IconDcRead from './icons/icon-dc-read.svg?react'
import IconDcSelect from './icons/icon-dc-select.svg?react'
import IconDcSelEdit from './icons/icon-dc-sel-edit.svg?react'
import IconDisable from './icons/icon-disable.svg?react'
import IconEnable from './icons/icon-enable.svg?react'
import IconGoto from './icons/icon-goto.svg?react'
import IconHide from './icons/icon-hide.svg?react'
import IconCreateBo from './icons/icon-create-bo.svg?react'
import IconDeleteBo from './icons/icon-delete-bo.svg?react'
import IconPublish from './icons/icon-publish.svg?react'
import IconSearch from './icons/icon-search.svg?react'
import IconShow from './icons/icon-show.svg?react'
import IconSubscribe from './icons/icon-subscribe.svg?react'
import IconUpdateBo from './icons/icon-update-bo.svg?react'
import IconZoom from './icons/icon-zoom.svg?react'
import IconButton from '../_OPR/icon-fopr-button.svg?react'
import IconCheck from '../_OPR/icon-fopr-check.svg?react'
import IconInput from '../_OPR/icon-fopr-input.svg?react'
import IconLink from '../_OPR/icon-fopr-link.svg?react'
import IconMenu from '../_OPR/icon-fopr-menu.svg?react'
import IconTab from '../_OPR/icon-fopr-tab.svg?react'

export default [
    [
        [
            'GENERAL',
            '通用描述',
        ],

        [
            'CAT',
            '分类',

            <IconArrowRight
                fill={Color.ORANGE}
                color="#fff"
            />
        ],

        [
            'MARK',
            '标注',
            <IconQuote />
        ],

        [
            'BEGIN',
            '开始',
            <IconCircle fill={Color.GREEN} />
        ],

        [
            'END',
            '结束',
            <IconCircle fill={Color.RED} />
        ],

        [
            'CONNECT',
            '连接',
            <IconConnect fill={Color.BLUE} />
        ],

        [
            'EXEC',
            '执行',

            <IconTriangle
                fill={Color.GREEN}
                rotate={90}
            />
        ],

        [
            'ASYNC_EXEC',
            '异步执行',
            <IconAsyncExec fill={Color.GREEN} />
        ],

        [
            'PUBLISH_EVENT',
            '发布事件',
            <IconPublish />
        ],

        [
            'SUBSCRIBE_EVENT',
            '订阅事件',
            <IconSubscribe />
        ],

        [
            'VERIFY',
            '校验',

            <IconLetters
                fill="#f88634"
                letters="!"
                textColor="#fff"
            />
        ],

        [
            'DF',
            '字段',

            <IconLetters
                fill={Color.DARK_CYAN}
                letters="DF"
                textColor="#666"
            />
        ],

        [
            'USER',
            '用户场景简述',
            <IconAvatar color={Color.BLUE} />
        ],

        [
            'EXECUTOR',
            '执行者',
            <IconAvatar color={Color.GREEN} />
        ],
    ],

    [
        [
            'OD',
            '1份元素',

            <IconLetters
                fill={Color.DARK_CYAN}
                letters=":1"
                textColor="#fff"
            />
        ],

        [
            'MD',
            'N份元素',

            <IconLetters
                fill={Color.DARK_CYAN}
                letters=":N"
                textColor="#fff"
            />
        ],

        [
            'OE',
            '1份实体',

            <IconLetters
                fill={Color.DARK_CYAN}
                letters=":1"
                textColor="#666"
            />
        ],

        [
            'ME',
            'N份实体',

            <IconLetters
                fill={Color.DARK_CYAN}
                letters=":N"
                textColor="#666"
            />
        ],

        [
            'OTO',
            '一对一',

            <IconOneToOne
                color="#fff"
                fill={Color.DARK_CYAN}
            />
        ],

        [
            'OTM',
            '一对多',

            <IconOneToMany
                color="#fff"
                fill={Color.DARK_CYAN}
            />
        ],

        [
            'MTO',
            '多对一',

            <IconManyToOne
                color="#fff"
                fill={Color.DARK_CYAN}
            />
        ],

        [
            'MTM',
            '多对多',

            <IconManyToMany
                color="#fff"
                fill={Color.DARK_CYAN}
            />
        ],
    ],

    [
        [
            'BO_C',
            '新建数据 ',
            <IconCreateBo />
        ],

        [
            'QUERY_DF',
            '读取数据',
            <IconSearch />
        ],

        [
            'BO_U',
            '更新数据',
            <IconUpdateBo />
        ],

        [
            'BO_D',
            '删除数据',
            <IconDeleteBo />
        ],

        [
            'DB_C',
            '新建实体',

            <IconLetters
                fill={Color.GREEN}
                letters="C"
                textColor="#fff"
            />
        ],

        [
            'DB_R',
            '读取实体',

            <IconLetters
                fill={Color.DARK_CYAN}
                letters="R"
                textColor="#fff"
            />
        ],

        [
            'DB_U',
            '更新实体',

            <IconLetters
                fill={Color.DARK_CYAN}
                letters="U"
                textColor="#fff"
            />
        ],

        [
            'DB_D',
            '删除实体',

            <IconLetters
                fill={Color.RED}
                letters="D"
                textColor="#fff"
            />
        ],

        [
            'DB_LD',
            '逻辑删除',

            <IconLetters
                fill={Color.RED}
                letters="U"
                textColor="#fff"
                textDecoration="line-through"
            />
        ],

        [
            'SET_DF',
            '字段赋值',
            <IconStar fill={Color.DARK_CYAN} />
        ],

        [
            'WRITE_QUEUE',
            '写入队列',
            <IconWriteQueue fill={Color.DARK_CYAN} />
        ],

        [
            'READ_QUEUE',
            '读取队列',
            <IconReadQueue fill={Color.DARK_CYAN} />
        ],
    ],

    [
        [
            'VAR',
            '声明变量',

            <IconLetters
                letters="V"
                textColor={Color.GREEN}
            />
        ],

        [
            'OPT',
            '选项',

            <IconLetters
                letters="OP"
                textColor={Color.GREEN}
            />
        ],

        [
            'DEF_VAL',
            '缺省值',

            <IconLetters
                letters="DV"
                textColor={Color.GREEN}
            />
        ],

        [
            'SET_VAR',
            '变量赋值',

            <IconTriangle
                fill={Color.GREEN}
                rotate={180}
            />
        ],

        [
            'GET',
            '取值',

            <IconLetters
                letters="="
                textColor={Color.GREEN}
            />
        ],

        [
            'GET_DC',
            '取控件值',

            <IconLetters
                letters="=C"
                textColor={Color.GREEN}
            />
        ],

        [
            'CASE',
            '分支',
            <IconCase />
        ],

        [
            'LOOP',
            '循环',
            <IconLoop />
        ],

        [
            'GOTO',
            '跳转',
            <IconGoto />
        ],
    ],

    [
        [
            'ALG',
            '调用能力',

            <IconLetters
                fill={Color.GREEN}
                letters="P"
                textColor="#fff"
            />
        ],

        [
            'LSI',
            '调用接口',

            <IconLetters
                fill={Color.PURPLE}
                letters="LI"
                textColor="#fff"
            />
        ],

        [
            'SET_IP',
            '入参赋值',

            <IconTriangle
                fill={Color.GREEN}
                rotate={0}
            />
        ],

        [
            'GET_OP',
            '取出参值',

            <IconLetters
                letters="=P"
                textColor={Color.GREEN}
            />
        ],
    ],

    [
        [
            'UI',
            '打开界面',
            <IconZoom />
        ],

        [
            'OUT',
            '界面输出',
            <IconStar fill={Color.BLUE} />
        ],

        [
            'CLOSE',
            '关闭界面',

            <IconLetters
                fill={Color.BLUE}
                letters="X"
                textColor="#fff"
            />
        ],

        [
            'PRINT',
            '打印',

            <IconPrinter
                fill={Color.BLUE}
                theme="light"
            />
        ],

        [
            'EXPORT',
            '导出文件',

            <IconFile
                fill={Color.BLUE}
                theme="light"
            />
        ],
    ],

    [
        [
            'DC',
            '通用控件',

            <IconLetters
                fill={Color.BLUE}
                letters="DC"
                textColor="#fff"
            />
        ],

        [
            'DC_M_MODE',
            '多模控件',

            <IconLetters
                fill={Color.BLUE}
                letters="VC"
                textColor="#fff"
            />
        ],

        [
            'DC_HIDDEN',
            '隐式控件',
            <IconDcHidden />
        ],

        [
            'DC_READ',
            '只读框',
            <IconDcRead />
        ],

        [
            'DC_EDIT',
            '录入框',
            <IconDcEdit />
        ],

        [
            'DC_EDIT_M',
            '多行录入框',
            <IconDcEditM />
        ],

        [
            'DC_SELECT',
            '选择框',
            <IconDcSelect />
        ],

        [
            'DC_SEL_EDIT',
            '可选可录框',
            <IconDcSelEdit />
        ],

        [
            'DC_CHECK',
            '勾选框',
            <IconDcCheck />
        ],

        [
            'DC_RADIO',
            '单选框',
            <IconDcRadio />
        ],

        [
            'DC_DATE',
            '日期框',
            <IconDcDate />
        ],

        [
            'DC_PIC',
            '图片框',
            <IconDcPic />
        ],

        [
            'DC_LIST',
            '列表',
            <IconDcList />
        ],

        [
            'DC_EVENT',
            '控件事件',
            <IconDcEvent />
        ],

        [
            'DISABLE',
            '禁用控件',
            <IconDisable />,
        ],

        [
            'ENABLE',
            '启用控件',
            <IconEnable />,
        ],

        [
            'HIDE',
            '隐藏控件',
            <IconHide />,
        ],

        [
            'SHOW',
            '显示控件',
            <IconShow />,
        ],

        [
            'REFRESH',
            '刷新控件',
            <IconRedo fill={Color.BLUE} />
        ],
    ],

    [
        [
            'CASE_DESC',
            '用例描述',
            <IconAvatar color={Color.GOLD} />
        ],

        [
            'OPR_GENERAL',
            '通用操作',
            <IconLetters fill={Color.LIGHT_ORANGE} />
        ],

        [
            'FRES',
            '系统响应',
            <IconLightning fill={Color.BLUE} />
        ],

        [
            'OPR_MENU',
            '点击菜单',
            <IconMenu fill={Color.LIGHT_ORANGE} />
        ],

        [
            'OPR_BUTTON',
            '点击按钮',
            <IconButton fill={Color.LIGHT_ORANGE} />
        ],

        [
            'OPR_CLICK_URL',
            '点击链接',
            <IconLink fill={Color.LIGHT_ORANGE} />
        ],

        [
            'OPR_CLICK_TAB',
            '点击页签',
            <IconTab fill={Color.LIGHT_ORANGE} />
        ],

        [
            'OPR_SHORTCUT',
            '快捷键',

            <IconLetters
                textColor={Color.LIGHT_ORANGE}
                letters="K"
            />
        ],

        [
            'OPR_CHECK_ROW',
            '勾选行记录',
            <IconCheck fill={Color.LIGHT_ORANGE} />
        ],

        [
            'OPR_INPUT',
            '录入数据',
            <IconInput fill={Color.LIGHT_ORANGE} />
        ],
    ],
]
