import {useOpenModal} from '@/components/Modal/Modal.jsx'
import {MapContext} from '@/components/MindMap/index.mjs'
import {NodePropModel} from '../../bizNodeModel.mjs'
import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import {ValidationError} from '../Error.mjs'
import useBaseNode from '../_BASE/useBaseNode.jsx'
import ModalChooseDesignItems from '../components/ModalChooseDesignItems.jsx'
import meta from './metaDesignNode.mjs'

/**
 * 设计节点类型的基类
 */
export default () => {
    const openModal = useOpenModal()
    const BaseNode = useBaseNode()

    return extendNode(BaseNode, {
        ...meta,
        api: {},
        attrNodes: null,

        get read() {
            const {read} = this.api

            if (! read) {
                return void 0
            }

            return async args => {
                const {
                    [this.mapProp]: _,
                    ...nodeData
                } = await read(args)

                return {
                    ...nodeData,
                    bizNodeType: this.type,
                }
            }
        },

        get readList() {
            const {readList} = this.api

            if (! readList) {
                return void 0
            }

            return async args => {
                const items = await readList({
                    stsCodes: 'RLS,REVISE',
                    ...args,
                })

                return items.map(
                    ({
                        [this.mapProp]: _,
                        ...nodeData
                    }) => ({
                        ...nodeData,
                        bizNodeType: this.type,
                    })
                )
            }
        },

        canGrow(map, node) {
            if (! BaseNode.canGrow.call(this, map, node)) {
                return false
            }

            if (! node.parent) {
                map.logger.error('根节点不能加载/卸载', [node])
                return false
            }

            if (! node.data.pkid) {
                map.logger.error('概念节点不能加载/卸载', [node])
                return false
            }

            return true
        },

        /**
         * 能否改变版本
         */
        canChangeVersion(map, node) {
            const rev = this.getRev(map, node)

            if (null === rev) {
                map.logger.error('模件没有版本化', [node])
                return false
            }

            const {delFlag} = node.data

            if ('1' === delFlag) {
                map.logger.error('模件已被删除', [node])
                return false
            }

            return true
        },

        /**
         * 能否升级
         */
        canUpgrade(map, node) {
            if (! this.canChangeVersion(map, node)) {
                return false
            }

            if (! this.isOutdated(map, node)) {
                map.logger.error('模件没有过时，无需升级', [node])
                return false
            }

            return true
        },

        castTo(map, node, bizNodeType) {
            BaseNode.castTo.call(this, map, node, bizNodeType)

            const {
                pkid: _,
                [this.codeProp]: code,
                ...data
            } = node.data

            const {codeProp} = map.BizNode[bizNodeType]
            node.data = {...data, [codeProp]: code}
        },

        async changeVersion(map, node, rev) {
            const {data} = node
            node.data = {...data, rev}
            const {firstChild} = node
            await this.grow(map, node)

            if (! firstChild) {
                map.deleteChildren(node)
            }

            await this.emitEventUp(map, node, {detail: data, type: 'change'})
        },

        async choose(map, parent) {
            return this._choose(map, parent)
        },

        clean(map, node) {
            BaseNode.clean.call(this, map, node)

            if (! node.data.pkid) {
                const {
                    lastRev,
                    rev,
                    sVer,
                    ...data
                } = node.data

                node.data = data
            }
        },

        getDesc(map, node) {
            return this.name
        },

        getText(map, node) {
            if (this.codeProp && ! node.data.pkid) {
                const parts = []
                const text = node.data[this.textProp]

                if (text) {
                    parts.push(text)
                }

                const code = node.data[this.codeProp]

                if (code) {
                    parts.push(code)
                }

                return parts.join(' #')
            }
            else {
                return BaseNode.getText.call(this, map, node)
            }
        },

        isExternal(map, node) {
            const {pkid, prjId} = node.data
            const {prjId: mPrjId} = map.data

            return Boolean(
                pkid &&
                prjId &&
                mPrjId &&
                prjId !== mPrjId
            )
        },

        isLinked(map, node) {
            return (
                // 概念节点不能链接
                node.data.pkid &&
                BaseNode.isLinked.call(this, map, node)
            )
        },

        mapReplaceTextData(map, node, replace) {
            const data = BaseNode.mapReplaceTextData.call(
                this, map, node, replace
            )

            if (this.codeProp) {
                const {
                    pkid,
                    [this.codeProp]: code = '',
                } = node.data

                if (pkid) {
                    data[this.codeProp] = replace(code)
                }
            }

            return data
        },

        mapSetTextData(map, node, text) {
            if (node.data.pkid || ! this.codeProp) {
                return BaseNode.mapSetTextData.call(this, map, node, text)
            }

            const words = text.split(' ')

            const code = (() => {
                const lastWord = words.at(-1)

                if (lastWord?.startsWith('#')) {
                    return lastWord.replace(/^#/, '')
                }
            })()

            if (code) {
                const text = words.slice(0, -1).join(' ')

                return {
                    [this.codeProp]: code,
                    [this.textProp]: text,
                }
            }
            else {
                return {
                    [this.codeProp]: '',
                    [this.textProp]: text,
                }
            }
        },

        async onInsertConcept(map, nodes) {
            await map.commands.insertBizNode(nodes, this.type)
        },

        async onInsertProduct(map, nodes) {
            await map.commands.insertProduct(nodes, this.type)
        },

        async onPull(map, node) {
            await BaseNode.onPull.call(this, map, node)
            node.isFolded = Boolean(node.parent)
        },

        async shrink(map, node) {
            if (this.canShrink(map, node)) {
                map.deleteChildren(node)
                await this.emitEventUp(map, node, {type: 'shrink'})
            }
        },

        async upgrade(map, node) {
            if (this.isOutdated(map, node)) {
                await this.changeVersion(map, node, node.data.lastRev)
            }
        },

        async _atAttach(map, node, event) {
            if (event.target === node) {
                if (this.isLinked(map, node)) {
                    map.deleteChildren(node)
                }
            }

            await BaseNode._atAttach.call(this, map, node, event)
        },

        async _atImport(map, node, event) {
            await BaseNode._atImport.call(this, map, node, event)

            if (
                ! node.parent ||
                this.isMounted(map, node) ||
                node.firstChild
            ) {
                this._initAttrNodes(map, node)
            }
        },

        async _choose(map, parent, modalProps) {
            const fetch = this.readList.bind(this)

            const items = await openModal(
                <MapContext.Provider value={map}>
                    <ModalChooseDesignItems
                        fetch={fetch}
                        FormChoose={this.FormChoose}
                        TableChoose={this.TableChoose}
                        title={`选择${this.name }`}
                        width="80%"
                        {...modalProps}
                    />
                </MapContext.Provider>
            )

            if (items) {
                return items.map(data => ({data}))
            }
            else {
                return []
            }
        },

        _getStyle(map, node, styleDone) {
            const style = BaseNode.getStyle.call(this, map, node)
            const {delFlag, pkid, stsCode} = node.data

            if (pkid) {
                Object.assign(style, styleDone)
            }
            else {
                Object.assign(style, {
                    backgroundColor: Color.GREY,
                    textColor: '#000',
                })
            }

            if (
                '1' === delFlag ||
                'DISCARD' === stsCode
            ) {
                Object.assign(style, {
                    //fontWeight: 'bold',
                    textDecoration: 'line-through red 0.2em',
                })
            }

            return style
        },

        async _initAttrNodes(map, node) {
            if (! this.attrNodes) {
                return
            }

            const {top = [], bottom = []} = this.attrNodes

            const init = async (type, insert) => {
                const n = map.createNode(type)
                insert(node, n)
                await map.BizNode[type]._atCreate(map, n)
            }

            await Promise.all([
                Promise.all(
                    top.toReversed().map(
                        async type => init(type, map.prependChild.bind(map))
                    )
                ),

                Promise.all(
                    bottom.map(
                        type => init(type, map.appendChild.bind(map))
                    )
                ),
            ])
        },

        async _getPushData(map, node) {
            this._validate(node)
            const {data, id} = node

            const cat = (() => {
                const path = []

                for (const n of map.chain(node.parent)) {
                    const {bizNodeType} = n.data

                    if ('CAT' !== bizNodeType) {
                        break
                    }

                    const text = map.BizNode.CAT.getTextRaw(map, n)
                    path.push(text)
                }

                if (0 < path.length) {
                    return path.reverse().join('@@')
                }
                else {
                    return ''
                }
            })()

            const slots = await (async () => {
                if (! this._pushDataSlots) {
                    return void 0
                }

                const e = {
                    type: 'push',
                    detail: structuredClone(this._pushDataSlots),
                }

                await this.emitEventDown(map, node, e)
                return e.detail
            })()

            const pushData = {
                ...data,
                ...slots,
                id,
                [this.catProp]: cat,
            }

            return pushData
        },

        async _grow(map, node, depth) {
            if (! this.canGrow(map, node)) {
                return depth
            }

            const tree = await this._readGrowTree(map, node)

            if (! tree) {
                return depth
            }

            const {
                deliverRev,
                deliverVer,
                lastRev,
                rev,
            } = node.data

            if (0 < rev) {
                tree.data = {
                    ...tree.data,
                    deliverRev,
                    deliverVer,
                    lastRev,
                    rev,
                }
            }

            await this.replace(map, node, tree)
            return depth + 1
        },

        async _growProps(map, node, props) {
            const {definition} = this.Model

            const children = props
                .map(p => NodePropModel.create(definition, p, node.data))
                .filter(m => ! m.isHidden)
                .map(m => {
                    const text = (() => {
                        if ('boolean' === m.type) {
                            if (m.value) {
                                return '是'
                            }
                            else {
                                return '否'
                            }
                        }
                        else if ('key' === m.type) {
                            return m.text
                        }
                        else {
                            if (m.value) {
                                return m.value
                            }
                            else {
                                return '(空)'
                            }
                        }
                    })()

                    return {
                        data: {
                            bizNodeType: 'DPPROP',
                            propName: m.name,
                            text,
                        }
                    }
                })

            const dpProps = map.importTree({
                children,
                data: {bizNodeType: 'DP_PROPS'},
            })

            map.appendChild(node, dpProps)
        },

        async _onChange(map, node, event) {
            if (event.target === node) {
                const oldData = event.detail

                await (async () => {
                    if (! this.attrNodes) {
                        return
                    }

                    const attrs = new Map
                    let noChange = true
                    const {top = [], bottom = []} = this.attrNodes

                    for (const type of [...top, ...bottom]) {
                        const {prop} = map.BizNode[type]
                        const isChanged = node.data[prop] !== oldData[prop]

                        const attr = {
                            isChanged,
                            type,
                            value: node.data[prop],
                        }

                        attrs.set(type, attr)
                        noChange &&= ! isChanged
                    }

                    if (noChange) {
                        return
                    }

                    for (const n of node.children) {
                        const {bizNodeType} = n.data
                        const attr = attrs.get(bizNodeType)

                        if (attr) {
                            attr.node = n
                        }
                    }

                    const insertAttrNode = (type, an) => {
                        let insert = () => map.prependChild(node, an)

                        if (top.includes(type)) {
                            for (const t of top) {
                                if (t === type) {
                                    break
                                }

                                const {node: n} = attrs.get(t)

                                if (n) {
                                    insert = () => map.insertSiblingAfter(n, an)
                                }
                            }
                        }
                        else {
                            for (const t of bottom.toReversed()) {
                                if (t === type) {
                                    break
                                }

                                const {node: n} = attrs.get(t)

                                if (n) {
                                    insert = () => map.insertSiblingBefore(n, an)
                                }
                            }
                        }

                        insert()
                    }

                    const createAttr = async ({type}) => {
                        const n = map.createNode(type)
                        insertAttrNode(type, n)
                        const _n = map.nodeProxy(n)
                        await _n._atCreate()
                    }

                    const deleteAttr = ({node}) => {
                        if (node) {
                            map.deleteTree(node)
                        }
                    }

                    const updateAttr = ({node, type, value}) => {
                        const {textProp} = map.BizNode[type]

                        node.data = {
                            ...node.data,
                            [textProp]: value,
                        }
                    }

                    await Promise.all([...attrs.values()]
                        .filter(({isChanged}) => isChanged)
                        .map(async attr => {
                            if (attr.value) {
                                if (attr.node) {
                                    updateAttr(attr)
                                }
                                else {
                                    await createAttr(attr)
                                }
                            }
                            else {
                                deleteAttr(attr)
                            }
                        })
                    )
                })()
            }

            await BaseNode._onChange.call(this, map, node, event)
        },

        async _onInsert(map, node) {
        },

        async _pushDone(map, node, result) {
            const updates = {...result}

            if (this._pushDataSlots) {
                for (const [k, v] of Object.entries(this._pushDataSlots)) {
                    delete updates[k]

                    if (Array.isArray(v)) {
                        const results = result[k] ?? []

                        await Promise.all(
                            results.map(result => {
                                if (! result.id) {
                                    return
                                }

                                const n = map.getNode(result.id)

                                if (! n) {
                                    return
                                }

                                const _n = map.nodeProxy(n)
                                return _n._pushDone?.(result)
                            })
                        )
                    }
                }
            }

            node.data = {...node.data, ...updates}
            this.clean(map, node)
        },

        async _readGrowTree(map, node) {
            return null
        },

        async _shrinkProps(map, node) {
            for (const n of node.children) {
                if ('DP_PROPS' === n.data.bizNodeType) {
                    map.deleteTree(n)
                }
            }
        },

        _toConcept(map, node) {
            const {dpSn, lastRev, pkid, rev, ...data} = node.data
            node.data = data
        },

        _validate(node) {
            if (this.Model) {
                const model = new this.Model(node.data)
                const errMsg = model.validate()

                if (errMsg) {
                    throw new ValidationError(errMsg, node)
                }
            }
        },
    })
}
