import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelCdtseqval.mjs'

const PropertiesPaneCdtseqval = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="cdtSeqValName" />
                <Text prop="cdtSeqVal" />
                <Text prop="cdtSeqValEn" />
                <LongText prop="cdtSeqValDesc" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'ChgReference',
                    'Reference',
                    'ReferenceCall',
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneCdtseqval
