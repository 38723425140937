import {TreeDoc, TreeDocNode} from '@/components/TreeDoc/index.mjs'
import metaBizNode from './metaBizNode.mjs'

const commentTypes = new Set(
    Object.entries(metaBizNode)
        .map(([type, {classes}]) => classes?.includes('comment') ? type : '')
        .filter(a => a)
)

class SMapNode extends TreeDocNode {
    get trueChain() {
        return this.doc.trueChain(this)
    }

    get trueParent() {
        const p = this.parent

        if (! p) {
            return null
        }

        const {bizNodeType} = p.data

        if (commentTypes.has(bizNodeType)) {
            return p.trueParent
        }
        else {
            return p
        }
    }
}

export class SMapDoc extends TreeDoc {
    static TreeNode = SMapNode;

    *trueChain(node) {
        for (const n of this.chain(node)) {
            const {bizNodeType} = n.data

            if (! commentTypes.has(bizNodeType)) {
                yield n
            }
        }
    }

    async _init() {
        const _n = this.nodeProxy(this.root)
        await _n.emitEventDown({type: 'import'})
    }
}
