import Table from '@/components/Table/Table.jsx'
import TableChooseComponent from '../_COMPONENT/TableChooseComponent.jsx'

const TableChooseDbins = (props) => {
    const columns = [
        {
            title: '数据库名称',
            dataIndex: 'dbName',
            component: <Table.ViewText />,
        },

        {
            title: '数据库实例名称',
            dataIndex: 'dbInsName',
            component: <Table.ViewText />,
        },

        {
            title: '环境',
            dataIndex: 'dbInsEnv',
            component: <Table.ViewText />,
        },

        {
            title: '内网IP',
            dataIndex: 'dbInsIntranetIp',
            component: <Table.ViewText />,
        },

        {
            title: '公网IP',
            dataIndex: 'dbInsInternetIp',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseComponent
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseDbins
