import extendNode from '../extendNode.mjs'
import useOprNode from '../_OPR/useOprNode.jsx'
import meta from './metaCopr.mjs'

export default () => {
    const OprNode = useOprNode()

    return extendNode(OprNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(CRES|PIC)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'CRES'
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['PIC'],
            ]
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['CRES'],
            ]
        },

        async _atOwnerPush(map, node, event) {
            let dataKey, oprCat, oprScene, sceneNo

            for (const n of map.chain(node.parent)) {
                const {bizNodeType} = n.data

                if ('CAT' === bizNodeType) {
                    if (! oprCat) {
                        oprCat = map.BizNode.CAT.getTextRaw(map, n)
                    }
                }
                else if (map.BizNode[bizNodeType].classes.includes('comment')) {
                    continue
                }
                else if ('ROLE' === bizNodeType) {
                    dataKey = 'ucOprList'
                    oprScene = map.BizNode.ROLE.getTextRaw(map, n)
                    sceneNo = n.data.sceneNo
                    break
                }
            }

            if (! dataKey) {
                return
            }

            const d = await this._getPushData(map, node)

            Object.assign(d, {
                oprCat,
                oprScene,
                picId: '',
                picName: '',
                sceneNo,
            })

            for (const n of node.children) {
                const {bizNodeType} = n.data

                if ('PIC' === bizNodeType) {
                    const {fileId: picId, text: picName} = n.data

                    if (picId) {
                        Object.assign(d, {picId, picName})
                        break
                    }
                }
            }

            event.detail[dataKey].push(d)
        },

        _fixChild(map, node, child) {
            const {bizNodeType} = child.data

            if ('FRES' === bizNodeType) {
                map.BizNode.CRES.castFrom(map, child)
                return true
            }
            else {
                return OprNode._fixChild.call(this, map, node, child)
            }
        },
    })
}
