import extendNode from '../extendNode.mjs'
import useCategoryRootNode from '../_CATEGORY_ROOT/useCategoryRootNode.jsx'
import meta from './metaCatRootPrjSln.mjs'
import PropertiesPane from './PropertiesPaneCatRootPrjSln.jsx'

export default () => {
    const CategoryRootNode = useCategoryRootNode()

    return extendNode(CategoryRootNode, {
        ...meta,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(SUB_PRJ|CAT_DOC)$/.test(type)
        },
        
        async gotoCurrent(map, node, target) {
            const {prjId, sVer} = node.data
            openWindow('/SaList', {prjId, sVer}, target)
        },
        menuItemsInsertCommon(map, node) {
            return []
        },
    })
}
