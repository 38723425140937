import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import IconLetters from '../icons/IconLetters.jsx'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import api from './apiDe.mjs'
import meta from './metaDe.mjs'
import Model from './ModelDe.mjs'
import PropertiesPane from './PropertiesPaneDe.jsx'
import FormChoose from './FormChooseDe.jsx'
import TableChoose from './TableChooseDe.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        attrNodes: {
            top: ['ATTR_DE_RESUME'],
        },

        canMountType(map, node, type) {
            return /^(AR)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'AR'
        },

        getIcons(map, node) {
            return [
                <IconLetters
                    key="type"
                    fill={Color.CYAN}
                />
            ]
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.CYAN}),
                shape: 'Parallelogram',
            }
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['AR'],
            ]
        },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.deList.push(d)
        },
    })
}
