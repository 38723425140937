import Table from '@/components/Table/Table.jsx'
import TableChooseProduct from '../_PRODUCT/TableChooseProduct.jsx'

const TableChooseVr = (props) => {
    const columns = [
        {
            title: '虚拟机名称',
            dataIndex: 'vsName',
            component: <Table.ViewText />,
        },

        {
            title: '环境',
            dataIndex: 'vsEnv',
            component: <Table.ViewText />,
        },

        {
            title: '内网IP',
            dataIndex: 'vsIntranetIp',
            component: <Table.ViewText />,
        },

        {
            title: '公网IP',
            dataIndex: 'vsInternetIp',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseProduct
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseVr
