import http from '@/biz/http.mjs'

export default {
    create: data => http.post('/omoo/addOmOo', data),

    publish: ({pkid, ...data}) => http.post(
        '/omoo/rlsOmOo', {omOos: [{pkid}], ...data}
    ),

    pull: ({pkid}) => http.get('/omoo/loadDp', {pkid}),
    push: data => http.post('/omoo/modifyDp', data),
    read: args => http.get('/omoo/oneOmOo', args),
    readList: args => http.get('/omoo/listOmOo', args),

    revise: ({pkid, ...data}) => http.post(
        '/omoo/reviseOmOo', {omOos: [{pkid}], ...data}
    ),

    update: data => http.post('/omoo/modifyOmOo', data),
    updateMap: data => http.post('/omoo/saveMap', data),
}
