import Table from '@/components/Table/Table.jsx'
import TableChooseProduct from '../_PRODUCT/TableChooseProduct.jsx'

export default function TableChooseDo(props) {
    const columns = [
        {
            title: '项目编号',
            dataIndex: 'prjNo',
            component: <Table.ViewText />,
        },

        {
            title: '组织域',
            dataIndex: 'omNamePath',
            component: <Table.ViewText />,
        },

        {
            title: '组织对象代码',
            dataIndex: 'ooNo',
            component: <Table.ViewText />,
        },

        {
            title: '组织对象名称',
            dataIndex: 'ooName',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseProduct
            columns={columns}
            {...props}
        />
    )
}
