import {CheckOutlined} from '@ant-design/icons'
import {useMapContext} from '@/components/MindMap/index.mjs'

const Prefix = () => {
    const map = useMapContext()
    const show = map.useShowHiddenNodes()
    return show ? <CheckOutlined /> : null
}

export default () => {
    const map = useMapContext()

    const onClick = e => {
        e.stopPropagation()
        map.showHiddenNodes = ! map.showHiddenNodes
    }

    return {
        key: 'show-hidden-nodes',
        disabled: ! map.allowShowHiddenNodes,
        label: '显示隐藏节点',
        prefix: <Prefix />,
        onClick,
    }
}
