import useSwrNoCache from '@/script/useSwrNoCache.mjs'
import http from '@/biz/http.mjs'

export default prjId => {
    return useSwrNoCache(
        () => http.get('/prjmbrs', {prjId}),

        {
            dependencies: [prjId],
            start: void 0 !== prjId,
        }
    )
}
