import extendNode from '../extendNode.mjs'
import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaBdRelation.mjs'

export default () => {
    const ArCatNode = useArCatNode()

    return extendNode(ArCatNode, {
        ...meta,

        isHidden(map, node) {
            const {bdType} = node.parent?.data ?? {}

            return (
                '2' === bdType ||
                ArCatNode.isHidden.call(this, map, node)
            )
        },

        async _atPush(map, node, event) {
            event.stopPropagation()
            const d = await this._getPushData(map, node)
            event.detail.algList.push(d)
        },

        _getDefaultDataFields(map, node) {
            return {
                ...ArCatNode._getDefaultDataFields.call(this, map, node),
                algType: 'RELATION',
            }
        },
    })
}
