import Table from '@/components/Table/Table.jsx'
import TableChooseProduct from '../_PRODUCT/TableChooseProduct.jsx'

const TableChooseLs = (props) => {
    const columns = [
        {
            title: '外联系统名称',
            dataIndex: 'lsName',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseProduct
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseLs
