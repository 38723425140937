import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaUeAlg.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        isHidden(map, node) {
            return (
                /^(SF)_LVL$/.test(map.data.desgAcc) ||
                CategoryNode.isHidden.call(this, map, node)
            )
        },

        _getCategories(map, node) {
            return [
                'ALG_INPUT',
                'ALG_OUTPUT',
                'ALG_SUMMARY',
            ]
        },
    })
}
