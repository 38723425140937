import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import IconAvatar from '../icons/IconAvatar.jsx'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import api from './apiOr.mjs'
import meta from './metaOr.mjs'
import Model from './ModelOr.mjs'
import PropertiesPane from './PropertiesPaneOr.jsx'
import FormChoose from './FormChooseOr.jsx'
import TableChoose from './TableChooseOr.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        getIcons(map, node) {
            return [
                <IconAvatar
                    key="type"
                    color={Color.LIGHT_YELLOW}
                />
            ]
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.LIGHT_YELLOW}),
                shape: 'RightEllipseRectangle',
            }
        },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.orList.push(d)
        },
    })
}
