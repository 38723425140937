import {useEffect} from 'react'
import {css} from '@emotion/react'
import {Checkbox} from 'antd'
import {useMapContext} from '@/components/MindMap/index.mjs'

const cssList = css({
    overflow: 'auto',
})

const cssListItem = css({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gap: 8,
    padding: 8,

    '&:hover': {
        backgroundColor: '#e9f7fe',
    },
})

const ListItem = ({node, ...props}) => {
    const map = useMapContext()
    const _n = map.nodeProxy(node)
    const path = _n.getPath()
    const disabled = ! map.actions.canUnhideNode(node)

    const handleClick = () => {
        map.execute(
            () => {
                map.selectNodes([node])
            }
        )
    }

    return (
        <div
            css={cssListItem}
            {...props}
        >
            <Checkbox disabled={disabled} />
            <div onClick={handleClick}>{path}</div>
        </div>
    )
}

export default function HiddenNodes({
    items,
    selectedKeys,
    onSelect,
    ...props
}) {
    const map = useMapContext()

    useEffect(
        () => {
            const oldAllow = map.allowShowHiddenNodes
            const oldShow = map.showHiddenNodes
            map.allowShowHiddenNodes = false
            map.showHiddenNodes = true

            return () => {
                map.allowShowHiddenNodes = oldAllow
                map.showHiddenNodes = oldShow
            }
        },

        [map]
    )

    const handleChangeItem = (key) => {
        return ({target: {checked}}) => {
            const newSelectedKeys = new Set(selectedKeys)

            if (checked) {
                newSelectedKeys.add(key)
            }
            else {
                newSelectedKeys.delete(key)
            }

            onSelect(newSelectedKeys)
        }
    }

    const listItems = items.map(
        node => (
            <ListItem
                key={node.id}
                node={node}
                onChange={handleChangeItem(node.id)}
            />
        )
    )

    return (
        <div
            css={cssList}
            {...props}
        >
            {listItems}
        </div>
    )
}
