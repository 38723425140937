import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    orName: {
        name: () => '岗位角色标题',
        required: () => true,
    },

    orUserCode: {
        name: () => '岗位角色代码',
    },
})
