import superMeta from '../_PRODUCT/metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'aoNo',
    detailUrl: '/AoDetail',
    detailIdProp: 'aoId',
    mapProp: 'aoMap',
    name: '分析对象',
    textProp: 'aoName',
    type: 'AO',
}
