import Table from '@/components/Table/Table.jsx'
import TableChooseProduct from '../_PRODUCT/TableChooseProduct.jsx'
import SsType from './SsType.mjs'

const DictSsType = Object.fromEntries(SsType)

const TableChooseSs = (props) => {
    const columns = [
        {
            title: '子系统类型',
            dataIndex: 'isUi',
            component: <Table.ViewDict dict={DictSsType} />,
        },

        {
            title: '子系统代码',
            dataIndex: 'ssUserCode',
            component: <Table.ViewText />,
        },

        {
            title: '子系统名称',
            dataIndex: 'ssName',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseProduct
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseSs
