import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    acName: {
        name: () => '口径名称',
        required: () => true,
    },

    acUserCode: {
        name: () => '口径代码',
    },

    resume: {
        name: () => '简述',
    },
})
