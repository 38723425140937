import {css} from '@emotion/react'
import {Checkbox} from 'antd'
import {useMapContext} from '@/components/MindMap/index.mjs'

const cssList = css({
    overflow: 'auto',
})

const cssListItem = css({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gap: 8,
    padding: 8,

    '&:hover': {
        backgroundColor: '#e9f7fe',
    },
})

const cssListItemProductName = css({
    fontSize: 16,
})

const cssListItemProductVersion = css({
})

const ListItem = ({nodeId, referer, referred, ...props}) => {
    const map = useMapContext()

    const stsName = {
        DRAFT: ' (草稿)',
        REVISE: ' (修订)',
    }[referer.stsCode]

    const handleClick = () => {
        const node = map.getNode(nodeId)

        map.execute(
            () => map.selectNodes([node])
        )
    }

    return (
        <div
            css={cssListItem}
            {...props}
        >
            <Checkbox disabled={stsName} />

            <div onClick={handleClick}>
                <div css={cssListItemProductName}>
                    {referer.text}{stsName}→{referred.text}
                </div>

                <div css={cssListItemProductVersion}>
                    {referer.ver}→{referred.ver}
                </div>
            </div>
        </div>
    )
}

export default function ListUpgradable({
    items,
    selectedKeys,
    onSelect,
    ...props
}) {
    const handleChangeItem = (key) => {
        return ({target: {checked}}) => {
            const newSelectedKeys = new Set(selectedKeys)

            if (checked) {
                newSelectedKeys.add(key)
            }
            else {
                newSelectedKeys.delete(key)
            }

            onSelect(newSelectedKeys)
        }
    }

    const listItems = items.map(
        ({key, ...data}) => (
            <ListItem
                key={key}
                nodeId={key}
                {...data}
                onChange={handleChangeItem(key)}
            />
        )
    )

    return (
        <div
            css={cssList}
            {...props}
        >
            {listItems}
        </div>
    )
}
