import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import api from './apiBdlogic.mjs'
import meta from './metaBdlogic.mjs'
import Model from './ModelBdlogic.mjs'
import RuleType from './RuleType.jsx'
import PropertiesPane from './PropertiesPaneBdlogic.jsx'
import PropertiesPaneBatch from './PropertiesPaneBatchBdlogic.jsx'
import FormChoose from './FormChooseBdlogic.jsx'
import TableChoose from './TableChooseBdlogic.jsx'

const DEFAULT_RESUME = '在此录入简述'

const ruleTypes = new Map(
    RuleType.map(([type, name, icon]) => [type, {icon, name, type}])
)

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,
        PropertiesPaneBatch,

        attrNodes: {
            top: ['ATTR_BDLOGIC_RESUME'],
        },

        canMountType(map, node, type) {
            return (
                /^(AR|DOC|PIC|URL)$/.test(type) ||
                ComponentNode.canMountType.call(this, map, node, type)
            )
        },

        defaultChildType(map, node) {
            return 'AR'
        },

        getDesc(map, node) {
            const {ruleType} = node.data

            if (! ruleType) {
                return this.name
            }

            const {name = ''} = ruleTypes.get(ruleType) ?? {}
            return name
        },

        getIcons(map, node) {
            const {ruleType} = node.data
            const {icon} = ruleTypes.get(ruleType) ?? {}

            if (icon) {
                return [icon]
            }
            else {
                return []
            }
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.LIGHT_YELLOW}),
                shape: 'EllipseRectangle',
            }
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['AR'],
                ['PIC'],
                ['DOC'],
                ['URL'],
            ]
        },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.ruleList.push(d)
        },

        _getCategories(map, node) {
            return [
                'BDLOGIC_INPUT',
                'BDLOGIC_OUTPUT',
                'BDLOGIC_RULE',
                'BDLOGIC_TARGET',
                'BDLOGIC_UI',
            ]
        },

        _getDefaultDataFields(map, node) {
            return {
                ...ComponentNode._getDefaultDataFields.call(this, map, node),
                resume: DEFAULT_RESUME,
                ruleType: 'BT',
            }
        },

        async _onChange(map, node, event) {
            if (event.target === node) {
                const oldData = event.detail
                const {resume, ruleType} = node.data

                if (ruleType !== oldData.ruleType) {
                    // 强制子节点重新渲染

                    node.change('children')

                    for (const n of node.children) {
                        n.change('data')
                        n.change('parent')
                    }

                    if (
                        'BL' === ruleType &&
                        resume === DEFAULT_RESUME
                    ) {
                        node.data = {...node.data, resume: ''}
                    }
                    else if (
                        'BT' === ruleType &&
                        resume === ''
                    ) {
                        node.data = {...node.data, resume: DEFAULT_RESUME}
                    }
                }
            }

            await ComponentNode._onChange.call(this, map, node, event)
        },

        async _readGrowTree(map, node) {
            return map.BizNode.BDRULE._readGrowTree.call(this, map, node)
        }
    })
}
