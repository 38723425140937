import {css} from '@emotion/react'
import {Checkbox as AntdCheckbox} from 'antd'
import {useReadOnly} from './ReadOnly.mjs'

const cssCheckbox = css({
    display: 'inline-grid',
    gridTemplateColumns: 'auto 1fr 0',

    '&+&': {
        marginLeft: 0,
    },
})

const cssCheckboxSmall = css({
    fontSize: 12,
})

const Checkbox = ({
    defaultValue,
    falseValue = false,
    readOnly,
    size,
    trueValue = true,
    value = defaultValue,
    checked = value === trueValue,
    onChange,
    ...props
}) => {
    const gReadOnly = useReadOnly()

    const handleChange = ({target: {checked}}) => {
        if (! (readOnly || gReadOnly)) {
            return onChange(checked ? trueValue : falseValue)
        }
    }

    const csss = [cssCheckbox]

    if ('small' === size) {
        csss.push(cssCheckboxSmall)
    }

    return (
        <AntdCheckbox
            css={csss}
            checked={checked}
            onChange={handleChange}
            {...props}
        />
    )
}

export default Checkbox
