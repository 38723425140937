import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useAlgNode from '../_ALG/useAlgNode.jsx'
import meta from './metaBdalg.mjs'
import PropertiesPane from './PropertiesPaneBdalg.jsx'
import PropertiesPaneBatch from './PropertiesPaneBatchBdalg.jsx'

export default () => {
    const AlgNode = useAlgNode()

    return extendNode(AlgNode, {
        ...meta,
        PropertiesPane,
        PropertiesPaneBatch,

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.LIGHT_YELLOW,
                }),

                shape: 'HorizontalHexagon',
            }
        },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.algList.push(d)
        },

        _pushDataSlots: {
            fcCode: '',
            sfCodes: [],
        },

        _getCategories(map, node) {
            return [
                'ALG_RULE',
                'ALG_ALG',
                'ALG_API',
            ]
        },
    })
}
