import http from '@/biz/http.mjs'

export default {
    publish: data => http.post('/pa/rlsArchPa', data),
    pull: ({pkid}) => http.get('/pa/loadDp', {pkid}),
    push: data => http.post('/pa/modifyDp', data),
    read: args => http.get('/pa/oneArchPa', args),
    revise: ({pkid}) => http.post('/pa/reviseArchPa', {pkid}),
    updateMap: data => http.post('/pa/saveMap', data),
}
