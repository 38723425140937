import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaVrDeployedIn.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canLinkType(map, node, type) {
            return /^(PHYS)$/.test(type)
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['PHYS'],
            ]
        },

        async _atPush(map, node, event) {
            event.stopPropagation()

            const next = chain => {
                const _n = map.nodeProxy(chain[0])
                return {yieldChildren: _n.isMounted()}
            }

            for (const n of map.bfs(node.children, next)) {
                const {bizNodeType, prjId, psCode, rev, sVer} = n.data

                if ('PHYS' === bizNodeType) {
                    event.detail.depVsDeploys.push({
                        psCode,
                        psPrjId: prjId,
                        psSVer: sVer,
                        psVer: rev,
                    })
                }
            }
        },
    })
}
