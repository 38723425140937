import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import meta from './metaOo.mjs'
import Model from './ModelOo.mjs'
import PropertiesPane from './PropertiesPaneOo.jsx'
import FormChoose from './FormChooseOo.jsx'
import TableChoose from './TableChooseOo.jsx'
import api from './apiOo.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        attrNodes: {
            top: ['ATTR_OO_RESUME'],
        },

        async choose(map, node) {
            const getQuery = ({oms = [], ...query}) => ({
                ...query,
                omIds: oms.map(({omId}) => omId),
            })

            return this._choose(map, node, {getQuery})
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.LIGHT_YELLOW,
                }),

                shape: 'Rectangle',
            }
        },

        _getCategories(map, node) {
            return [
                'OO_OA',
                'OO_OU',
                'OO_OR',
            ]
        },

        _pushDataSlots: {
            orList: [],
            ouList: [],
        },
    })
}
