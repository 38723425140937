import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'acUserCode',
    mapProp: 'acMap',
    name: '分析口径',
    ownerIdProp: 'aiId',
    ownerTextProp: 'aiName',
    ownerType: 'AMAI',
    textProp: 'acName',
    type: 'AIAC',
}
