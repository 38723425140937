import {useEffect, useRef, useState} from 'react'
import {publish, subscribe, unsubscribe} from '@/script/event.mjs'

export default (defaultShow = false) => {
    const refShow = useRef(defaultShow)

    const extensions = () => ({
        get showHiddenNodes() {
            return refShow.current
        },

        set showHiddenNodes(value) {
            refShow.current = value
            publish(this, 'show_hidden_nodes_change', value)

            this.execute(() => {
                const next = chain => {
                    const {isHidden} = chain[0]

                    return {
                        yieldChildren: ! isHidden,
                        yieldNode: isHidden,
                    }
                }

                for (const n of this.dfs(this.root.children, next)) {
                    n.parent.change('children')
                }
            })
        },

        isNodeHidden(node) {
            return node.isHidden && ! this.showHiddenNodes
        },

        useShowHiddenNodes() {
            const [show, setShow] = useState(refShow.current)

            useEffect(
                () => {
                    subscribe(this, 'show_hidden_nodes_change', setShow)

                    return () => {
                        unsubscribe(this, 'show_hidden_nodes_change', setShow)
                    }
                },

                []
            )

            return show
        },
    })

    return {extensions}
}
