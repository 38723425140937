import SelectAmDialog from '@/pages/MainPage/dialogs/SelectAmDialog'
import InputModalSelect from '../components/InputModalSelect.jsx'

export default function SelectAm({value, ...props}) {
    const getText = multiple => multiple ?
        (value ?? []).map(({amName}) => amName).join(',') :
        (value ?? {}).amName

    return (
        <InputModalSelect
            getText={getText}
            Modal={SelectAmDialog}
            {...props}
        />
    )
}
