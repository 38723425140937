import superMeta from '../_ARCH/metaArchNode.mjs'

export default {
    ...superMeta,
    alias: '需求池结构',
    archUrl: '/ProjectSfMap',
    detailUrl: '/RaDetail',
    detailIdProp: 'raId',
    mapProp: 'raMap',
    moduleType: 'RM',
    name: '需求池结构',
    type: 'RA',
}
