import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import IconLetters from '../icons/IconLetters.jsx'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import meta from './metaUirule.mjs'
import Model from './ModelUirule.mjs'
import PropertiesPane from './PropertiesPaneUirule.jsx'
import PropertiesPaneBatch from './PropertiesPaneBatchUirule.jsx'
import FormChoose from './FormChooseUirule.jsx'
import TableChoose from './TableChooseUirule.jsx'
import api from './apiUirule.mjs'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,
        PropertiesPaneBatch,

        canMountType(map, node, type) {
            return /^(AR|DOC|PIC|URL)$/.test(type)
        },

        async choose(map, node) {
            const getQuery = query => {
                const {bizNodeType, uiUserCode} = map.root.data

                if ('UI' === bizNodeType) {
                    return {uiUserCode, ...query}
                }
                else {
                    return query
                }
            }

            return this._choose(map, node, {getQuery})
        },

        defaultChildType(map, node) {
            return 'AR'
        },

        getIcons(map, node) {
            const {ruleType} = node.data

            if ('DC' === ruleType) {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.LIGHT_BLUE}
                        letters="C"
                        textColor="#fff"
                    />
                ]
            }
            else {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.LIGHT_BLUE}
                        letters="R"
                        textColor="#fff"
                    />
                ]
            }
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.LIGHT_BLUE}),
                shape: 'EllipseRectangle',
            }
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['AR'],
                ['PIC'],
                ['DOC'],
                ['URL'],
            ]
        },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.ruleList.push(d)
        },

        _getDefaultDataFields(map, node) {
            return {
                ...ComponentNode._getDefaultDataFields.call(this, map, node),
                ruleType: 'COMMON'
            }
        },
    })
}
