import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    catProp: 'ueCat',
    codeProp: 'ueUserCode',
    mapProp: 'ueMap',
    name: '界面事件能力',
    ownerIdProp: 'uiId',
    ownerTextProp: 'uiName',
    ownerType: 'UI',
    textProp: 'ueName',
    type: 'UE',
}
