import {useRef} from 'react'
import {css} from '@emotion/react'
import {DraggableListItemContextProvider} from './DraggableListContext.mjs'
import useDraggableListItem from './useDraggableListItem.mjs'

const cssDraggableListItem = css({
    backgroundColor: '#fff',
})

const cssDraggableListItemFocused = css({
    zIndex: 1,
    boxShadow: '0 0 4px rgba(0, 0, 0, .5)',
})

export default function DraggableListItem({children, ...props}) {
    const refEl = useRef()
    const li = useDraggableListItem(refEl)
    const csss = [cssDraggableListItem]

    if (li.isFocused) {
        csss.push(cssDraggableListItemFocused)
    }

    return (
        <div
            ref={refEl}
            css={csss}
            {...props}
        >
            <DraggableListItemContextProvider value={li}>
                {children}
            </DraggableListItemContextProvider>
        </div>
    )
}
