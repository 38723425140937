import {useOpenModal} from '@/components/Modal/Modal.jsx'
import SelectMapVerDialog from '@/pages/MainPage/dialogs/SelectMapVerDialog.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import parseMap from '../scripts/parseMap.mjs'

export default () => {
    const openModal = useOpenModal()
    const map = useMapContext()
    const permission = map.usePermission()

    if (
        ! permission.has('write') ||
        '_DES_MAP' === map.data.mapTypeCode
    ) {
        return null
    }

    const onClick = async () => {
        const data = await openModal(
            <SelectMapVerDialog id={map.data.pkid} />
        )

        if (! data) {
            return
        }

        const metaKeys = [
            'crtId',
            'crtIp',
            'crtName',
            'crtTime',
            'rlsTime',
            'rlsUserId',
            'rlsUserName',
            'stsCode',
            'stsName',
            'uptId',
            'uptIp',
            'uptName',
            'uptTime',
        ]

        const {root} = parseMap(data.dataMap)

        for (const k of metaKeys) {
            root.data[k] = map.root.data[k]
        }

        await map.cmd(async () => {
            const _n = map.nodeProxy(map.root)
            await _n.replace(root)
        })
    }

    return {
        key: 'import-snapshot',
        label: '导入发布版本',
        onClick,
    }
}
