import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

import {
    PropertiesController,
    usePropertiesController,
} from '../../SidePanel/RightPanel/PropertiesController.jsx'

import SelectOm from './SelectOm.jsx'

export default function PropertiesControllerOm({
    className,
    style,
    codeProp = 'omCode',
    disabled,
    hidden,
    idProp = 'omId',
    label,
    labelSuffix,
    nameProp = 'omName',
    readOnly,
    required,
    ...props
}) {
    const [values, update] = useProperties()

    const {
        [codeProp]: omCode,
        [idProp]: omId,
        [nameProp]: omName,
    } = values

    const handleChange = om => {
        const {
            omCode = '',
            omId = '',
            omName = '',
        } = om ?? {}

        update({
            [codeProp]: omCode,
            [idProp]: omId,
            [nameProp]: omName,
        })
    }

    const p = usePropertiesController({
        disabled,
        hidden,
        label,
        prop: codeProp,
        readOnly,
        required,
    })

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            label={p.label}
            labelSuffix={labelSuffix}
            required={p.required}
        >
            <SelectOm
                disabled={p.disabled}
                readOnly={p.readOnly}
                value={{omCode, omId, omName}}
                onChange={handleChange}
                {...props}
            />
        </PropertiesController>
    )
}
