import MenuButton from '../MenuButton.jsx'
import useMenuItemCopy from '../../menuItems/useMenuItemCopy.jsx'
import useMenuItemCopyStyle from '../../menuItems/useMenuItemCopyStyle.jsx'
import useMenuItemCut from '../../menuItems/useMenuItemCut.jsx'
import useMenuItemDelete from '../../menuItems/useMenuItemDelete.jsx'
import useMenuItemDeleteSingleNode from '../../menuItems/useMenuItemDeleteSingleNode.jsx'
import useMenuItemDuplicate from '../../menuItems/useMenuItemDuplicate.jsx'
import useMenuItemFold from '../../menuItems/useMenuItemFold.jsx'
import useMenuItemHide from '../../menuItems/useMenuItemHide.jsx'
import useMenuItemMark from '../../menuItems/useMenuItemMark.jsx'
import useMenuItemMoveDown from '../../menuItems/useMenuItemMoveDown.jsx'
import useMenuItemMoveUp from '../../menuItems/useMenuItemMoveUp.jsx'
import useMenuItemPaste from '../../menuItems/useMenuItemPaste.jsx'
import useMenuItemPasteAfter from '../../menuItems/useMenuItemPasteAfter.jsx'
import useMenuItemPasteStyle from '../../menuItems/useMenuItemPasteStyle.jsx'
import useMenuItemRedoChange from '../../menuItems/useMenuItemRedoChange.jsx'
import useMenuItemRedoSelection from '../../menuItems/useMenuItemRedoSelection.jsx'
import useMenuItemResetStyle from '../../menuItems/useMenuItemResetStyle.jsx'
import useMenuItemSelectAll from '../../menuItems/useMenuItemSelectAll.jsx'
import useMenuItemSelectRoot from '../../menuItems/useMenuItemSelectRoot.jsx'
import useMenuItemUndoChange from '../../menuItems/useMenuItemUndoChange.jsx'
import useMenuItemUndoSelection from '../../menuItems/useMenuItemUndoSelection.jsx'

export default function MenuButtonEdit(props) {
    const items = [
        useMenuItemUndoChange(),
        useMenuItemRedoChange(),
        useMenuItemUndoSelection(),
        useMenuItemRedoSelection(),
        {divider: true},
        useMenuItemCut(),
        useMenuItemCopy(),
        useMenuItemPaste(),
        useMenuItemPasteAfter(),
        useMenuItemDuplicate(),
        useMenuItemDelete(),
        useMenuItemDeleteSingleNode(),
        {divider: true},
        useMenuItemCopyStyle(),
        useMenuItemPasteStyle(),
        useMenuItemResetStyle(),
        {divider: true},
        useMenuItemMoveUp(),
        useMenuItemMoveDown(),
        useMenuItemFold(),
        {divider: true},
        useMenuItemHide(),
        useMenuItemMark(),
        useMenuItemSelectRoot(),
        useMenuItemSelectAll(),
    ]

    return (
        <MenuButton
            items={items}
            {...props}
        >
            编辑
        </MenuButton>
    )
}
