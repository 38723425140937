import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Db from '../DB/PropertiesControllerDb.jsx'
import Dm from '../DM/PropertiesControllerDm.jsx'
import Model from './ModelDv.mjs'

const PropertiesPaneDv = (props) => (
    <ProductPropertiesPane
        controllers={
            <>
                <Db />
                <Dm />
                <Text prop="dvUserCode" />
                <Text prop="dvName" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'Depend',
                    'Effect',
                    'Reference',
                    'ReferenceCall',
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneDv
