import ModulePropertiesPane from '../_MODULE/ModulePropertiesPane.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import ButtonExportModel from '../components/PropertiesPaneButtonExportModel.jsx'
import Model from './ModelRm.mjs'

const PropertiesPaneRm = (props) => (
    <ModulePropertiesPane
        buttons={
            <>
                <ButtonExportModel type="rm" />
                <ButtonExportModel format="excel" type="rm" />
            </>
        }

        controllers={
            <>
                <Text prop="rmUserCode" />
                <Text prop="rmName" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'Reference',
                    'ReferenceCall',
                ]}
            />
        }

        versionLabel="需求域版本号"
        {...props}
    />
)

export default PropertiesPaneRm
