import { connect, mapProps, mapReadPretty, ReactFC, useForm, useExpressionScope } from '@formily/react'
import { Button, ButtonProps } from 'antd'
import React from 'react'
import { Icon } from "../../setters";
import { clickEventToProps } from '../../../service/utils'

export interface FormilyButtonProps extends ButtonProps {
  text: string
  clickEventProps?: any
  clickCustomEventProps?: any
  iconName?: string
  mapParmsToProps?: any
  hideModal?: boolean
}
export const AntdBtn = (props: FormilyButtonProps) => {
  const { text, clickEventProps, clickCustomEventProps, mapParmsToProps, iconName, hideModal = false,  ...rest } = props;
  const form = useForm();
  const $scope = useExpressionScope();
  let clickProps: any = {};
  if (iconName) {
    rest.icon = <Icon name={iconName} style={{fontSize: '16px'}}/>
  }
  if(clickEventProps) {
    // clickProps = clickEventToProps(clickEventProps);
    clickProps = clickEventToProps(clickEventProps, form);
  }
  const handleClick = (event) => {
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault();
      // Ctrl键被按下，执行你想要的操作
      form.notify('ctrl-click', clickCustomEventProps)
    } else {
      // 正常的点击事件处理
      // 如果可关闭弹窗
      if(hideModal) {
        $scope?.$closeScope()
        form.notify('hide-modal')
      }
      clickProps.onClick && clickProps.onClick();
    }
  };


  return (
    <Button {...rest} onClick={handleClick} >{text}</Button>
  )
}
const FormilyButton: ReactFC<FormilyButtonProps> = connect(
  AntdBtn,
)

export default FormilyButton
