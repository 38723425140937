import { connect, ReactFC } from '@formily/react'
import { Button, ButtonProps } from 'antd'
import React from 'react'
import { Icon } from "../../setters";

export interface FormilyDialogProps extends ButtonProps {
  text: string
  clickCustomEventProps?: any
  iconName?: string
  mapParmsToProps?: any
  children?: any
  width?: number
  hideBtn?: boolean
}
export const AntdBtn = (props: FormilyDialogProps) => {
  const { text, clickCustomEventProps, iconName,  ...rest } = props;
  if (iconName) {
    rest.icon = <Icon name={iconName} style={{fontSize: '16px'}}/>
  }

  return (
    <Button {...rest}  >{text}</Button>
  )
}
const FormilyDialog: ReactFC<FormilyDialogProps> = connect(
  AntdBtn,
)

export default FormilyDialog
