import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaOoOa.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canWriteTree(map, node) {
            return false
        },

        exportTree(map, node) {
            const children = [...node.children]
                .map(n => {
                    const _n = map.nodeProxy(n)
                    return _n.exportTree()
                })
                .filter(a => a)

            return {
                ...map.exportNode(node),
                children,
            }
        },
    })
}
