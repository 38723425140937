import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useModuleNode from '../_MODULE/useModuleNode.jsx'
import api from './apiDm.mjs'
import meta from './metaDm.mjs'
import Model from './ModelDm.mjs'
import PropertiesPane from './PropertiesPaneDm.jsx'

export default () => {
    const ModuleNode = useModuleNode()

    return extendNode(ModuleNode, {
        ...meta,
        api,
        Model,
        PropertiesPane,

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.CYAN}),
                shape: 'SingleBreakangle',
            }
        },

        _fixChild(map, node, child) {
            const {bizNodeType} = child.data

            if (/^[BF]M$/.test(bizNodeType)) {
                this.castFrom(map, child)
                return true
            }
            else {
                return ModuleNode._fixChild.call(this, map, node, child)
            }
        },

        async _atOwnerPush(map, node, event) {
            const {bizNodeType} = event.target.data

            const key = {
                DB: 'archDmList',
                DM: 'child'
            }[bizNodeType]

            const d = await this._getPushData(map, node)
            event.detail[key].push(d)
        },
    })
}
