export const saveMap = () => () => {
    return function () {
        return this.runAction(
            () => {
                const _n = this.nodeProxy(this.root)
                return _n.updateHierarchy()
            },

            {
                action: '保存地图',
                success: {show: true},
            },
        )
    }
}
