import superMeta from '../_MODULE/metaModuleNode.mjs'

export default {
    ...superMeta,
    codeProp: 'pmUserCode',
    mapIdProp: 'bfMapId',
    mapStsCodeProp: 'bfMapStsCode',
    mapTypeCode: 'BF_MAP',
    name: '流程域',
    ownerIdProp: 'paId',
    ownerTextProp: 'paName',
    ownerType: 'PA',
    textProp: 'pmName',
    type: 'PM',
}
