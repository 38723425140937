import useSwrNoCache from '@/script/useSwrNoCache.mjs'
import useHTTP from '@/hooks/useHTTP.mjs'

const makeUseBizTable = (key, rowKey, defaultArgs = {}) => {
    return (userArgs) => {
        const http = useHTTP()
        const args = {...defaultArgs, ...userArgs}
        const {data, mutate} = useSwrNoCache(() => http.get(key, args))

        if (data) {
            const pairs = data.map((e) => [e[rowKey], e])
            const dict = Object.fromEntries(pairs)
            return {dict, list: data, pairs, mutate}
        }
        else {
            return {dict: {}, list: [], pairs: [], mutate}
        }
    }
}

export const useBdt = makeUseBizTable(
    '/basbdts',
    'bdtCode',
    {stsCode: 'ENABLE'},
)

export const useBdType = makeUseBizTable(
    '/basdatadictdtls',
    'code',
    {dictCode: 'DM_BD_TYPE'},
)

export const useMidType = makeUseBizTable(
    '/listEnableArchMiddlewareType',
    'pkid',
)

export const useSvrType = makeUseBizTable(
    '/basdatadictdtls',
    'code',
    {dictCode: 'BM_SVR_TYPE'},
)
