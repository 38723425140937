import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import IconLetters from '../icons/IconLetters.jsx'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import api from './apiOu.mjs'
import meta from './metaOu.mjs'
import Model from './ModelOu.mjs'
import PropertiesPane from './PropertiesPaneOu.jsx'
import FormChoose from './FormChooseOu.jsx'
import TableChoose from './TableChooseOu.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        attrNodes: {
            top: ['ATTR_OU_RESUME'],
        },

        canDeleteTree(map, node) {
            const {pkid} = node.data

            if (pkid && this.isMounted(map, node)) {
                const owner = this.getOwner(map, node)

                if (owner) {
                    const {ouId} = owner.data

                    if (pkid === ouId) {
                        return false
                    }
                }
            }

            return ComponentNode.canDeleteTree.call(this, map, node)
        },

        canMove(map, node) {
            const gp = node.parent?.parent

            if ('OO_OA' === gp?.data?.bizNodeType) {
                return true
            }

            return ComponentNode.canMove.call(this, map, node)
        },

        getIcons(map, node) {
            return [
                <IconLetters
                    key="type"
                    fill={Color.LIGHT_YELLOW}
                />
            ]
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.LIGHT_YELLOW}),
                shape: 'EllipseRectangle',
            }
        },

        _pushDataSlots: {
            pOuCodes: [],
        },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.ouList.push(d)
        },

        _getCategories(map, node) {
            return [
                'OU_OU',
                'OU_OR',
            ]
        },
    })
}
