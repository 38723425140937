import parseMap from '../../scripts/parseMap.mjs'
import BaseMap from '../../BaseMap.jsx'
import LeftPanelChangeRef from './LeftPanelChangeRef.jsx'

export default function EmbeddedMap({
    mapData,
    patternRev,
    prjPatternId,
    ...props
}) {
    const initData = parseMap(mapData)

    if (initData) {
        Object.assign(initData.data, {patternRev, prjPatternId})
    }

    const LeftPanel = 'CHG_REFERENCE_MAP' === initData.data.mapTypeCode ?
        LeftPanelChangeRef
        :
        () => null

    return (
        <BaseMap
            components={{LeftPanel}}
            initData={initData}
            {...props}
        />
    )
}
