import http from '@/biz/http.mjs'
import runAsync from '@/script/runAsync.mjs'
import useSwrNoCache from '@/script/useSwrNoCache.mjs'

export default prjId => {
    const fetchProject = () => runAsync(
        async () => {
            const {
                desgAcc,
                prjName,
                prjNo,
                prjTypeCode,
                rev,
                subTypeCode,
                typeName,
            } = await http.get(`/prjs/${prjId}/info`)

            return {
                desgAcc,
                prjName,
                prjNo,
                prjTypeCode,
                rev,
                subTypeCode,
                typeName,
            }
        },

        {
            error: {
                content: (err) => `读取项目失败: ${err.message}`,
            },

            loading: {
                content: '正在读取项目，请稍候',
            },
        }
    )

    return useSwrNoCache(fetchProject, {
        dependencies: [prjId],
        start: void 0 !== prjId,
    })
}
