import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    lsId: {
        name: () => '外联系统',
        required: () => true,
    },

    lsiNo: {
        name: () => '外联接口代码',
        required: () => true,
    },

    lsiName: {
        name: () => '外联接口名称',
        required: () => true,
    },

    memo: {
        name: () => '备注',
    },
})
