import extendNode from '../extendNode.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaFc.mjs'
import Model from './ModelFc.mjs'
import PropertiesPane from './PropertiesPaneFc.jsx'
import FormChoose from './FormChooseFc.jsx'
import TableChoose from './TableChooseFc.jsx'
import api from './apiFc.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        async choose(map, node) {
            const getQuery = ({fms = [], ss = {}, vms = [], ...query}) => ({
                ...query,
                fmIds: fms.map(({fmId}) => fmId),
                ssId: ss.ssId,
                vmIds: vms.map(({vmId}) => vmId),
            })

            return this._choose(map, node, {getQuery})
        },

        clean(map, node) {
            ProductNode.clean.call(this, map, node)

            const {
                jobMap,
                ...data
            } = node.data

            node.data = data
        },

        getIcons(map, node) {
            if (node.data.pkid) {
                return []
            }
            else {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.GREEN_YELLOW}
                        letters="F"
                        textColor="#666"
                    />
                ]
            }
        },

        getStyle(map, node) {
            const {isApi} = node.data

            const shape = '1' === isApi ?
                'HorizontalHexagon': 'EllipseRectangle'

            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.GREEN_YELLOW,
                }),

                shape,
            }
        },

        _pushDataSlots: {
            fmFjList: [],
            repList: [],
            reqList: [],
        },

        async _atAttach(map, node, event) {
            await ProductNode._atAttach.call(this, map, node, event)

            if (event.target === node) {
                await this._onInsert(map, node)
            }
        },

        async _atCreate(map, node) {
            for (const n of map.chain(node.parent)) {
                const {
                    bizNodeType,
                    ssCode,
                    ssId,
                    ssName,
                    vmCode,
                    vmId,
                    vmName,
                } = n.data

                if ('CAT_ROOT_VM' === bizNodeType) {
                    node.data = {
                        ...node.data,
                        ssCode,
                        ssId,
                        ssName,
                        vmCode,
                        vmId,
                        vmName,
                    }

                    break
                }
            }

            await ProductNode._atCreate.call(this, map, node)
        },

        async onPull(map, node) {
            await ProductNode.onPull.call(this, map, node)
            const {jobMap, ...data} = node.data

            if (! jobMap) {
                return
            }

            for (const child of map.children(node)) {
                const {bizNodeType} = child.data

                if ('FC_JOB' === bizNodeType) {
                    const mapData = parseMap(jobMap)
                    const grandchildTrees = mapData.children ?? mapData

                    for (const tree of grandchildTrees) {
                        const grandchild = map.importTree(tree)
                        map.appendChild(child, grandchild)
                    }

                    break
                }
            }

            node.data = data
        },

        _getCategories(map, node) {
            return [
                'FC_DS',
                'FC_PARAM',
                'FC_JOB',
            ]
        },

        _getDefaultDataFields(map, node) {
            return {
                ...ProductNode._getDefaultDataFields.call(this, map, node),
                isApi: '1',
                isSi: '0',
            }
        },

        async _onChange(map, node, event) {
            if (event.target === node) {
                const oldData = event.detail
                const {ssCode} = node.data

                if (oldData.ssCode && ssCode !== oldData.ssCode) {
                    node.data = {
                        ...node.data,
                        vmCode: '',
                        vmId: '',
                        vmName: ''
                    }
                }
            }

            await ProductNode._onChange.call(this, map, node, event)
        },

        async readMap(args) {
            const desc = Reflect.getOwnPropertyDescriptor(
                ProductNode, 'readMap'
            )

            const readMap = desc.get.call(this)
            const mapData = await readMap.call(this, args)
            delete mapData.root.data.jobMap
            return mapData
        },
    })
}
