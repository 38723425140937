import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'
import SelectAm from '../AM/SelectAm.jsx'

const FormItemAmCode = (props) => {
    return (
        <Form.Item
            label="分析域"
            name="amCode"
            {...props}
        >
            <SelectAm multiple />
        </Form.Item>
    )
}

const FormItemAiCode = (props) => {
    return (
        <Form.Item
            label="分析指标代码"
            name="aiNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemAiName = (props) => {
    return (
        <Form.Item
            label="分析指标名称"
            name="aiName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

// const FormItemPrjNo = (props) => {
//     return (
//         <Form.Item
//             label="制品编号"
//             name="prjNo"
//             {...props}
//         >
//             <Input allowClear />
//         </Form.Item>
//     )
// }

export default function FormChooseAmar(props) {
    const brief = (
        <Row gutter={12}>
            {/* <Col span={6}>
                <FormItemPrjNo />
            </Col> */}

            <Col span={6}>
                <FormItemAmCode />
            </Col>

            <Col span={6}>
                <FormItemAiCode />
            </Col>

            <Col span={6}>
                <FormItemAiName />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}