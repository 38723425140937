import extendNode from '../extendNode.mjs'
import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaAlgRule.mjs'

export default () => {
    const ArCatNode = useArCatNode()

    return extendNode(ArCatNode, {
        ...meta,

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.algList.push(d)
        },
    })
}
