import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useArchCatNode from '../_ARCH_CAT/useArchCatNode.jsx'
import meta from './metaCatSs.mjs'

export default () => {
    const ArchCatNode = useArchCatNode()

    return extendNode(ArchCatNode, {
        ...meta,

        getStyle(map, node) {
            return {
                ...ArchCatNode.getStyle.call(this, map, node),
                backgroundColor: Color.GREEN_YELLOW,
            }
        },

        _getCategories(map, node) {
            return [
                'SS_SS',
                'SS_MID',
            ]
        },
    })
}
