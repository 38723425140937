import Table from '@/components/Table/Table.jsx'
import TableChooseProduct from '../_PRODUCT/TableChooseProduct.jsx'

export default function TableChooseAo(props) {
    const columns = [
        {
            title: '分析域',
            dataIndex: 'amNamePath',
            component: <Table.ViewText />,
        },

        {
            title: '分析对象编号',
            dataIndex: 'aoNo',
            component: <Table.ViewText />,
        },

        {
            title: '分析对象名称',
            dataIndex: 'aoName',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseProduct
            columns={columns}
            {...props}
        />
    )
}
