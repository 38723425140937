export function canHideNode(node) {
    return node.parent && ! node.isHidden
}

export function hideNode(nodes) {
    for (const node of nodes) {
        node.isHidden = true
    }

    if (! this.showHiddenNodes) {
        const nodesToSelect = new Set

        for (const n of this.topNodes(nodes)) {
            nodesToSelect.add(n.parent)
        }

        this.selectNodes(nodesToSelect)
    }
}
