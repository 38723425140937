import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    omCode: {
        name: () => '组织域',
    },

    ooFullName: {
        name: () => '组织对象全称',
    },

    ooNo: {
        name: () => '组织对象编号',
        required: () => true,
    },

    ooName: {
        name: () => '组织对象简称',
        required: () => true,
    },

    memo: {
        name: () => '备注',
    },

    resume: {
        name: () => '简述',
    },
})
