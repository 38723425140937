import {useMapContext} from '@/components/MindMap/index.mjs'
import AddPrjPattern from '@/pages/MainPage/panes/PrjPatternList/AddPrjPattern.jsx'

export default function PropertiesPaneButtonMakePattern(props) {
    const map = useMapContext()
    const permission = map.usePermission()

    if (! permission.has('revise')) {
        return null
    }

    const {prjId, prjName, sVer} = map.data

    return (
        <AddPrjPattern
            icon={null}
            prjId={prjId}
            prjName={prjName}
            sVer={sVer}
            {...props}
        />
    )
}
