import useSwrNoCache from '@/script/useSwrNoCache.mjs'
import Select from '@/components/Form/Select.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import apiLs from './apiLs.mjs'

export default function SelectLs({onChange, ...props}) {
    const map = useMapContext()
    const {prjId, sVer} = map.data

    const {data = []} = useSwrNoCache(
        () => apiLs.readList({prjId, sVer, lsStsCodes: ['RLS', 'REVISE']}),
        {dependencies: [prjId, sVer]}
    )

    const options = data.map(e => [e.pkid, e])
    const dict = new Map(options)

    const handleChange = id => {
        const ls = dict.get(id)
        onChange(ls)
    }

    return (
        <Select
            mapOption={{label: e => e.lsName}}
            options={options}
            onChange={handleChange}
            {...props}
        />
    )
}
