import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'orUserCode',
    mapProp: 'orMap',
    name: '岗位角色',
    ownerIdProp: 'ooId',
    ownerTextProp: 'ooName',
    ownerType: 'OO',
    textProp: 'orName',
    type: 'OR',
}
