import ModulePropertiesPane from '../_MODULE/ModulePropertiesPane.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelOm.mjs'

export default function PropertiesPaneOm(props) {
    return (
        <ModulePropertiesPane
            controllers={
                <>
                    <Text prop="omUserCode" />
                    <Text prop="omName" />
                </>
            }

            Model={Model}

            snSuffix={
                <Analyse
                    modes={[
                        'Reference',
                        'ReferenceCall',
                    ]}
                />
            }

            versionLabel="组织地图版本号"
            {...props}
        />
    )
}
