import {Button} from 'antd'
import http from '@/biz/http.mjs'
import ContextMenu from '@/components/ContextMenu/ContextMenu.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {PopoverTrigger} from '@/components/Popover/index.mjs'
import openWindow from '@/script/openWindow.mjs'

const mode2name = {
    ChgReference: '变更分析',
    CallUiDepend: '界面间依赖分析',
    CallUiEffect: '界面间影响分析',
    Depend: '依赖分析',
    Effect: '影响分析',
    FkDepend: '外键依赖分析',
    FkEffect: '外键影响分析',
    Reference: '被引用分析',
    ReferenceCall: '引用分析',
}

const order = [
    'Effect',
    'CallUiEffect',
    'FkEffect',
    'Depend',
    'CallUiDepend',
    'FkDepend',
    'ReferenceCall',
    'Reference',
    'ChgReference',
]

const analyse = async (event, map, mode, node) => {
    const {
        bizNodeType,
        dpSn,
        pkid,
        prjId,
        prjNo,
        rev,
        sVer,
    } = node.data

    const {textProp, ownerTextProp} = map.BizNode[bizNodeType]

    const {
        [ownerTextProp]: dpName,
        [textProp]: name,
    } = node.data

    const {patternRev, prjPatternId} = await (async () => {
        const {patternRev, prjPatternId} = node.data

        if (patternRev && prjPatternId) {
            return {patternRev, prjPatternId}
        }

        if (prjId === map.data.prjId) {
            const {patternRev, prjPatternId} = map.data
            return {patternRev, prjPatternId}
        }
        else {
            const {patternRev, prjPatternId} = await http.get(
                '/prjpatterns/oneModuleLastPattern',

                {
                    bizNodeType,
                    moduleId: pkid,
                    moduleRev: rev,
                    prjId,
                    sVer,
                }
            ) ?? {}

            return {patternRev, prjPatternId}
        }
    })()

    const target = event.altKey ? '_self' : '_blank'

    openWindow(
        '/ModuleAnalyse',

        {
            bizNodeType,
            dpName,
            dpSn,
            mode,
            name,
            patternRev,
            pkid,
            prjNo,
            prjPatternId,
            rev,
            sVer,
        },

        target
    )
}

const AnalyseMultiple = ({map, modes, node, ...props}) => {
    const menuItems = modes
        .toSorted((a, b) => {
            const ia = order.indexOf(a)
            const ib = order.indexOf(b)
            return ia - ib
        })
        .map(mode => ({
            label: mode2name[mode],
            onClick: e => analyse(e, map, mode, node)
        }))

    return (
        <PopoverTrigger
            align="right"
            content={<ContextMenu items={menuItems} />}
            position="bottom"
            {...props}
        >
            <Button
                size="small"
                type="link"
            >
                模件分析
            </Button>
        </PopoverTrigger>
    )
}

const AnalyseSingle = ({map, mode, node, ...props}) => {
    const label = mode2name[mode]
    const handleClick = e => analyse(e, map, mode, node)

    return (
        <Button
            size="small"
            type="link"
            onClick={handleClick}
            {...props}
        >
            {label}
        </Button>
    )
}

export default function PropertiesControllerAnalyse({modes, ...props}) {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()

    if (1 !== selectedNodes.size) {
        return null
    }

    const [node] = selectedNodes

    if (0 === modes.length) {
        return null
    }

    if (1 < modes.length) {
        return (
            <AnalyseMultiple
                map={map}
                modes={modes}
                node={node}
                {...props}
            />
        )
    }
    else {
        return (
            <AnalyseSingle
                map={map}
                mode={modes[0]}
                node={node}
                {...props}
            />
        )
    }
}
