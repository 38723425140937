import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'

const FormItemAoNo = (props) => {
    return (
        <Form.Item
            label="分析对象编号"
            name="aoNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemAoName = (props) => {
    return (
        <Form.Item
            label="分析对象名称"
            name="aoName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemPrjNo = (props) => {
    return (
        <Form.Item
            label="项目编号"
            name="prjNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

export default function FormChooseAo(props) {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemPrjNo />
            </Col>

            <Col span={8}>
                <FormItemAoNo />
            </Col>

            <Col span={8}>
                <FormItemAoName />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}
