import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    omUserCode: {
        name: () => '组织域代码',
    },

    omName: {
        name: () => '组织域名称',
        required: () => true,
    },
})
