import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Fm from '../FM/PropertiesControllerFm.jsx'
//import ButtonDemo from './PropertiesPaneButtonDemo.jsx'
import ButtonExport from './PropertiesPaneButtonExport.jsx'
import Model from './ModelUc.mjs'

const PropertiesPaneUc = (props) => (
    <ProductPropertiesPane
        buttons={
            <>
                {/*<ButtonDemo />*/}
                <ButtonExport nodes={props.nodes} />
            </>
        }

        controllers={
            <>
                <Fm />
                <Text prop="sfNo" />
                <Text prop="sfName" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'ChgReference',
                    'Reference',
                    'ReferenceCall',
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneUc
