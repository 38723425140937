import Table from '@/components/Table/Table.jsx'
import TableChooseComponent from './TableChooseDesign.jsx'
import { Tag } from 'antd'

export default function TableChooseDe(props) {
    const columns = [
        {
            title: '项目编号',
            dataIndex: 'prjNo',
            component: <Table.ViewText />,
        },
        {
            title: '项目名称',
            dataIndex: 'prjName',
            component: <Table.ViewText />,
        },
        {
            title: '分支号',
            dataIndex: 'sVer',
            width: 80,
            component: <Table.ViewText />,
        },
        {
            title: '项目类型',
            dataIndex: 'typeName',
            component: <Table.ViewText />,
            filter: <Table.FilterText />,
        },

        {
            title: '项目所有者',
            dataIndex: 'userAndTeam',
            width: 200,
            component: <Table.ViewText />,
            filter: <Table.FilterText  />,
            render: (text, record) => {
                switch (record.prjOwnerTypeCode) {
                    case "USER": return <div><Tag color="yellow">个人</Tag>{text}</div>;
                    case "TEAM": return <div><Tag color="blue">团队</Tag>{text}</div>;
                }
            }
        },
        {
            title: '创建人',
            dataIndex: 'crtName',
            width: 180,
            ellipsis: true,
            filter: <Table.FilterText/>,
            component: <Table.ViewText />
        },
        {
            title: '创建时间',
            dataIndex: 'crtTime',
            width: 160,
            component: <Table.ViewText />
        },
    ]

    return (
        <TableChooseComponent
            columns={columns}
            {...props}
        />
    )
}
