import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'

const FormItemAmarTitle = (props) => {
    return (
        <Form.Item
            label="分析报表名称"
            name="arName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemArrdName = (props) => {
    return (
        <Form.Item
            label="报表维度名称"
            name="rdName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemArrdNo = (props) => {
    return (
        <Form.Item
            label="报表维度代码"
            name="rdUserCode"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

export default function FormChooseDe(props) {
    const brief = (
        <Row gutter={12}>
             <Col span={6}>
                <FormItemAmarTitle />
            </Col>

            <Col span={6}>
                <FormItemArrdName />
            </Col>

            <Col span={6}>
                <FormItemArrdNo />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}
