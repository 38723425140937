import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'

const FormItemOuTitle = (props) => {
    return (
        <Form.Item
            label="组织单元标题"
            name="ouName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemOoName = (props) => {
    return (
        <Form.Item
            label="组织对象名称"
            name="ooName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemOoNo = (props) => {
    return (
        <Form.Item
            label="组织对象代码"
            name="ooNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemPrjNo = (props) => {
    return (
        <Form.Item
            label="项目编号"
            name="prjNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

export default function FormChooseOu(props) {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemPrjNo />
            </Col>

            <Col span={6}>
                <FormItemOoName />
            </Col>

            <Col span={6}>
                <FormItemOoNo />
            </Col>

            <Col span={6}>
                <FormItemOuTitle />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}
