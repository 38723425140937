import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'

const FormItemAmaiTitle = (props) => {
    return (
        <Form.Item
            label="分析指标标题"
            name="aiName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}


const FormItemAiacName = (props) => {
    return (
        <Form.Item
            label="分析口径名称"
            name="acName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemAiacNo = (props) => {
    return (
        <Form.Item
            label="分析口径代码"
            name="acUserCode"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

export default function FormChooseDe(props) {
    const brief = (
        <Row gutter={12}>
             <Col span={6}>
                <FormItemAmaiTitle />
            </Col>
            <Col span={6}>
                <FormItemAiacName />
            </Col>

            <Col span={6}>
                <FormItemAiacNo />
            </Col>

           
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}
