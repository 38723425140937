import Table from '@/components/Table/Table.jsx'
import TableChooseComponent from '../_COMPONENT/TableChooseComponent.jsx'

export default function TableChooseArri(props) {
    const columns = [
        {
            title: '分析报表名称',
            dataIndex: 'arName',
            component: <Table.ViewText />,
        },

        {
            title: '分析报表代码',
            dataIndex: 'arNo',
            component: <Table.ViewText />,
        },
        {
            title: '报表指标名称',
            dataIndex: 'riName',
            component: <Table.ViewText />,
        },

        {
            title: '报表指标代码',
            dataIndex: 'riUserCode',
            component: <Table.ViewText />,
        },

        // {
        //     title: '维度实体标题',
        //     dataIndex: 'deTitle',
        //     component: <Table.ViewText />,
        // },
    ]

    return (
        <TableChooseComponent
            columns={columns}
            {...props}
        />
    )
}
