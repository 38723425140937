import {useOpenModal} from '@/components/Modal/Modal.jsx'
import extendNode from '../extendNode.mjs'
import http from '@/biz/http.mjs'
import Color from '../Color.mjs'
import useCommonNode from '../_COMMON/useCommonNode.jsx'
import meta from './metaSpj.mjs'
import PropertiesPane from './PropertiesPaneSpj.jsx'
import ModalChooseDesignItems from './ModalChooseDesign.jsx'
import FormChoose from './FormChooseSpj.jsx'
import TableChoose from './TableChooseSpj.jsx'
import {MapContext} from '@/components/MindMap/index.mjs'
import openWindow from '@/script/openWindow.mjs'

const readProducts = data => http.get('/prjs/listPrjByMap', data)

export default () => {
    const CommonNode = useCommonNode()
    const openModal = useOpenModal()

    return extendNode(CommonNode, {
        ...meta,
        PropertiesPane,
        getStyle(map, node) {
            return {
                ...CommonNode.getStyle.call(this, map, node),
                // ...BaseNode.getStyle.call(this, map, node),
                backgroundColor: '#fa9096',
                textColor: '#000',
                shape: 'RoundedRectangle',
            }
        },
        async onInsertCommon(map, nodes) {

            console.log(map)
            const items = await openModal(
                <MapContext.Provider value={this}>
                    <ModalChooseDesignItems
                        fetch={readProducts}
                        FormChoose={FormChoose}
                        TableChoose={TableChoose}
                        title="选择项目"
                        width="80%"
                    />
                </MapContext.Provider>
            )

            const trees = items.map(
                (e) => ({
                    data: {...e, bizNodeType: "SPJ", prjId: e.pkid}
                })
            )
            await map.execute(() => {
                const nodesToSelect = new Set()

                for (const node of map.selectedNodes) {
                    const subNodes = trees.map(
                        tree => map.importTree(tree)
                    )

                    for (const subNode of subNodes) {
                        map.appendChild(node, subNode)
                        nodesToSelect.add(subNode)
                    }
                }

                map.selectNodes(nodesToSelect)
            })
        },

        async onDoubleClick(map, node, event) {
            event.preventDefault()
            const {pkid:prjId, sVer} = node.data
            console.log(node.data)
            const target = event.altKey ? '_self' : '_blank'
            openWindow('/SaList', {prjId, sVer}, target)
        },
    })
}
