import SelectPmDialog from '@/pages/MainPage/dialogs/SelectPmDialog'
import InputModalSelect from '../components/InputModalSelect.jsx'

export default function SelectPm({value, ...props}) {
    const getText = multiple => multiple ?
        (value ?? []).map(({pmName}) => pmName).join(',') :
        (value ?? {}).pmName

    return (
        <InputModalSelect
            getText={getText}
            Modal={SelectPmDialog}
            {...props}
        />
    )
}
