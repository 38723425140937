import extendNode from '../extendNode.mjs'
import useAbstractNode from '../_ABSTRACT/useAbstractNode.jsx'
import meta from './metaAd_1.mjs'
import FormChoose from './FormChooseAd_1.jsx'
import TableChoose from './TableChooseAd_1.jsx'
import api from './apiAd_1.mjs'

export default () => {
    const AbstractNode = useAbstractNode()

    return extendNode(AbstractNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
    })
}
