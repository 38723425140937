import http from '@/biz/http.mjs'

export default {
    publish: ({pkid, ...data}) => http.post(
        '/amAr/rlsAmAr',
        {amArs: [{pkid}], ...data}
    ),

    pull: ({pkid}) => http.get(`/amAr/loadDp`, {pkid}),
    push: (data) => http.post('/amAr/modifyDp', data),
    read: ({pkid}) => http.get(`/amAr/oneAmAr`, {pkid}),
    readList: (args) => http.get('/amAr/listAmAr', args),

    revise: ({pkid, ...data}) => http.post(
        '/amAr/reviseAmAr',
        {amArs: [{pkid}], ...data}
    ),

    update: (data) => http.post(`/amAr/saveMap`, data),
}
