import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaBdRule.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(BDRULE|CAT)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'BDRULE'
        },

        isHidden(map, node) {
            const {bdType} = node.parent?.data ?? {}

            return (
                '3' === bdType ||
                CategoryNode.isHidden.call(this, map, node)
            )
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['CAT'],
            ]
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['BDRULE'],
            ]
        },
    })
}
