import {NodePropertiesPane} from '../../SidePanel/RightPanel/NodePane.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import ProjectDetail from '../CAT_ROOT_PRJ/PropertiesControllerProjectDetail.jsx'
import Model from './ModelSpj.mjs'

const PropertiesPaneSpj = (props) => (
    <NodePropertiesPane
        Model={Model}
        {...props}
    >
        <Text prop="prjNo"  labelSuffix={<ProjectDetail />} />
        <Text prop="prjName" />
        <Text prop="typeName" />
        <Text prop="uptName" />
        <Text prop="uptTime" />
        <Text prop="crtName" />
        <Text prop="crtTime" />
    </NodePropertiesPane>
)

export default PropertiesPaneSpj
