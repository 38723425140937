import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'ouUserCode',
    mapProp: 'ouMap',
    name: '组织单元',
    ownerIdProp: 'ooId',
    ownerTextProp: 'ooName',
    ownerType: 'OO',
    textProp: 'ouName',
    type: 'OU',
}
