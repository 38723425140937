import {useRef} from 'react'

export default () => {
    const refAllow = useRef(true)

    const extensions = map => {
        const isNodeHidden = map.isNodeHidden.bind(this)

        return {
            get allowShowHiddenNodes() {
                return refAllow.current
            },

            set allowShowHiddenNodes(allow) {
                refAllow.current = allow
            },

            isNodeHidden(node) {
                if (isNodeHidden(node)) {
                    return true
                }

                const _n = this.nodeProxy(node)
                return _n.isHidden()
            },
        }
    }

    return {extensions}
}
